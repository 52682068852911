import './index.scss';
import React, { useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import noImg from '../../../../assets/images/no-image.jpg';
import moment from 'moment';
import Loader from '../../../../components/loader';
import { getNewsView } from '../../../../actions/Cms';
import bannerImg from '../../../../assets/images/news-banner.png';
import BannerBar from '../../components/banner-bar';

const NewsView = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { news_details, isLoading } = useSelector(state => state.Cms);

    useEffect(() => {
        dispatch(getNewsView(id));
    }, [dispatch]);

    // console.log("news_details===>", news_details)

    return (
        <div className='arenaBase pt-0'>
            <BannerBar
                title={"News"}
                subTitle={"Stay up to date with the news! Follow our networks @sport.ranking.ftv!"}
                description={"The right point in your game."}
                bannerUrl={bannerImg}
                linkUrl={"/championships"} />
            {isLoading ? <Loader /> :
                <div className='cmn-content-body news-details-wrapper'>
                    <div className='container'>
                        {news_details?.length === 0 ?
                            <p className='text-center mb-0'>{t("News") + " " + t("Not Found")}</p>
                            :
                            <div className='view-news-bx'>
                                <div className='news-img'>
                                    {news_details?.news_url ?
                                        <img className='rounded' src={process.env.REACT_APP_IMGBASE_URL + news_details?.news_url} alt="" /> :
                                        <img className='rounded' src={noImg} alt="" />}
                                </div>
                                <div className='news-content'>
                                    <span className='date-text'> {moment(news_details?.created_at).format("LL")}</span>
                                    <h3>{news_details?.title}</h3>
                                    <p>{news_details?.description}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default NewsView;