import React from "react";
import './index.scss';
import { FloatingLabel, Form } from "react-bootstrap";
import { State, City } from 'country-state-city';

export const country = "BR";

export const StateSelectField = ({ formik, fieldName, labelName, cityFieldName }) => {

  return (
    <>
      <FloatingLabel controlId={fieldName} label={labelName} className={`${formik.values[fieldName] ? 'cmn-style-floating active' : 'cmn-style-floating select-style'}`}>
        <Form.Select
          name={fieldName}
          onChange={(e) => {
            formik.handleChange(e);
            formik.setFieldValue(cityFieldName, "");
            formik.setFieldTouched(fieldName, false);
          }}
          value={formik.values[fieldName]}>
          <option value=""></option>
          {State.getStatesOfCountry(country).map((item, index) => {
            return (
              <option key={index} value={item.isoCode}>{item.name}</option>
            )
          })}
        </Form.Select>
      </FloatingLabel>
      {formik.touched[fieldName] && formik.errors[fieldName] ? <p className="errorTxt">{formik.errors[fieldName]}</p> : null}
    </>
  );
}

export const CitySelectField = ({ formik, fieldName, labelName, stateFieldName }) => {

  return (
    <>
      <FloatingLabel controlId={fieldName} label={labelName} className={`${formik.values[fieldName] ? 'cmn-style-floating active' : 'cmn-style-floating select-style'}`}>
        <Form.Select
          name={fieldName}
          onChange={formik.handleChange}
          value={formik.values[fieldName]}>
          <option value=""></option>
          {City.getCitiesOfState(country, formik.values[stateFieldName]).map((item, index) => {
            return (
              <option key={index} value={item.name}>{item.name}</option>
            )
          })}
        </Form.Select>
      </FloatingLabel>
      {formik.touched[fieldName] && formik.errors[fieldName] ? <p className="errorTxt">{formik.errors[fieldName]}</p> : null}
    </>
  );
}
