import './index.scss';
import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo.svg';
import footerwhitelogo from '../../../../assets/images/footerwhitelogo.svg';
import appstore from '../../../../assets/images/appstore.svg'
import gplay from '../../../../assets/images/gplay.svg'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Footer = () => {
    const { t } = useTranslation();
    const { contact_info } = useSelector(state => state.Cms);

    return (
        <>
            <div className='footerTop'>
                <Link to={contact_info?.appstore_url ? contact_info?.appstore_url : ""} target="_blank" rel="noopener noreferrer"><img src={appstore} alt='' /></Link>
                <Link to={contact_info?.googleplay_url ? contact_info?.googleplay_url : ""} target="_blank" rel="noopener noreferrer"><img src={gplay} alt='' /></Link>
            </div>

            <div className='footerB'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-2 footerLogo'>
                            <img src={logo} alt='' />
                        </div>

                        <div className='col-lg-2 mb-lg-0 mb-3'>
                            <h4>{t("Menu")}</h4>
                            <ul>
                                <li><Link to='/'>{t("Home")}</Link></li>
                                <li><Link to='/about-us'>{t("About Us")}</Link></li>
                                <li><Link to='/contact-us'>{t("Contact Us")}</Link></li>
                            </ul>
                        </div>

                        <div className='col-lg-2 mb-lg-0 mb-3'>
                            <h4>{t("Legal")}</h4>
                            <ul>
                                <li><Link to='/faqs'>{t("FAQ's")}</Link></li>
                                <li><Link to='/privacy-policy'>{t("Privacy Policy")}</Link></li>
                                <li><Link to='/terms-conditions'>{t("Terms & Conditions")}</Link></li>
                            </ul>
                        </div>

                        <div className='col-lg-3 mb-lg-0 mb-3'>
                            <h4>{t("Get Info")}</h4>
                            <p><strong>{t("Address")}:</strong> {contact_info?.address ? contact_info?.address : "N/A"}</p>
                            <p><strong>{t("Phone")}:</strong> {contact_info?.phone ? contact_info?.phone : "N/A"} </p>
                            <p><strong>{t("Mail")}:</strong> {contact_info?.email ? contact_info?.email : "NA"} </p>
                        </div>
                        <div className='col-lg-3 footerStore'>
                            <div className='followUshdn'><img src={footerwhitelogo} alt='' /> {t("Follow Us")}</div>
                            <div className='socialBg'>
                                <Link to={contact_info?.facebook_url} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f"></i></Link>
                                <Link to={contact_info?.whatsapp_url} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i></Link>
                                <Link to={contact_info?.instagram_url} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></Link>
                            </div>
                            <p>&copy; {new Date().getFullYear()} sportranking. {t("All rights reserved")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;