import './index.scss';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import noUser from '../../../../assets/images/no-user.jpg';
import imgShape from '../../../../assets/images/ShieldBadge.svg';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Loader from '../../../../components/loader';
import { BadgeImages } from './badgeImages';
import {
  getAthleteCount,
  getAthleteRanking,
  getAthleteView,
} from '../../../../actions/Athletes';

const AthleteView = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { isLoading, rankingData, countingData, details } = useSelector(
    state => state.Athletes,
  );

  useEffect(() => {
    dispatch(getAthleteRanking(id));
    dispatch(getAthleteCount(id));
    dispatch(getAthleteView(id));
  }, [id]);

  //   const details = athletes ? athletes.find(item => item.id === Number(id)) : {};

  const calculateAge = dob => {
    const parsedDob = moment(dob, 'DD-MM-YYYY');
    return moment().diff(parsedDob, 'years', false) + " " + t('Years');
  };

  const batchImageStyle = ftvPoints => {
    if (ftvPoints < 1000) {
      return BadgeImages.Beginner;
    } else if (ftvPoints >= 1000 && ftvPoints < 1100) {
      return BadgeImages.Bronze2;
    } else if (ftvPoints >= 1100 && ftvPoints < 1300) {
      return BadgeImages.Bronze3;
    } else if (ftvPoints >= 1300 && ftvPoints < 1600) {
      return BadgeImages.Bronze4;
    } else if (ftvPoints >= 1600 && ftvPoints < 1700) {
      return BadgeImages.Silver2;
    } else if (ftvPoints >= 1700 && ftvPoints < 1900) {
      return BadgeImages.Silver3;
    } else if (ftvPoints >= 1900 && ftvPoints < 2200) {
      return BadgeImages.Silver4;
    } else if (ftvPoints >= 2200 && ftvPoints < 2400) {
      return BadgeImages.Gold2;
    } else if (ftvPoints >= 2400 && ftvPoints < 2700) {
      return BadgeImages.Gold3;
    } else if (ftvPoints >= 2700 && ftvPoints < 3000) {
      return BadgeImages.Gold4;
    } else if (ftvPoints >= 3000 && ftvPoints < 4000) {
      return BadgeImages.Platinum2;
    } else if (ftvPoints >= 4000 && ftvPoints < 5000) {
      return BadgeImages.Platinum3;
    } else if (ftvPoints >= 5000) {
      return BadgeImages.Platinum4;
    } else {
      return BadgeImages.Bronze2;
    }
  };

  const categoryNameFunc = (ftvPoints, totalMatches) => {
    if (totalMatches < 10) {
      return (
        t('Novice').toUpperCase() +
        '/' +
        t('Beginner').toUpperCase()
      );
    } else if (ftvPoints < 1001) {
      return t('Novice').toUpperCase();
    } else if (ftvPoints > 1000 && ftvPoints < 1100) {
      return (
        t('Novice').toUpperCase() +
        '/' +
        t('Beginner').toUpperCase()
      );
    } else if (ftvPoints > 1100 && ftvPoints <= 1300) {
      return t('Beginner').toUpperCase();
    } else if (ftvPoints > 1300 && ftvPoints < 1400) {
      return (
        t('Beginner').toUpperCase() +
        '/' +
        t('Amateur C').toUpperCase()
      );
    } else if (ftvPoints >= 1400 && ftvPoints <= 1700) {
      return t('Amateur C').toUpperCase();
    } else if (ftvPoints > 1700 && ftvPoints < 1800) {
      return (
        t('Amateur C').toUpperCase() +
        '/' +
        t('Amateur B').toUpperCase()
      );
    } else if (ftvPoints >= 1800 && ftvPoints <= 2100) {
      return t('Amateur B').toUpperCase();
    } else if (ftvPoints > 2100 && ftvPoints < 2300) {
      return (
        t('Amateur B').toUpperCase() +
        '/' +
        t('Amateur A').toUpperCase()
      );
    } else if (ftvPoints >= 2300 && ftvPoints < 2900) {
      return t('Amateur A').toUpperCase();
    } else if (ftvPoints >= 2900) {
      return (
        t('Amateur A').toUpperCase() +
        '/' +
        t('Qualify').toUpperCase()
      );
    } else {
      return t('Novice').toUpperCase();
    }
  };

  const batchColorStyle = ftvPoints => {
    if (ftvPoints < 1000) {
      return '#fff';
    } else if (ftvPoints >= 1000 && ftvPoints < 1600) {
      return '#C8A47B';
    } else if (ftvPoints >= 1600 && ftvPoints < 2200) {
      return '#A6A6A6';
    } else if (ftvPoints >= 2200 && ftvPoints < 4000) {
      return '#D0A933';
    } else if (ftvPoints >= 2400) {
      return '#E4CD7D';
    } else {
      return '#fff';
    }
  };
  const badgeStyle = {
    backgroundImage: `url(${batchImageStyle(details?.ftv_points)})`,
  };

  return (
    <div className="arenaBase">
      <h2 className="admin-page-title mb-3">{t('Athlete Details')}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="adBg">
          <div className="adbgLft">
            <div className="adImgContent">
              <div className="adImgbg">
                {details?.profile_url ? (
                  <img
                    className="img-user"
                    src={
                      process.env.REACT_APP_IMGBASE_URL + details?.profile_url
                    }
                    alt={details?.name}
                  />
                ) : (
                  <img className="img-user" src={noUser} alt="No user" />
                )}
                <img className="img-shape" src={imgShape} alt="No user" />
              </div>
              {rankingData?.length !== 0 && (
                <div className="adIdbx">
                  <div className="adIdIn">
                    <span>{rankingData} {'º'}</span>
                  </div>
                </div>
              )}
              <h6>{details?.display_name ? details.display_name : 'N/A'}</h6>
            </div>
            <div className="adbTxt">
              <table>
                <tbody>
                  <tr>
                    <th scope="col">{t('Athlete Name')}</th>
                    <td data-label={t('Athlete Name')}>
                      {details?.name ? details.name : 'N/A'}
                    </td>
                  </tr>
                  {/* <tr>
                    <th scope="col">{t('CPF Number')}</th>
                    <td data-label={t('CPF Number')}>
                      {details?.cpf_no ? details.cpf_no : 'N/A'}
                    </td>
                  </tr> */}
                  <tr>
                    <th scope="col">{t('Age')}</th>
                    <td data-label={t('Age')}>
                      {details?.dob
                        ? calculateAge(
                          moment(details?.dob).format('DD-MM-YYYY'),
                        )
                        : 'N/A'}
                    </td>
                  </tr>
                  {/* <tr>
                                        <th scope="col">{t("Profession")}</th>
                                        <td data-label="Profession">{details?.profession ? details.profession : "N/A"}</td>
                                    </tr> */}
                  <tr>
                    <th scope="col">{t('Gender')}</th>
                    <td data-label={t('Gender')}>
                      {details?.gender ? t(details.gender) : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('City')}</th>
                    <td data-label={t('City')}>
                      {details?.city ? details.city : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('State')}</th>
                    <td data-label={t('State')}>
                      {details?.state ? details.state : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('Prefered Side to Play')}</th>
                    <td data-label={t('Prefered Side to Play')}>
                      {details?.playing_side ? t(details.playing_side) : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('Social Media')}</th>
                    <td data-label={t('Social Media')}>
                      {/* <i className="fa-brands fa-facebook-f"></i>&nbsp;&nbsp; */}
                      <Link
                        to={details?.socialmedia ? "https://www.instagram.com/" + details?.socialmedia : "https://www.instagram.com"}
                        target="_blank"
                        rel="noopener noreferrer">
                        <i className="fa-brands fa-instagram"></i>
                      </Link>
                      {/* <i className="fa-brands fa-youtube"></i> */}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">{t('Summary')}</th>
                    <td data-label={t('Summary')}>
                      {details?.summary ? details.summary : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="ftbbg">
            <div className="ftv1">
              {countingData?.match_count < 10 ? (
                <div
                  style={{ backgroundImage: `url(${BadgeImages.Beginner})` }}
                  className="ftbImg">
                  <div className="calibrating">
                    <span style={{ color: '#fff', fontSize: 11 }}>
                      {t('Calibrating')}
                    </span>
                    <span style={{ color: '#fff', fontSize: 10 }}>
                      {countingData?.match_count}/10
                    </span>
                  </div>
                </div>
              ) : (
                <div style={badgeStyle} className="ftbImg">
                  <h6>
                    <span style={{ color: batchColorStyle(details?.ftv_points) }}>
                      {details?.ftv_points}
                    </span>
                  </h6>
                </div>
              )}
            </div>
            <div className="indicative1">
              <div className="trophy-room-bx">
                <div className="trophy-room-header">
                  <span>{t('Trophy Room')}</span>
                </div>
                <div className="trophy-room-trophy">
                  <div
                    style={{
                      backgroundImage: `url(${BadgeImages.Trophyroom})`,
                    }}
                    className="tropyRoom">
                    <div className="tr1">
                      <span className="numbr">{countingData?.silver}</span>
                      <img src={BadgeImages.Silvercup} alt="No user" />
                    </div>
                    <div className="tr2">
                      <span className="numbr">{countingData?.gold}</span>
                      <img src={BadgeImages.Goldcup} alt="No user" />
                    </div>
                    <div className="tr3">
                      <span className="numbr">{countingData?.bronze}</span>
                      <img src={BadgeImages.Bronzecup} alt="No user" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${BadgeImages.CategoryBlock})`,
                }}
                className="cateGory">
                <h5>{t('Indicative')}</h5>
                <h6>{t('Category')}</h6>
                <div
                  style={{ backgroundImage: `url(${BadgeImages.Categorybar})` }}
                  className="categotyBtm">
                  {categoryNameFunc(
                    details?.ftv_points,
                    countingData?.match_count,
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Link to="/athelets" className="backtxt">
        <i className="fa-light fa-arrow-left-long"></i> {t('Back')}
      </Link>
    </div>
  );
};

export default AthleteView;
