import React, { useEffect } from "react";
import './index.scss';
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import noUser from '../../../assets/images/no-user.jpg';
import Loader from "../../../components/loader";
import { getRankingList } from "../../../actions/Cms";
import { useTranslation } from "react-i18next";
import BannerBar from "../components/banner-bar";
import bannerImg from '../../../assets/images/ranking-banner.png';

const Ranking = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { athletes_female, athletes_male, isLoading } = useSelector(state => state.Cms);

    useEffect(() => {
        dispatch(getRankingList());
    }, [dispatch]);

    // console.log("ranking_list====>", ranking_list[0])
    return (
        <div className='mainBase pt-0'>
            <BannerBar
                title={"Ranking"}
                subTitle={"Overcome Limits, Rank Wins: Sport Ranking, your balanced competition"}
                description={"The right point in your game."}
                bannerUrl={bannerImg}
                linkUrl={"/championships"} />
            <div className='cmn-content-body ranking-wrapper'>
                <div className='container'>
                    {isLoading ?
                        <Loader />
                        :
                        <Tabs
                            defaultActiveKey="male"
                            transition={false}
                            className="cmn-tabs-style"
                        >
                            <Tab eventKey="male" title={t("Male")}>
                                <div className="row">
                                    {athletes_male && athletes_male.length > 0 ? athletes_male.map((item, index) => {
                                        return (
                                            <div key={item.id} className="col-md-6 col-lg-3 py-3">
                                                <div className="ranking-item">
                                                    <div className="ranking-item-img">
                                                        {item.profile_url ? <img src={item.profile_url} alt={item.name} /> : <img src={noUser} alt="No user" />}
                                                    </div>
                                                    <div className="ranking-item-info">
                                                        <p className="ranking-id"><span>{index + 1} {'º'}</span></p>
                                                        <h5>{item.display_name}</h5>
                                                        <p className="ftv-points">{t("FTV Points")}: {item.ftv_points}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <div className="col-md-12"><p className="mb-0 text-center">{t("Ranking")} {t("Not Found")}</p></div>}
                                </div>
                            </Tab>
                            <Tab eventKey="female" title={t("Female")}>
                                <div className="row">
                                    {athletes_female && athletes_female.length > 0 ? athletes_female.map((item, index) => {
                                        return (
                                            <div key={item.id} className="col-md-6 col-lg-3 py-3">
                                                <div className="ranking-item">
                                                    <div className="ranking-item-img">
                                                        {item.profile_url ? <img src={item.profile_url} alt={item.name} /> : <img src={noUser} alt="" />}
                                                    </div>
                                                    <div className="ranking-item-info">
                                                        <p className="ranking-id"><span>{index + 1} {'º'}</span></p>
                                                        <h5>{item.display_name}</h5>
                                                        <p className="ftv-points">{t("FTV Points")}: {item.ftv_points}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <div className="col-md-12"><p className="mb-0 text-center">{t("Ranking")} {t("Not Found")}</p></div>}
                                </div>
                            </Tab>
                        </Tabs>
                    }
                </div>
            </div>
        </div>
    )
}
export default Ranking;