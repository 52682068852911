import React from "react";
import './index.scss';
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ deleteModal, setDeleteModal, action }) => {
    const { t } = useTranslation();

    const handleConfirmDelete = () => {
        setDeleteModal(false);
        action();
    }

    return (
        <Modal
            show={deleteModal}
            onHide={() => setDeleteModal(false)}
            keyboard={false}
            size="sm"
            className="logout-modal"
            centered
        >
            <Modal.Body>
                <p className="text-center">{t("Are you sure you want to delete")}?</p>
                <div className="mt-3 justify-content-center d-flex">
                    <button type="button" className="btn btn-outline-primary commonButton" onClick={() => setDeleteModal(false)}>
                        {t("No")}
                    </button>
                    <button className='btn btn-primary commonButton ms-3' type="button" onClick={handleConfirmDelete}>
                        <span>{t("Yes")}</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteModal;