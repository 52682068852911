import "./index.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="not-found-box d-flex text-center justify-content-center flex-column align-items-center">
            <div className="not-found-content">
                <h1 className="not-found-heading">404</h1>
                <h3>{t("Look like you're lost")}</h3>
                <p>{t("The page you are looking for not avaible")}!</p>
                <button type="button" className="btn cmn-btn-style" onClick={() => navigate("/")}>{t("Go to Home")}</button>
            </div>
        </div>
    )
}

export default NotFound;