import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import FrontendLayout from '../layouts/FrontendLayout';
import AdminLayout from '../layouts/AdminLayout';
// For Frontend page
import Home from '../pages/frontend/home';
// For Auth page
import Login from '../pages/auth/login';
import Register from '../pages/auth/register';
import ForgotPassword from '../pages/auth/forgot-password';
import EmailVerification from '../pages/auth/email-verification';
import EmailVerifiedSuccess from '../components/email-verified-success';
// For Admin page
import Dashboard from '../pages/admin/dashboard';
import MyProfile from '../pages/admin/my-profile';
import Arena from '../pages/admin/arena';
import Athletes from '../pages/admin/athletes';
import AthleteView from '../pages/admin/athletes/view';
import Tournaments from '../pages/admin/tournaments';
import AddTournament from '../pages/admin/tournaments/add';
import Payment from '../pages/admin/payment';
import ArenaView from '../pages/admin/arena/view';
// For not found page
import NotFound from '../components/404';
// For not authentication page
import ProtectedRoute from '../components/auth-guard/protected-route';
import PublicRoute from '../components/auth-guard/public-route';
import EditTournament from '../pages/admin/tournaments/edit';
import ViewTournament from '../pages/admin/tournaments/view';
import Teams from '../pages/admin/tournaments/teams';
import Loader from '../components/loader';
import { onMessageListener } from '../firebase';
import { useDispatch } from 'react-redux';
import { handleRecievedNotification } from '../actions/Actions';
import { toast } from 'react-toastify';
import GroupPhase from '../pages/admin/tournaments/brackets/group-phase';
import DoubleElimination from '../pages/admin/tournaments/brackets/double-elimination';
import AboutUs from '../pages/frontend/about-us';
import ContactUs from '../pages/frontend/contact-us';
import FAQs from '../pages/frontend/faqs';
import PrivacyPolicy from '../pages/frontend/privacy-policy';
import TermsConditions from '../pages/frontend/terms-conditions';
import Arenas from '../pages/frontend/arenas';
import Championships from '../pages/frontend/championships';
import Ranking from '../pages/frontend/ranking';
import News from '../pages/frontend/news';
import NewsView from '../pages/frontend/news/view';
import ArenaDetails from '../pages/frontend/arenas/view';
import ChampionshipView from '../pages/frontend/championships/view';
import ChampionGroupPhase from '../pages/frontend/championships/brackets/group-phase';
import ChampionDoubleElimination from '../pages/frontend/championships/brackets/double-elimination';
import PaymentSuccess from '../pages/admin/payment/PaymentSuccess';
import PaymentFailed from '../pages/admin/payment/PaymentFailed';
import PaymentCancelled from '../pages/admin/payment/PaymentCancelled';

const Routers = () => {
  const dispatch = useDispatch();

  const NotificationMsg = ({ title, body }) => (
    <div className="notify-msg">
      <h6>{title}</h6>
      <p>{body}</p>
    </div>
  );

  onMessageListener()
    .then(payload => {
      // console.log('Recieved notification==>', payload);
      const notification = {
        id: Date.now(),
        foreign_id: payload.data.foreign_id,
        created_at: new Date().toISOString(),
        type: payload.data.type,
        title: payload.data.title,
        body: payload.data.body,
        icon: payload.data.icon,
      };
      dispatch(handleRecievedNotification(notification));
      toast(
        <NotificationMsg title={notification.title} body={notification.body} />,
      );
    })
    .catch(err => console.log('Notification failed: ', err));

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* For public route */}
        <Route element={<FrontendLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/arenas" element={<Arenas />} />
          <Route path="/arenas/view/:id" element={<ArenaDetails />} />
          <Route path="/championships" element={<Championships />} />
          <Route
            path="/championships/view/:id"
            element={<ChampionshipView />}
          />
          <Route
            path="/championships/view/group-phase/:id"
            element={<ChampionGroupPhase />}
          />
          <Route
            path="/championships/view/double-elimination/:id"
            element={<ChampionDoubleElimination />}
          />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/view/:id" element={<NewsView />} />
        </Route>
        {/* For auth route */}
        <Route
          element={
            <PublicRoute>
              <AuthLayout />
            </PublicRoute>
          }>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/email-verification" element={<EmailVerification />} />
          <Route path="/forgot-password/:token" element={<ForgotPassword />} />
        </Route>
        {/* For admin route */}
        <Route
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />

          <Route path="/arena" element={<Arena />} />
          <Route path="/arena/view/:id" element={<ArenaView />} />

          <Route path="/athelets" element={<Athletes />} />
          <Route path="/athelets/view/:id" element={<AthleteView />} />

          <Route path="/tournaments" element={<Tournaments />} />
          <Route path="/tournaments/add" element={<AddTournament />} />
          <Route path="/tournaments/edit/:id" element={<EditTournament />} />
          <Route path="/tournaments/view/:id" element={<ViewTournament />} />
          <Route path="/tournaments/teams/:id" element={<Teams />} />
          <Route path="/tournaments/group-phase/:id" element={<GroupPhase />} />
          <Route path="/tournaments/double-elimination/:id" element={<DoubleElimination />}/>

          <Route path="/payment" element={<Payment />} />
        </Route>
        {/* For not found route */}
        <Route path="/email-verified-success/:token" element={<EmailVerifiedSuccess />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failed" element={<PaymentFailed />} />
        <Route path="/payment-cancelled" element={<PaymentCancelled />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};
export default Routers;
