import './index.scss';
import React, { useState, useEffect } from "react";

const BackToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <button type="button"
            className={`backToTopBtn fa-regular fa-arrow-up ${showTopBtn ? 'show' : ''}`}
            onClick={goToTop}
        />
    )
}
export default BackToTop;