import React, { useEffect, useState } from "react";
import './index.scss';
import TitleBar from "../components/titlte-bar";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Alert, FloatingLabel, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AddContactUs } from "../../../actions/Cms";
import { clearMessage } from "../../../actions/Actions";
import PhoneInputField from "../../../components/phone-input-field";

const ContactUs = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { contact_info, message, isError, isLoading } = useSelector(state => state.Cms);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: ""
        },
        validationSchema: yup.object({
            name: yup.string().min(2, t("Name must have atleast 2 characters.")).required(t("Please enter your name.")),
            email: yup.string().email(t("Invalid email address.")).required(t("Please enter email address.")),
            phone: yup.number().min(10, t("Phone must have 10 digits.")).required(t("Please enter phone number.")),
            subject: yup.string().required(t("Please enter a subject.")),
            message: yup.string().required(t("Please enter message.")),
        }),
        onSubmit: (values) => {
            // console.log("contact value====", values)
            dispatch(AddContactUs(values, formik));
        }
    });

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    // console.log("contact info====", contact_info)

    return (
        <div className='mainBase pt-0'>
            <TitleBar title="Contact Us" linkUrl="/" />
            <div className='cmn-content-body contact-wrapper'>
                <div className='container'>
                    <div className="row contact-form-row">
                        <div className="col-lg-5">
                            <div className="contact-info">
                                <div className="contact-item">
                                    <i className="bi bi-geo-alt"></i>
                                    <div className="contact-item-info">
                                        <h6>{t("Address")}</h6>
                                        <p>{contact_info?.address ? contact_info?.address : "N/A"}</p>
                                    </div>
                                </div>
                                <div className="contact-item">
                                    <i className="bi bi-telephone"></i>
                                    <div className="contact-item-info">
                                        <h6>{t("Call Us")}</h6>
                                        <p>{contact_info?.phone ? "+"+contact_info?.phone : "N/A"}</p>
                                    </div>
                                </div>
                                <div className="contact-item">
                                    <i className="bi bi-envelope"></i>
                                    <div className="contact-item-info">
                                        <h6>{t("Email Us")}</h6>
                                        <p>{contact_info?.email ? contact_info?.email : "N/A"}</p>
                                    </div>
                                </div>
                                <div className="contact-item">
                                    <div className="contact-item-info d-flex align-items-center">
                                        <h6>{t("Follow Us")}</h6>
                                        <div className='socialBg ms-3'>
                                            <Link to={contact_info?.facebook_url} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f"></i></Link>
                                            <Link to={contact_info?.whatsapp_url} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i></Link>
                                            <Link to={contact_info?.instagram_url} target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 mt-4 mt-lg-0">
                            <div className="loginCard profileCard my-0">
                                <h3 className="contact-title mb-4">{t("Send us a Message")}</h3>
                                {message && isError && typeof message === 'string' && (
                                    <Alert className='mb-3' variant="danger" onClose={() => dispatch(clearMessage())} dismissible>
                                        {message}
                                    </Alert>
                                )}
                                {message && !isError && (
                                    <Alert className='mb-3' variant="success" onClose={() => dispatch(clearMessage())} dismissible>
                                        {t("Thank you for reaching out! We've received your message and will respond soon.")}
                                    </Alert>
                                )}
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='mb-3'>
                                        <FloatingLabel controlId="Name" label={t("Name")} className='cmn-style-floating'>
                                            <Form.Control type="text" name="name" placeholder="Enter name" onChange={formik.handleChange} value={formik.values.name} />
                                            {formik.touched.name && formik.errors.name ? <p className="errorTxt">{formik.errors.name}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <div className='mb-3'>
                                        <FloatingLabel controlId="Email" label={t("Email Address")} className='cmn-style-floating'>
                                            <Form.Control type="email" name="email" placeholder="Enter email address" onChange={formik.handleChange} value={formik.values.email} />
                                            {formik.touched.email && formik.errors.email ? <p className="errorTxt">{formik.errors.email}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <div className='mb-3'>
                                        <PhoneInputField formik={formik} fieldName={"phone"} labelName={t('Phone Number')}/>
                                    </div>
                                    <div className='mb-3'>
                                        <FloatingLabel controlId="Subject" label={t("Subject")} className='cmn-style-floating'>
                                            <Form.Control type="text" name="subject" placeholder="Enter subject" onChange={formik.handleChange} value={formik.values.subject} />
                                            {formik.touched.subject && formik.errors.subject ? <p className="errorTxt">{formik.errors.subject}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <div className='mb-3'>
                                        <FloatingLabel controlId="Messages" label={t("Message")} className='cmn-style-floating'>
                                            <Form.Control as="textarea" name="message" placeholder="Enter your message" className='custTxrarea' rows={5} onChange={formik.handleChange} value={formik.values.message} />
                                            {formik.touched.message && formik.errors.message ? <p className="errorTxt">{formik.errors.message}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <button className='loginButton' type='submit' disabled={isLoading}>
                                        {isLoading ? <Spinner animation="border" size="sm" /> : <span>{t("Submit")}</span>}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactUs;