import React from "react";
import "./index.scss";

const Loader = () => {
    return (
        <div className="loading-wrapper">
            <div className="loader-ring">
                <span className="loader-center-img"></span>
                <span className="loader-circle-roted"></span>
            </div>
        </div>
    );
}

export default Loader;