import './index.scss';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FloatingLabel, Form } from 'react-bootstrap';
import { fetchDeleteTournament } from '../../../actions/Tournaments';
import { getSearchedByChampionshipName } from '../../../helpers';
import Loader from '../../../components/loader';
import DeleteModal from '../../../components/delete-modal';
import LoadMore from '../../../components/load-more';
import { SearchInputField } from '../../../components/search-filter';
import moment from 'moment';

const Tournaments = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tournaments, isLoading } = useSelector(state => state.Tournaments);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredItems = getSearchedByChampionshipName(tournaments, searchTerm);
    // FOR-LOAD-MORE 
    const [visibleItems, setVisibleItems] = useState(20);
    const [deleteModal, setDeleteModal] = useState(false);
    const [itemId, setItemId] = useState('');

    const TournamentItems = filteredItems && filteredItems.length > 0 ? filteredItems.slice(0, visibleItems).map((item) => (
        <tr key={item?.id}>
            <td data-label={t("Tournament Name")}>{item?.championship_name ? item.championship_name : "N/A"}</td>
            <td data-label={t("Tournament Type")}>{item?.type?.type_name ? t(item?.type?.type_name) : "N/A"}</td>
            <td data-label={t("From Date")}>{item?.from_date ? moment(item.from_date).format("DD-MM-YYYY") : "N/A"}</td>
            <td data-label={t("From Time")}>{item?.from_time ? moment(item.from_time, "HH:mm").format("HH:mm") : "N/A"}</td>
            {/* <td data-label={t("To Date")}>{item?.to_date ? item.to_date : "N/A"}</td>
            <td data-label={t("To Time")}>{item?.to_time ? item.to_time : "N/A"}</td> */}
            <td data-label={t("No. Duos")}>{item?.duo?.duo_number ? item?.duo?.duo_number : "N/A"}</td>
            <td data-label={t("Action")} className='arenaBtns'>
                {item?.status === "2" || item?.status === 2 && (
                    <>
                        <button type='button' onClick={() => {
                            if (item?.types_id === "1" || item?.types_id === 1) {
                                navigate(`/tournaments/double-elimination/${item?.id}`)
                            } else {
                                navigate(`/tournaments/group-phase/${item?.id}`)
                            }
                        }}><i className="fa-regular fa-play"></i></button>
                        <button type='button' onClick={() => handleShowConfirmModal(item.id)}><i className="fa-regular fa-trash"></i></button>
                    </>
                )}
                {item?.status === "1" || item?.status === 1 && (
                    <>
                        <button type='button' onClick={() => {
                            if (item?.status === "1" || item?.status === 1) {
                                navigate(`/tournaments/teams/${item?.id}`);
                            } else {
                                if (item?.types_id === "1" || item?.types_id === 1) {
                                    navigate(`/tournaments/double-elimination/${item?.id}`)
                                } else {
                                    navigate(`/tournaments/group-phase/${item?.id}`)
                                }
                            }
                        }}>
                            <i className="fa-regular fa-circle-plus"></i>
                        </button>
                        <button type='button' onClick={() => navigate(`/tournaments/edit/${item?.id}`)}><i className="fa-regular fa-pencil"></i></button>
                        <button type='button' onClick={() => handleShowConfirmModal(item.id)}><i className="fa-regular fa-trash"></i></button>
                    </>
                )}
            </td>
        </tr>
    )) : <tr className='p-0'><td colSpan="6" className='text-center'>{t("Tournaments")} {t("Not Found")}.</td></tr>;


    const handleShowConfirmModal = (itemId) => {
        setDeleteModal(true);
        setItemId(itemId);
    }

    // console.log("tournaments list====>", tournaments)

    return (
        <>
            <div className='arenaBase'>
                <div className='topFlex mb-3'>
                    <h2 className='admin-page-title mb-3'>{t("Tournaments")}</h2>
                    <div className='admin-page-header-right'>
                        <div className='search-filter-bx'>
                            <div className='search-filter-col search-filter'>
                                <SearchInputField fieldName={"search"} labelName={t("Search")} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                                <button className='btn btn-reset ms-3' onClick={() => setSearchTerm('')}><i className="fa-regular fa-light fa-rotate-left"></i></button>
                                <Link className='addBtns ms-3' to="/tournaments/add"><i className="fa-regular fa-circle-plus"></i> <span>{t("Add Tournament")}</span></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <table className='mb-3'>
                    <thead>
                        <tr>
                            <th scope="col">{t("Tournament Name")}</th>
                            <th scope="col">{t("Tournament Type")}</th>
                            <th scope="col">{t("From Date")}</th>
                            <th scope="col">{t("From Time")}</th>
                            {/* <th scope="col">{t("To Date")}</th>
                            <th scope="col">{t("To Time")}</th> */}
                            <th scope="col">{t("No. Duos")}</th>
                            <th scope="col">{t("Action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <tr><td colSpan="6"><Loader /></td></tr> : TournamentItems}
                    </tbody>
                </table>

                {!isLoading && filteredItems.length > 20 && (
                    <LoadMore visibleItems={visibleItems} setVisibleItems={setVisibleItems} perPage={20} totalItems={filteredItems.length} />
                )}

                <Link to="/dashboard" className='backtxt'><i className="fa-light fa-arrow-left-long"></i> {t("Back")}</Link>
            </div>
            <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} action={() => dispatch(fetchDeleteTournament(itemId))} />
        </>
    )
}

export default Tournaments;