import React, { useEffect } from "react";
import './index.scss';
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loader";
import { getHelpList } from "../../../actions/Cms";
import TitleBar from "../components/titlte-bar";

const FAQs = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { help_list, isLoading } = useSelector(state => state.Cms);

    useEffect(() => {
        dispatch(getHelpList());
    }, [dispatch]);

    return (
        <div className='mainBase pt-0'>
            <TitleBar title="FAQ's" linkUrl="/" />
            <div className='cmn-content-body help-wrapper'>
                {isLoading ? <Loader /> :
                    <div className='container'>
                        <Accordion>
                            {help_list && help_list.length > 0 ?
                                help_list.map((item, index) => {
                                    return (
                                        <Accordion.Item key={item.id} eventKey={index + 1}>
                                            <Accordion.Header>{i18n.language === "en" || i18n.language === "en-US" ? item?.en_question : item?.pt_question}</Accordion.Header>
                                            <Accordion.Body>
                                                {i18n.language === "en" || i18n.language === "en-US" ? item?.en_answer : item?.pt_answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                }) :
                                <p className="mb-0 text-center">{t("Help")} {t("Not Found")}</p>
                            }
                        </Accordion>
                    </div>}
            </div>
        </div>
    )
}
export default FAQs;