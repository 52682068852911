import React, { useEffect, useState } from 'react';
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  SingleLineSeed,
} from 'react-brackets';
import MatchUpdateModal from '../components/match-update-modal';
import MatchViewModal from '../components/match-view-modal';
import './index.scss';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
const MatchesBracket = ({
  data,
  stages,
  totalTeam,
  category,
  tournamentId,
  tournamentType,
  tournamentStatus,
  totalMatches,
}) => {
  const { t } = useTranslation();
  const [matchData, setMatchData] = useState([]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [matchSeed, setMatchSeed] = useState('');
  const [matchNo, setMatchNo] = useState('');
  const [matchList, setMatchList] = useState([]);
  function createArray(N) {
    if (N == 0 || N < 1) return [];
    let arr = [...Array(N).keys()].map(i => i + 1);
    return [...arr.slice(0, -1), 'q'];
  }

  const stagesFunc = async data => {
    let stageArr = createArray(stages);
    let matchesList = [];
    let totalMatchList = [];
    await stageArr.map(stage => {
      let stagesMatches = data.filter(item => item.level == stage);
      totalMatchList.push(...stagesMatches);
      if (totalTeam == 28 && category == 'winner' && stage == 1) {
        let tempArr = [];
        for (let i = 0; i < 12; i = i + 3) {
          tempArr.push({});
          tempArr.push(stagesMatches[i]);
          tempArr.push(stagesMatches[i + 1]);
          tempArr.push(stagesMatches[i + 2]);
        }
        let temp1 = {
          title: `${t('Round')} ${stage}`,
          seeds: tempArr,
        };

        // console.log('======temp1=======>', temp1);
        matchesList.push(temp1);
      } else {
        let temp = {
          title: stage == 'q' ? t('Quater Final') : `${t('Round')} ${stage}`,
          seeds: stagesMatches,
        };
        matchesList.push(temp);
      }
    });
    setMatchList(totalMatchList);
    let stage1match = data.filter(item => item.level == 1);
    let stage2match = data.filter(item => item.level == 2);
    let lessMatch = stage2match.length - stage1match.length;

    if (totalTeam == 20 && category == 'winner') {
      for20Winner(matchesList, lessMatch);
    } else if (totalTeam == 28 && category == 'looser') {
      for28Loser(matchesList, lessMatch);
    } else {
      let emptymatch = [];
      let lessMatchArr = createArray(lessMatch);

      lessMatchArr.map(item => {
        let temp = {
          seeds: [],
        };
        emptymatch.push(temp);
      });

      // console.log("matchesList==>", matchesList)
      let arr = []
      arr = matchesList[0]?.seeds;

      let newData = [...emptymatch, ...arr];
      // console.log('newData', newData);
      matchesList[0] = {
        title: `${t('Round')} 1`,
        seeds: newData,
      };
      setMatchData(matchesList);
    }
  };

  const totalMatchFunc = () => {
    let stageArr = createArray(stages);
    let totalMatchList = [];
    stageArr.map(stage => {
      let stagesMatches = totalMatches.filter(item => item.level == stage);
      totalMatchList.push(...stagesMatches);
    });
    setMatchList(totalMatchList);
    // let tempA = _.sortBy(totalMatches, ['winners_side', 'level']);
    // console.log('====tempA=======>', tempA);
    // setMatchList(tempA);
  };

  const for28Loser = (matchesList, lessMatch) => {
    let totalArr = matchesList;
    let newArr = [];
    // console.log('=======20 winner=======>');
    let lessMatchArr = createArray(lessMatch);

    lessMatchArr.map((item, index) => {
      let temp = {
        seeds: [],
      };
      newArr.push(temp);
      newArr.push(matchesList[0].seeds[index]);
    });

    totalArr[0] = {
      title: `Round 1`,
      seeds: [...newArr],
    };

    setMatchData([...totalArr]);
  };

  const for20Winner = (matchesList, lessMatch) => {
    let totalArr = matchesList;
    let newArr = [];
    // console.log('=======20 winner=======>');
    let lessMatchArr = createArray(lessMatch);

    lessMatchArr.map((item, index) => {
      let temp = {
        seeds: [],
      };

      newArr.push(matchesList[0].seeds[index]);
      newArr.push(temp);
    });

    totalArr[0] = {
      title: `Round 1`,
      seeds: [...newArr],
    };

    setMatchData([...totalArr]);
  };

  const handleUpdateModal = data => {
    const pos = matchList.map(e => e.id).indexOf(data.id);
    // console.log('======pos======>', pos + 1);
    setMatchNo(pos + 1);
    setMatchSeed(data);
    setOpenUpdateModal(true);
  };

  const handleViewModal = data => {
    const pos = matchList.map(e => e.id).indexOf(data.id);
    // console.log('======pos======>', pos + 1);
    setMatchNo(pos + 1);

    setMatchSeed(data);
    setOpenViewModal(true);
  };

  useEffect(() => {
    stagesFunc(data);
    //totalMatchFunc();
  }, [data]);

  const CustomSeed = ({ seed, breakpoint, roundIndex, rounds }) => {
    const isLineConnector =
      rounds[roundIndex]?.seeds?.length ===
      (rounds[roundIndex + 1]?.seeds?.length || 0);
    const Wrapper =
      isLineConnector || rounds[roundIndex].title == t('Quater Final')
        ? SingleLineSeed
        : Seed;
    //const Wrapper = SingleLineSeed;
    // console.log("seed=View===", seed)
    // console.log("tournamentStatus====", tournamentStatus)
    return (
      <>
        <Wrapper
          mobileBreakpoint={breakpoint}
          style={{ fontSize: 12 }}
          className={`${rounds[roundIndex]?.title == t('Quater Final') ||
            seed?.id == undefined
            ? 'remove_line'
            : ''
            }`}>
          {seed && seed?.id && seed?.id !== null ? (
            <SeedItem
              onClick={() => {
                if (tournamentStatus === 1 && seed?.team_id1 != null && seed?.team_id2 != null) {
                  handleViewModal(seed);
                } else {
                  if (tournamentStatus === 2 && seed?.played === "0" && seed?.team_id1 != null && seed?.team_id2 != null) {
                    handleUpdateModal(seed);
                  } else if (tournamentStatus === 2 && seed?.played === "true" && seed?.team_id1 != null && seed?.team_id2 != null) {
                    handleViewModal(seed);
                  } else {
                    handleViewModal(seed);
                  }
                }
              }}>
              <div>
                <SeedTeam
                  className={`game-style game-1 ${seed?.played !== 'false' && seed?.played != false
                    ? seed?.winning_team_id == seed?.team_id1
                      ? 'win'
                      : 'lose'
                    : ''
                    }`}>
                  {/* <span className='player-id'>#{seed?.team_id1}</span> */}
                  {seed?.team_id1 != null ? (
                    <span className="player-name">
                      {t("Team")} {seed?.team1?.team_division_id}
                    </span>
                  ) : (
                    <span className="player-name">{t('No Team')}</span>
                  )}

                  {seed?.played === true || seed?.played === 'true' ? (
                    <span className="player-score">
                      {seed?.team_id1 === seed?.winning_team_id
                        ? seed?.winning_points
                        : seed?.losing_points}
                    </span>
                  ) : (
                    <span className="player-score">--</span>
                  )}
                </SeedTeam>
                <SeedTeam
                  className={`game-style game-2 ${seed?.played !== 'false' && seed?.played != false
                    ? seed?.winning_team_id == seed?.team_id2
                      ? 'win'
                      : 'lose'
                    : ''
                    }`}>
                  {/* <span className='player-id'>#{seed?.team_id2}</span> */}
                  <span className="player-name">
                    {seed?.team_id2 != null
                      ? t("Team") + " " + seed?.team2?.team_division_id
                      : t('No Team')}
                  </span>
                  {/* <span className='player-score'>{seed?.winning_team_id==seed?.team_id2? seed?.winning_points:seed?.losing_points}</span> */}
                  {seed?.played === true || seed?.played === 'true' ? (
                    <span className="player-score">
                      {seed?.team_id2 === seed?.winning_team_id
                        ? seed?.winning_points
                        : seed?.losing_points}
                    </span>
                  ) : (
                    <span className="player-score">--</span>
                  )}
                </SeedTeam>
              </div>
            </SeedItem>
          ) : (
            <div style={{ height: '70px' }}></div>
          )}
        </Wrapper>
      </>
    );
  };

  return (
    <div>
      {matchData?.length > 0 && (
        <Bracket
          rounds={matchData}
          renderSeedComponent={CustomSeed}
          bracketClassName={'bracket-mlr-1 mt-3'}
          roundTitleComponent={(title, roundIndex) => {
            return (
              <div className="bracket-step-title">
                <span>{title}</span>
              </div>
            );
          }}
        />
      )}
      {openUpdateModal && (
        <MatchUpdateModal
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          matchSeed={matchSeed}
          tournamentId={tournamentId}
          tournamentType={tournamentType}
          tournamentStatus={tournamentStatus}
          matchNumber={
            category == 'winner'
              ? `${t('Winning')} ${t('Match')}  ${matchNo}`
              : `${t('Losing')} ${t('Match')}  ${matchNo}`
          }
        />
      )}
      {openViewModal && (
        <MatchViewModal
          openViewModal={openViewModal}
          setOpenViewModal={setOpenViewModal}
          matchSeed={matchSeed}
          matchNumber={
            category == 'winner'
              ? `${t('Winning')} ${t('Match')}  ${matchNo}`
              : `${t('Losing')} ${t('Match')}  ${matchNo}`
          }
        />
      )}
    </div>
  );
};

export default MatchesBracket;
