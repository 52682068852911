import React from 'react';
import noImg from '../../../../../../assets/images/no-image.jpg';
import { useSelector } from 'react-redux';
import './index.scss';
import { useTranslation } from 'react-i18next';

const MatchHeader = ({ matchSeed }) => {
  const { t } = useTranslation();
  const { tournament, basePoints } = useSelector(state => state.Tournaments);
  const filterFtvPoints = (ftvHistory, playerId) => {
    return ftvHistory && ftvHistory?.find(item => item.player_id === playerId);
  };
  const team1Player1 = filterFtvPoints(
    matchSeed?.ftv_history,
    matchSeed?.team1?.athlete_player1_id,
  );
  const team1Player2 = filterFtvPoints(
    matchSeed?.ftv_history,
    matchSeed?.team1?.athlete_player2_id,
  );
  const team2Player1 = filterFtvPoints(
    matchSeed?.ftv_history,
    matchSeed?.team2?.athlete_player1_id,
  );
  const team2Player2 = filterFtvPoints(
    matchSeed?.ftv_history,
    matchSeed?.team2?.athlete_player2_id,
  );
  // console.log('matchSeed===>', matchSeed);
  // console.log('basePoints===>', basePoints);
  return (
    <>
      <div className="game-popup-header">
        <div
          className={`game-popup-player bg ${matchSeed?.played === true || matchSeed?.played === 'true'
            ? matchSeed?.team_id1 === matchSeed?.winning_team_id
              ? 'win'
              : 'lose'
            : ''
            }`}>
          <h4 title="">
            {matchSeed?.team1?.team_division_id
              ? t('Team') + " " + matchSeed?.team1?.team_division_id
              : '?'}
          </h4>
          {matchSeed?.team1?.athlete_player1_id ?
            <div className="athlete-card">
              <div className="athlete-card-img">
                {matchSeed?.team1?.athlete_player1?.profile?.profile_url ? <img src={matchSeed?.team1?.athlete_player1?.profile?.profile_url} alt={matchSeed?.team1?.athlete_player1?.display_name} /> : <img src={noImg} alt='No image' />}
              </div>
              <div className="athlete-card-body">
                <h5>
                  <span className="ranking-id">
                    #{matchSeed?.team1?.athlete_player1_id}
                  </span>
                  {matchSeed?.team1?.athlete_player1?.display_name}
                </h5>
                <p className="ftv-points">
                  {t("FTV points")}:&nbsp;
                  {matchSeed?.team1_athelete1_ftv === "0" ? basePoints : matchSeed?.team1_athelete1_ftv}
                  {/* {tournament?.status === 2 && !matchSeed?.ftv_history && matchSeed?.played === '0' && basePoints} */}
                  {/* {team1Player1 && team1Player1.is_md10 !== 1
                    ? matchSeed?.team1_athelete1_ftv
                    : team1Player1?.baseline_point} */}
                  &nbsp;
                  {matchSeed?.played === true || matchSeed?.played === 'true' ? (
                    matchSeed?.team_id1 === matchSeed?.winning_team_id ? (
                      <span className="player-score">
                        (+{team1Player1.result})
                      </span>
                    ) : (
                      <span className="player-score">
                        ({team1Player1.result})
                      </span>
                    )
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </div>
            : <div className="athlete-card text-white">TBD</div>}
          {matchSeed?.team1?.athlete_player2_id ?
            <div className="athlete-card">
              <div className="athlete-card-img">
                {matchSeed?.team1?.athlete_player2?.profile?.profile_url ? <img src={matchSeed?.team1?.athlete_player2?.profile?.profile_url} alt={matchSeed?.team1?.athlete_player2?.display_name} /> : <img src={noImg} alt='No image' />}
              </div>
              <div className="athlete-card-body">
                <h5>
                  <span className="ranking-id">
                    #{matchSeed?.team1?.athlete_player2_id}
                  </span>
                  {matchSeed?.team1?.athlete_player2?.display_name}
                </h5>
                <p className="ftv-points">
                  {t("FTV points")}:&nbsp;
                  {matchSeed?.team1_athelete2_ftv === "0" ? basePoints : matchSeed?.team1_athelete2_ftv}
                  {/* {tournament?.status === 1 && matchSeed?.ftv_history !== 0 && matchSeed?.played === '0' && basePoints}
                  {tournament?.status === 2 && !matchSeed?.ftv_history && matchSeed?.played === '0' && basePoints}
                  {team1Player2 && team1Player2.is_md10 !== 1
                    ? matchSeed?.team1_athelete2_ftv
                    : team1Player2?.baseline_point} */}
                  &nbsp;
                  {matchSeed?.played === true || matchSeed?.played === 'true' ? (
                    matchSeed?.team_id1 === matchSeed?.winning_team_id ? (
                      <span className="player-score">
                        (+{team1Player2.result})
                      </span>
                    ) : (
                      <span className="player-score">
                        ({team1Player2.result})
                      </span>
                    )
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </div>
            : <div className="athlete-card text-white">TBD</div>}
          {matchSeed?.played === true || matchSeed?.played === 'true' ? (
            <span className="match-point">
              {matchSeed?.team_id1 === matchSeed?.winning_team_id
                ? matchSeed?.winning_points
                : matchSeed?.losing_points}
            </span>
          ) : (
            ''
          )}
        </div>
        <div className="vs">
          <span>VS.</span>
        </div>
        <div
          className={`game-popup-player ${matchSeed?.played === true || matchSeed?.played === 'true'
            ? matchSeed?.team_id2 === matchSeed?.winning_team_id
              ? 'win'
              : 'lose'
            : ''
            }`}>
          <h4 title="">
            {matchSeed?.team2?.team_division_id
              ? t('Team') + " " + matchSeed?.team2?.team_division_id
              : '?'}
          </h4>
          {matchSeed?.team2?.athlete_player1_id ?
            <div className="athlete-card">
              <div className="athlete-card-img">
                {matchSeed?.team2?.athlete_player1?.profile?.profile_url ? <img src={matchSeed?.team2?.athlete_player1?.profile?.profile_url} alt={matchSeed?.team2?.athlete_player1?.display_name} /> : <img src={noImg} alt='No image' />}
              </div>
              <div className="athlete-card-body">
                <h5>
                  <span className="ranking-id">
                    #{matchSeed?.team2?.athlete_player1_id}
                  </span>
                  {matchSeed?.team2?.athlete_player1?.display_name}
                </h5>
                <p className="ftv-points">
                  {t("FTV points")}:&nbsp;
                  {matchSeed?.team2_athelete1_ftv === "0" ? basePoints : matchSeed?.team2_athelete1_ftv}
                  {/* {tournament?.status === 1 && matchSeed?.ftv_history !== 0 && matchSeed?.played === '0' && basePoints}
                  {tournament?.status === 2 && !matchSeed?.ftv_history && matchSeed?.played === '0' && basePoints}
                  {team2Player1 && team2Player1.is_md10 !== 1
                    ? matchSeed?.team2_athelete1_ftv
                    : team2Player1?.baseline_point} */}
                  &nbsp;
                  {matchSeed?.played === true || matchSeed?.played === 'true' ? (
                    matchSeed?.team_id2 === matchSeed?.winning_team_id ? (
                      <span className="player-score">
                        (+{team2Player1.result})
                      </span>
                    ) : (
                      <span className="player-score">
                        ({team2Player1.result})
                      </span>
                    )
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </div>
            : <div className="athlete-card text-white">TBD</div>}
          {matchSeed?.team2?.athlete_player2_id ?
            <div className="athlete-card">
              <div className="athlete-card-img">
                {matchSeed?.team2?.athlete_player2?.profile?.profile_url ? <img src={matchSeed?.team2?.athlete_player2?.profile?.profile_url} alt={matchSeed?.team2?.athlete_player2?.display_name} /> : <img src={noImg} alt='No image' />}
              </div>
              <div className="athlete-card-body">
                <h5>
                  <span className="ranking-id">
                    #{matchSeed?.team2?.athlete_player2_id}
                  </span>
                  {matchSeed?.team2?.athlete_player2?.display_name}
                </h5>
                <p className="ftv-points">
                  {t("FTV points")}:&nbsp;
                  {matchSeed?.team2_athelete2_ftv === "0" ? basePoints : matchSeed?.team2_athelete2_ftv}
                  {/* {tournament?.status === 1 && matchSeed?.ftv_history !== 0 && matchSeed?.played === '0' && basePoints}
                  {tournament?.status === 2 && !matchSeed?.ftv_history && matchSeed?.played === '0' && basePoints}
                  {team2Player2 && team2Player2.is_md10 !== 1
                    ? matchSeed?.team2_athelete2_ftv
                    : team2Player2?.baseline_point} */}
                  &nbsp;
                  {matchSeed?.played === true || matchSeed?.played === 'true' ? (
                    matchSeed?.team_id2 === matchSeed?.winning_team_id ? (
                      <span className="player-score">
                        (+{team2Player2.result})
                      </span>
                    ) : (
                      <span className="player-score">
                        ({team2Player2.result})
                      </span>
                    )
                  ) : (
                    ''
                  )}
                </p>
              </div>
            </div>
            : <div className="athlete-card text-white">TBD</div>}
          {matchSeed?.played === true || matchSeed?.played === 'true' ? (
            <span className="match-point">
              {matchSeed?.team_id2 === matchSeed?.winning_team_id
                ? matchSeed?.winning_points
                : matchSeed?.losing_points}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default MatchHeader;
