import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL + "arena/";

export const fetchDuosList = () => {
  return axios.get(API_URL + "duo-list", { headers: authHeader() })
    .then(response => {
      return response.data
    })
    .catch(error => {
      toast.error(`Duos list fetch error: ${error.message}`);
    });
};

export const fetchCategoriesList = () => {
  return axios.get(API_URL + "category-list", { headers: authHeader() })
    .then(response => {
      return response.data
    })
    .catch(error => {
      toast.error(`Category list fetch error: ${error.message}`);
    });
};

export const fetchTypesList = () => {
  return axios.get(API_URL + "type-list", { headers: authHeader() })
    .then(response => {
      return response.data
    })
    .catch(error => {
      toast.error(`Type list fetch error: ${error.message}`);
    });
};

export const fetchTeamList = () => {
  return axios.get(API_URL + "team-division", { headers: authHeader() })
    .then(response => {
      return response.data
    })
    .catch(error => {
      toast.error(`Type list fetch error: ${error.message}`);
    });
};

export const fetchNotificationList = () => {
  return axios.get(API_URL + "notification-list", { headers: authHeader() })
    .then(response => {
      return response.data
    })
    .catch(error => {
      toast.error(`Notification list fetch error: ${error.message}`);
    });
};

export const fetchClearAllNotification = () => {
  return axios.get(API_URL + "notification-clear", { headers: authHeader() })
    .then(response => {
      return response.data
    })
    .catch(error => {
      toast.error(`Notification clear all fetch error: ${error.message}`);
    });
};