import { useEffect, useRef } from "react";
import './index.scss';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const PhoneInputField = ({ formik, fieldName, labelName }) => {
  const inputRef = useRef(null);
  const newLabel = document.createElement('label');

  useEffect(() => {
    if (inputRef.current) {
      newLabel.textContent = labelName;
      inputRef.current.parentNode.insertBefore(newLabel, inputRef.current.nextSibling);
    }
  }, []);

  return (
    <>
      <PhoneInput
        inputProps={{ ref: inputRef }}
        country={'pt'}
        value={`${formik.values[fieldName]}`}
        placeholder='Enter phone number'
        name={fieldName}
        containerClass={`w-100 cmn-style-floating form-floating phone-input-style`}
        inputClass={`f-phone-input`}
        specialLabel={false}
        onChange={phone => formik.setFieldValue(fieldName, phone)}
      />
      {formik.touched[fieldName] && formik.errors[fieldName] ? <p className="errorTxt">{formik.errors[fieldName]}</p> : null}
    </>
  );
}
export default PhoneInputField;