import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from 'react-bootstrap';
import MatchesBracket from './matches-bracket';
import Loader from '../../../../../components/loader';

import FinalStage from '../components/final-matches-bracket';
import {
  fetchGetDoubleEliminationBracketList,
  fetchGetDoubleEliminationFinalist,
} from '../../../../../actions/Cms';
import BannerHeader from '../components/banner-header';

const ChampionDoubleElimination = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    tournament,
    tournamentMatch,
    isLoading,
    winningStages,
    losingStages,
    finalMatches,
  } = useSelector(state => state.Tournaments);

  useEffect(() => {
    dispatch(fetchGetDoubleEliminationBracketList(id));
    dispatch(fetchGetDoubleEliminationFinalist(id));
  }, []);

  // console.log("tournament===>", tournament)
  // console.log("tournamentMatch===>", tournamentMatch.length)

  return (
    <>
      <div className="mainBase pt-0">
        <BannerHeader
          arenaId={tournament?.arena_id}
          picUrl={tournament?.pic_url}
          tournamentName={tournament?.championship_name}
          duoNumber={tournament?.duo?.duo_number}
          status={tournament?.status}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="bracketContent">
            <div className="container">
              <div className="group-phase-tabs double-elimination-tabs">
                <Tabs defaultActiveKey="prematches" transition={false}>
                  <Tab eventKey="prematches" title={t('Pre Matches')}>
                    {tournamentMatch && tournamentMatch?.length > 0 ? (
                      <>
                        <div className="match-flow-scrollbar">
                          <MatchesBracket
                            category={'winner'}
                            totalTeam={tournament?.duo?.duo_number}
                            tournamentId={id}
                            tournamentType={tournament?.type?.type_name}
                            tournamentStatus={tournament?.status}
                            stages={winningStages}
                            data={tournamentMatch?.filter(
                              item => item.winners_side == 0,
                            )}
                          />
                        </div>
                        <h3 className="mt-4 px-3 admin-page-title">
                          {t('Losing Team')}
                        </h3>
                        <div className="match-flow-scrollbar">
                          <MatchesBracket
                            category={'looser'}
                            totalTeam={tournament?.duo?.duo_number}
                            tournamentId={id}
                            tournamentType={tournament?.type?.type_name}
                            tournamentStatus={tournament?.status}
                            stages={losingStages}
                            data={tournamentMatch?.filter(
                              item => item.winners_side == 1,
                            )}
                          />
                        </div>
                      </>
                    ) : (
                      <p className="mt-3">
                        {t('Matches')} {t('Not Found')}.
                      </p>
                    )}
                  </Tab>
                  <Tab eventKey="final" title={'Final'}>
                    {finalMatches && finalMatches?.length > 0 ? (
                      <FinalStage
                        finalMatches={finalMatches}
                        tournamentId={id}
                        tournamentType={tournament?.type?.type_name}
                        tournamentStatus={tournament?.status}
                        preMatchLength={
                          tournamentMatch?.filter(
                            item => item.winners_side != 2,
                          ).length
                        }
                        noTeamsFinal={4}
                      />
                    ) : (
                      <p className="mt-3">
                        {'Final'} {t('Matches')} {t('Not Found')}.
                      </p>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChampionDoubleElimination;
