import React, { useEffect } from 'react';
import './index.scss';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MatchesGroup from './matches-group';
import FinalStage from '../components/final-matches-bracket';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGroupFinalList,
  fetchGroupPhaseBracketList,
  fetchGroupPhaseStandings
} from '../../../../../actions/Tournaments';
import Loader from '../../../../../components/loader';
import BannerHeader from '../components/banner-header';

const GroupPhase = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    tournament,
    tournamentMatch,
    tournamentStanding,
    isLoading,
    finalMatches,
  } = useSelector(state => state.Tournaments);

  const groups = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  const standingsHelper = (standings, groupName) => {
    return (
      standings && standings?.filter(item => item.group_name === groupName)
    );
  };

  const bracketHelper = (matches, groupName) => {
    return (
      matches &&
      matches
        ?.filter(item => item.group_name === groupName)
        ?.map(item => {
          return item;
        })
    );
  };

  useEffect(() => {
    dispatch(fetchGroupPhaseBracketList(id));
    dispatch(fetchGroupPhaseStandings(id));
    dispatch(fetchGroupFinalList(id));
  }, []);
  // console.log('tournament===>', tournament);
  // console.log('tournamentMatch===>', tournamentMatch);
  // console.log('tournamentStanding===>', tournamentStanding);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <BannerHeader
            arenaId={tournament?.arena_id}
            tournamentId={tournament?.id}
            picUrl={tournament?.pic_url}
            tournamentName={tournament?.championship_name}
            duoNumber={tournament?.duo?.duo_number}
            categoryType={tournament?.category?.category_name}
            status={tournament?.status}
          />
          <div className="group-phase-tabs">
            <Tabs
              defaultActiveKey="groupmatches"
              onSelect={tab =>
                tab === 'final' && dispatch(fetchGroupFinalList(id))
              }
              transition={false}>
              <Tab eventKey="groupmatches" title={t('Group Matches')}>
                <>
                  {groups && groups.length > 0 ? (
                    groups.slice(0, Number(tournament?.no_group)).map(index => {
                      return (
                        <MatchesGroup
                          key={index}
                          groupName={index}
                          groupStandings={standingsHelper(tournamentStanding, index)}
                          matchBrackets={bracketHelper(tournamentMatch, index)}
                          tournamentId={id}
                          tournamentType={tournament?.type?.type_name}
                          tournamentStatus={tournament?.status}
                        />
                      );
                    })
                  ) : (
                    <p>{t('No Team Found.')}</p>
                  )}
                </>
              </Tab>
              <Tab eventKey="final" className='final-tab' title="Final">
                <FinalStage
                  finalMatches={finalMatches}
                  tournamentId={id}
                  tournamentType={tournament?.type?.type_name}
                  tournamentStatus={tournament?.status}
                  noTeamsFinal={tournament?.no_teams_final}
                  preMatchLength={
                    tournamentMatch?.filter(
                      item => item.group_name != null
                    ).length
                  }
                />
              </Tab>
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};

export default GroupPhase;
