import React from "react";
import './index.scss';
import { FloatingLabel, Form } from "react-bootstrap";
import { State, City } from 'country-state-city';

export const country = "BR";

export const StateSelectField = ({ fieldName, labelName, setSearchCityTerm, searchStateTerm, setSearchStateTerm }) => {

  return (
    <>
      <FloatingLabel controlId={fieldName} label={labelName} className={`${searchStateTerm ? 'cmn-style-floating active' : 'cmn-style-floating select-style'}`}>
        <Form.Select
          name={fieldName}
          onChange={(e) => {
            setSearchStateTerm(e.target.value);
            setSearchCityTerm("");
          }}
          value={searchStateTerm}>
          <option value=""></option>
          {State.getStatesOfCountry(country).map((item, index) => {
            return (
              <option key={index} value={item.isoCode}>{item.name}</option>
            )
          })}
        </Form.Select>
      </FloatingLabel>
    </>
  );
}

export const CitySelectField = ({ fieldName, labelName, stateFieldName, searchCityTerm, setSearchCityTerm }) => {

  return (
    <>
      <FloatingLabel controlId={fieldName} label={labelName} className={`${searchCityTerm ? 'cmn-style-floating active' : 'cmn-style-floating select-style'}`}>
        <Form.Select
          name={fieldName}
          onChange={(e) => setSearchCityTerm(e.target.value)}
          value={searchCityTerm}>
          <option value=""></option>
          {City.getCitiesOfState(country, stateFieldName).map((item, index) => {
            return (
              <option key={index} value={item.name}>{item.name}</option>
            )
          })}
        </Form.Select>
      </FloatingLabel>
    </>
  );
}

export const SearchInputField = ({ fieldName, labelName, searchTerm, setSearchTerm }) => {
  return (
    <FloatingLabel controlId={fieldName} label={labelName} className='w-100'>
      <Form.Control type="text" name={fieldName} placeholder="Search here" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} autoComplete='off' />
    </FloatingLabel>
  )
}