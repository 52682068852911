import './index.scss';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { userUpdate } from '../../../actions/Auth';
import { Accordion, Alert, Spinner } from 'react-bootstrap';
import { clearMessage } from '../../../actions/Actions';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import PhoneInputField from '../../../components/phone-input-field';
import ImageUploadField from '../../../components/image-upload-field';
// import { DateSelectField, TimeSelectField } from '../../../components/datetime-select-field';
import { AddressInputField } from '../../../components/address-input-field';
import { CitySelectField, StateSelectField } from '../../../components/country-state-cily-select-field';
import OpeningHoursModal from './OpeningHoursModal';
import moment from 'moment';
import Loader from '../../../components/loader';

const MyProfile = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user, message, isError, isLoading } = useSelector(state => state.Auth);
    const [openHourModal, setOpenHourModal] = useState(false);
    const [dayData, setDayData] = useState("");
    const [currentDay, setCurrentDay] = useState('');

    const formik = useFormik({
        initialValues: {
            profile_url: user?.profile_url || "",
            name: user?.name || "",
            display_name: user?.display_name || "",
            email: user?.email || "",
            opening_hours: user?.opening_hours || [],
            // opening_time: user?.opening_time || null,
            phone: user?.phone || "",
            address: user?.address || "",
            state: user?.state || "",
            city: user?.city || "",
            socialmedia: user?.socialmedia || "",
            summary: user?.summary || "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            name: yup.string().required(t("Please enter your name.")),
            display_name: yup.string().required(t("Please enter nick name.")),
            email: yup.string().email(t("Invalid email address.")).required(t("Please enter email address.")),
            // opening_date: yup.string().required(t("Please enter opening date.")),
            // opening_time: yup.string().required(t("Please enter opening time.")),
            // phone: yup.string().required(t("Please enter phone number.")),
            // address: yup.string().required(t("Please enter address.")),
            // profession: yup.string().required(t("Please enter profession.")),
            // socialmedia: yup.string().required(t("Please enter social link.")),
            // summary: yup.string().required(t("Please enter summary.")),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            if (values.profile_url === undefined || values.profile_url === null || values.profile_url === "") {
                formData.append("profile_url", values.profile_url);
            } else {
                formData.append("profile_url", values.profile_url);
            }
            formData.append("name", values.name);
            formData.append("display_name", values.display_name);
            formData.append("opening_hours", values.opening_hours);
            // formData.append("opening_time", values.opening_time);
            formData.append("state", values.state);
            formData.append("city", values.city);
            formData.append("phone", values.phone);
            formData.append("socialmedia", values.socialmedia);
            formData.append("address", values.address);
            formData.append("summary", values.summary);
            dispatch(userUpdate(formData));
        }
    });

    const dayFiltered = formik?.values?.opening_hours.find(item => item.days === currentDay);
    const handleOpenHours = (e, data) => {
        setOpenHourModal(true);
        setDayData(data);
        // console.log(dayData)
    }

    useEffect(() => {
        setCurrentDay(moment().format('dddd'));
        dispatch(clearMessage());
    }, [dispatch]);

    return (
        <>
            {isLoading ? <Loader /> :
                <>
                    <div className='mpCard'>
                        <div className='loginCard profileCard mt-5 mb-3'>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='profileUpload mb-4'>
                                    <ImageUploadField imgType={"user"} imgUrl={user?.profile_url} fieldName={"profile_url"} formik={formik} />
                                </div>
                                {message && isError && typeof message === 'string' && (
                                    <Alert className='mb-3' variant="danger" onClose={() => dispatch(clearMessage())} dismissible>
                                        {message}
                                    </Alert>
                                )}
                                {message && !isError && (
                                    <Alert className='mb-3' variant="success" onClose={() => dispatch(clearMessage())} dismissible>
                                        {message}
                                    </Alert>
                                )}
                                <div className='row'>
                                    <div className='col-md-12 mb-3'>
                                        <FloatingLabel controlId="Name" label={t("Name")} className='cmn-style-floating'>
                                            <Form.Control type="text" name="name" placeholder="Enter name" onChange={formik.handleChange} value={formik.values.name} />
                                            {formik.touched.name && formik.errors.name ? <p className="errorTxt">{formik.errors.name}</p> : null}
                                        </FloatingLabel>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <FloatingLabel controlId="NickName" label={t("Nick Name")} className='cmn-style-floating'>
                                            <Form.Control type="text" name="display_name" placeholder="Enter nick name" onChange={formik.handleChange} value={formik.values.display_name} readOnly />
                                            {formik.touched.display_name && formik.errors.display_name ? <p className="errorTxt">{formik.errors.display_name}</p> : null}
                                        </FloatingLabel>
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <FloatingLabel controlId="Email" label={t("Email Address")} className='cmn-style-floating'>
                                            <Form.Control type="email" name="email" placeholder="Enter email address" onChange={formik.handleChange} value={formik.values.email} readOnly />
                                            {formik.touched.email && formik.errors.email ? <p className="errorTxt">{formik.errors.email}</p> : null}
                                        </FloatingLabel>
                                    </div>

                                    {/* <div className='col-md-12 mb-3'>
                            <FloatingLabel controlId="Profession" label={t("Profession")} className='cmn-style-floating'>
                                <Form.Control type="text" name="profession" placeholder="" onChange={formik.handleChange} value={formik.values.profession} autoComplete='off' />
                                {formik.touched.profession && formik.errors.profession ? <p className="errorTxt">{formik.errors.profession}</p> : null}
                            </FloatingLabel>
                        </div> */}
                                    <div className='col-md-12 mb-3'>
                                        <div className='opening-date-time-bx'>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>
                                                        {t("Opening Hours")}
                                                        <span className='show-htime-head'>
                                                            <span className='htime-dot'>:</span>
                                                            &nbsp;
                                                            {dayFiltered?.status === "true" ?
                                                                <>
                                                                    <span className='day-open'>{t("Open")}</span>
                                                                    &nbsp;
                                                                    <span className='day-time'>
                                                                    {dayFiltered?.opening_time && moment.utc(`${dayFiltered?.opening_time}`, 'HH:mm').format('HH:mm')}
                                                                    &nbsp;-&nbsp;
                                                                    {dayFiltered?.closing_time && moment.utc(`${dayFiltered?.closing_time}`, 'HH:mm').format('HH:mm')}
                                                                    </span>
                                                                </>
                                                                :
                                                                <>
                                                                    <span className='day-close'>{t("Closed")}</span>
                                                                </>
                                                            }

                                                        </span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <table>
                                                            <tbody>
                                                                {formik?.values?.opening_hours?.length > 0 && formik?.values?.opening_hours.sort((a, b) => a.days - b.days).map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{t(item.days)}</td>
                                                                            {item.status === "true" ?
                                                                                <td>{moment.utc(`${item.opening_time}`, 'HH:mm').format('HH:mm')} - {moment.utc(`${item.closing_time}`, 'HH:mm').format('HH:mm')} <button type='button' onClick={(e) => handleOpenHours(e, item)} className='btn btn-circle'><i className="fa-regular fa-pen"></i></button></td>
                                                                                :
                                                                                <td><span className='day-close'>{t("Closed")}</span> <button type='button' onClick={(e) => handleOpenHours(e, item)} className='btn btn-circle'><i className="fa-regular fa-pen"></i></button></td>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                    {/* <div className='col-md-12 mb-3'>
                            <DateSelectField formik={formik} fieldName={"opening_date"} labelName={t('Opening Date')} />
                        </div>

                        <div className='col-md-12 mb-3'>
                            <TimeSelectField formik={formik} fieldName={"opening_time"} labelName={t('Opening Time')} />
                        </div> */}

                                    <div className='col-md-12 mb-3'>
                                        <PhoneInputField formik={formik} fieldName={"phone"} labelName={t('Phone Number')} />
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <StateSelectField formik={formik} cityFieldName={"city"} fieldName={"state"} labelName={t('State')} />
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <CitySelectField formik={formik} stateFieldName={"state"} fieldName={"city"} labelName={t('City')} />
                                    </div>


                                    <div className='col-md-12 mb-3'>
                                        <AddressInputField formik={formik} fieldName={"address"} labelName={t('Address')} />
                                        {/* <FloatingLabel controlId="Address" label="Address" className='cmn-style-floating'>
                                <Form.Control type="text" name="address" placeholder="" onChange={formik.handleChange} value={formik.values.address} />
                                {formik.touched.address && formik.errors.address ? <p className="errorTxt">{formik.errors.address}</p> : null}
                            </FloatingLabel> */}
                                    </div>

                                    <div className='col-md-12 mb-3'>
                                        <FloatingLabel controlId="Socialmedia" label={t("Social Media")} className='cmn-style-floating'>
                                            <Form.Control type="text" name="socialmedia" placeholder="Enter social media link" onChange={formik.handleChange} value={formik.values.socialmedia} />
                                            {formik.touched.socialmedia && formik.errors.socialmedia ? <p className="errorTxt">{formik.errors.socialmedia}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <FloatingLabel controlId="Summary" label={t("Summary")} className='cmn-style-floating'>
                                            <Form.Control as="textarea" name="summary" placeholder="Enter summary" className='custTxrarea' rows={5} onChange={formik.handleChange} value={formik.values.summary} />
                                            {formik.touched.summary && formik.errors.summary ? <p className="errorTxt">{formik.errors.summary}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                </div>
                                <button className='loginButton' type='submit' disabled={isLoading}>
                                    {!isLoading && (
                                        <span>{t("Save")}</span>
                                    )}
                                    {isLoading && (
                                        <Spinner animation="border" size="sm" />
                                    )}
                                </button>
                            </form>
                        </div>
                        <Link to="/dashboard" className='backtxt'><i className="fa-light fa-arrow-left-long"></i> {t("Back")}</Link>
                    </div>
                    <OpeningHoursModal
                        day={dayData?.days}
                        openingTime={dayData?.opening_time}
                        closingTime={dayData?.closing_time}
                        dayStatus={dayData?.status}
                        openHourModal={openHourModal}
                        setOpenHourModal={setOpenHourModal}
                    />
                </>
            }
        </>
    )
}

export default MyProfile;