import './index.scss';
import React, { useState, useEffect } from 'react';
import LanguageDropdown from '../../../../components/language-dropdown';

const Header = () => {

    const [scrolltopdata, setscrolltopdata] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 15) {
                setscrolltopdata('');
            } else {
                setscrolltopdata('scrolled');
            }
        });
    }, []);

    return (
        <div className={scrolltopdata ? "langBg langBgcolor" : "langBg"}>
            <div className='container'>
                <LanguageDropdown/>
            </div>
        </div>
    )
}

export default Header;