import React from 'react'
import smlogo from '../../../assets/images/smlogo.svg';
import transfrm from '../../../assets/images/tabela-jogos.svg';
import matchHistoryImg from '../../../assets/images/sem-título.svg';
import { useTranslation } from 'react-i18next';

const Transform = () => {
  const { t } = useTranslation();
  return (
    <section className='transformBase'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5 transLft'>
            <img className='logo' src={smlogo} alt='' />
            <h3>{t("Transform your Results")}</h3>
            <p>{t("See your performance in each game, compare your performance with friends and opponents, and discover new ways to improve. It's not just about competing, but about becoming a better athlete every day.")}</p>
            <p>{t("Join us and transform your results. Show the footvolley world what you are capable of!")}</p>
            <div className='t-match-history'>
              <img src={matchHistoryImg} alt='' />
            </div>
          </div>
          <div className='col-lg-7 mt-lg-0 mt-4'>
            <div className='transformForm'>
              <img src={transfrm} alt='' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Transform;