import {
  CLEAR_MESSAGE,
  GROUP_PHASE_MATCHES_BRACKET,
  GROUP_PHASE_STANDING,
  GROUP_PHASE_UPDATE_MATCH,
  START_LOADING,
  STOP_LOADING,
  TEAM_CREATE,
  TOURNAMENT_ADD,
  TOURNAMENT_DELETE,
  TOURNAMENT_EDIT,
  TOURNAMENT_LIST,
  DOUBLE_ELIMINATION_MATCHES_BRACKET,
  DOUBLE_ELIMINATION_UPDATE_MATCH,
  FINAL_MATCH_LIST,
  START_TOURNAMENT,
  AVAILABLE_ATHLETE_LIST,
  DATA_FAILED,
} from '../actions/Types';

const initialState = {
  tournaments: [],
  availableathletelist: [],
  tournamentMatch: [],
  finalMatches: [],
  tournamentStanding: [],
  basePoints: 0,
  tournament: null,
  winningStages: 0,
  losingStages: 0,
  message: null,
  isError: false,
  isLoading: false,
};

export default function Tournaments(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOURNAMENT_LIST:
      return {
        ...state,
        tournaments: payload.tournaments.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        ),
      };

    case TOURNAMENT_ADD:
      const newTournaments = [...state.tournaments, payload.tournaments];
      newTournaments.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      return {
        ...state,
        tournaments: newTournaments,
        message: payload.message,
        isError: false,
      };

    case TOURNAMENT_EDIT:
      const updatedEditTournamnet = state.tournaments.map(tournament => {
        if (tournament.id === payload.id) {
          return {
            ...tournament,
            ...payload.updateData,
          };
        }
        return tournament;
      });
      return {
        ...state,
        tournaments: updatedEditTournamnet,
        message: payload.message,
        isError: false,
      };
    case TOURNAMENT_DELETE:
      return {
        ...state,
        tournaments: state.tournaments.filter(
          tournament => tournament.id !== payload.id,
        ),
        message: payload.message,
        isError: false,
      };

    case AVAILABLE_ATHLETE_LIST:
      return {
        ...state,
        availableathletelist: payload.availableathletelist.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        ),
      };

    case TEAM_CREATE:
      const updatedTournaments = state.tournaments.map(tournament => {
        if (tournament.id === payload.tournamentId) {
          return {
            ...tournament,
            fetch_teams: payload.teamData,
          };
        }
        return tournament;
      });
      return {
        ...state,
        tournaments: updatedTournaments,
        // message: payload.message,
        // isError: false
      };
    //DOUBLE ELIMINATION START
    case DOUBLE_ELIMINATION_MATCHES_BRACKET:
      return {
        ...state,
        tournamentMatch: payload.tournamentMatch,
        tournament: payload.tournament,
        winningStages: payload.winningStages,
        losingStages: payload.losingStages,
      };

    case DOUBLE_ELIMINATION_UPDATE_MATCH:
      const updateMatch = state.tournamentMatch.map(item => {
        if (item.id === payload.doublematchUpdateData.id) {
          return {
            ...item,
            ...payload.doublematchUpdateData,
          };
        }
        if (item.id === payload.winnerMatchUpdate.id) {
          return {
            ...item,
            ...payload.winnerMatchUpdate,
          };
        }
        if (item.id === payload.looserMatchUpdate.id) {
          return {
            ...item,
            ...payload.looserMatchUpdate,
          };
        }
        return item;
      });

      const updateFinalMatch = state?.finalMatches?.map(item => {
        if (item.id === payload.doublematchUpdateData.id) {
          return {
            ...item,
            ...payload.doublematchUpdateData,
          };
        }
        if (item.id === payload.winnerMatchUpdate.id) {
          return {
            ...item,
            ...payload.winnerMatchUpdate,
          };
        }
        if (item.id === payload.looserMatchUpdate.id) {
          return {
            ...item,
            ...payload.looserMatchUpdate,
          };
        }
        return item;
      });

      return {
        ...state,
        tournamentMatch: [...updateMatch],
        finalMatches: updateFinalMatch,
      };
    //DOUBLE ELIMINATION END
    // GROUP PHASE START
    case GROUP_PHASE_MATCHES_BRACKET:
      return {
        ...state,
        tournamentMatch: payload.tournamentMatch,
        tournament: payload.tournament,
      };

    case GROUP_PHASE_STANDING:
      return {
        ...state,
        tournamentStanding: payload.tournamentStanding,
        tournament: payload.tournament,
      };
    case START_TOURNAMENT:
      return {
        ...state,
        tournament: payload.tournament,
      };
    case GROUP_PHASE_UPDATE_MATCH:
      const updatedMatch = state.tournamentMatch.map(item => {
        if (item.id === payload.matchUpdateData.id) {
          return {
            ...item,
            ...payload.matchUpdateData,
          };
        }
        return item;
      });

      const updatedFinalMatch = state?.finalMatches?.map(item => {
        if (item.id === payload.matchUpdateData.id) {
          return {
            ...item,
            ...payload.matchUpdateData,
          };
        }
        return item;
      });

      const updatedStanding = state.tournamentStanding.map(item => {
        const standingUpdateObj = payload.standingUpdateData.find(
          updateItem => updateItem.id === item.id,
        );
        if (standingUpdateObj) {
          return {
            ...item,
            ...standingUpdateObj,
          };
        }
        return item;
      });
      return {
        ...state,
        tournamentMatch: updatedMatch,
        tournamentStanding: updatedStanding,
        finalMatches: updatedFinalMatch,
      };
    // GROUP PHASE END

    //Final Matches
    case FINAL_MATCH_LIST:
      return {
        ...state,
        finalMatches: payload.tournamentMatch,
        basePoints: payload.basePoints,
      };

    case DATA_FAILED:
      return {
        ...state,
        message: payload.message,
        isError: true,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        isError: false,
      };
    default:
      return state;
  }
}
