import React, { useEffect } from "react";
import './index.scss';
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ChampionshipItem from "../components/ChampionshipItem";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loader";
import { getPreviousTournament, getUpcomingTournament } from "../../../actions/Cms";
import BannerBar from "../components/banner-bar";
import bannerImg from '../../../assets/images/championships-banner.png';

const Championships = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { upcoming_tournament, previous_tournament, isLoading } = useSelector(state => state.Cms);

    useEffect(() => {
        dispatch(getUpcomingTournament());
        dispatch(getPreviousTournament());
    }, [dispatch]);

    return (
        <>
            <BannerBar
                title={"Events and Championships"}
                subTitle={"A Year of Triumph and Competition"}
                description={"The right point in your game."}
                bannerUrl={bannerImg}
                linkUrl={"/championships"} />
            <div className='mainBase pt-0'>
                <div className='cmn-content-body championships-wrapper'>
                    {isLoading ? <Loader /> :
                        <div className='container'>
                            <Tabs
                                defaultActiveKey="upcomming"
                                transition={false}
                                className="cmn-tabs-style"
                            >
                                <Tab eventKey="upcomming" title={t("Up Coming")}>
                                    <div className="row">
                                        {upcoming_tournament && upcoming_tournament.length > 0 ?
                                            upcoming_tournament.map((item) => {
                                                return (
                                                    <div key={item.id} className="col-md-6 col-lg-4 py-3">
                                                        <ChampionshipItem item={item} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="col-md-12 col-lg-12 text-center pt-3">
                                                <p className="mb-0">{t("Up Coming")} {t("Championships / Tournament")} {t("Not Found.")}</p>
                                            </div>
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey="previous" title={t("Previous")}>
                                    <div className="row">
                                        {previous_tournament && previous_tournament.length > 0 ?
                                            previous_tournament.map((item) => {
                                                return (
                                                    <div key={item.id} className="col-md-6 col-lg-4 py-3">
                                                        <ChampionshipItem item={item} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="col-md-12 col-lg-12 text-center pt-3">
                                                <p className="mb-0">{t("Previous")} {t("Championships / Tournament")} {t("Not Found.")}</p>
                                            </div>
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>}
                </div>
            </div>
        </>
    )
}
export default Championships;