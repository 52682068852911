import React, { useState } from 'react';
import './index.scss';
import noUser from '../../assets/images/no-user.jpg';
import noImage from '../../assets/images/no-image.jpg';

const ImageUploadField = ({ fieldName, formik, imgUrl, imgType }) => {
  const [image, setImage] = useState("");

  const onSelectImg = (event) => {
    if (event["target"].files.length > 0) {
      setImage(URL.createObjectURL(event.target.files[0]));
      formik.setFieldValue(fieldName, event.target.files[0]);
    }
  }

  return (
    <>
      <div className='profileUpblock'>
        <label htmlFor="upload-button" className='uploadBtn'>
          {imgUrl && !image ? (
            <img src={process.env.REACT_APP_IMGBASE_URL + imgUrl} alt="" width="100" height="100" />
          ) : image ? (
            <img src={image} alt="user" width="100" height="100" />
          ) : (
            imgType === "user" ? <img src={noUser} alt="No user" /> : <img src={noImage} alt="No image" />
          )}
        </label>
        <span className='profEdit'>
          <i className="fa-regular fa-camera"></i>
          <input type="file" accept="image/png, image/jpeg" id="upload-button" onChange={onSelectImg} />
        </span>
      </div>
    </>
  );
}
export default ImageUploadField;