import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_MESSAGE,
    PROFILE_UPDATE_SUCCESS,
    FORGOT_PASSWORD_SUCCESS,
    EMAIL_VERIFICATION_SUCCESS,
    START_LOADING,
    STOP_LOADING,
    USER_PROFILE_SUCCESS,
    OPENING_HOURS_UPDATE,
    DATA_FAILED
} from "../actions/Types";

const user = JSON.parse(localStorage.getItem("SR_USER"));
const deviceToken = JSON.parse(localStorage.getItem("SR_DEVICE_TOKEN"));

const initialState = user
    ? { isLoggedIn: true, user, deviceToken, message: null, isError: false, isLoading: false }
    : { isLoggedIn: false, user: null, deviceToken: null, message: null, isError: false, isLoading: false };

export default function Auth(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                message: payload.message,
                isError: false
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                message: payload.message,
                isError: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                message: payload.message,
                isError: false
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message: payload.message,
                isError: true
            };
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: null,
                isError: false
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                message: null,
                isError: false
            };

        case USER_PROFILE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload.user,
                },
                isLoggedIn: true,
                isError: false,
            };
        case PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload.user
                },
                isLoggedIn: true,
                message: payload.message,
                isError: false
            };
        case OPENING_HOURS_UPDATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    opening_hours: state?.user?.opening_hours?.map((item) => item?.days === payload.dayData?.days ? { ...item, ...payload.dayData } : item),
                }
            };
        case DATA_FAILED:
            return {
                ...state,
                message: payload.message,
                isError: true
            };
        case EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                message: payload.message,
                isError: false
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                message: payload.message,
                isError: false
            }
        default:
            return state;
    }
}