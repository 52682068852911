import React from 'react';
import './index.scss';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import AthleteGameHeader from '../match-header';
import moment from 'moment';

const MatchViewModal = ({
  openViewModal,
  setOpenViewModal,
  matchSeed,
  matchNumber,
}) => {
  const {t} = useTranslation();

  // console.log("matchViewSeed===>", matchSeed)

  return (
    <Modal
      show={openViewModal}
      onHide={() => setOpenViewModal(false)}
      keyboard={false}
      size="lg"
      className="game-popup"
      centered>
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title as="h5"> {matchNumber ? matchNumber : ''}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0 radius-bl-br-10">
        <AthleteGameHeader matchSeed={matchSeed} />
        <div className="px-4 py-4 game-popup-form-style">
          <div className="row">
            <div className="col-md-3 d-flex align-items-center">
              <label>{t('Winner')}</label>
            </div>
            <div className="col-md-9 text-label-2">
              {matchSeed?.winning_team_id
                ? (matchSeed?.winning_team_id === matchSeed?.team1?.id &&
                  t('Team') + " " + matchSeed?.team1?.team_division_id) ||
                  (matchSeed?.winning_team_id === matchSeed?.team2?.id &&
                    t('Team') + " " + matchSeed?.team2?.team_division_id)
                : t('Unknown')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 d-flex align-items-center">
              <label>{t('Score')}</label>
            </div>
            <div className="col-md-9 text-label-2">
              {matchSeed?.played === true || matchSeed?.played === 'true' ? (
                <span className="player-score">
                  {matchSeed?.team_id1 === matchSeed?.winning_team_id
                    ? matchSeed?.winning_points
                    : matchSeed?.losing_points}
                </span>
              ) : (
                <span className="player-score">--</span>
              )}
              &nbsp;&nbsp;:&nbsp;&nbsp;
              {matchSeed?.played === true || matchSeed?.played === 'true' ? (
                <span className="player-score">
                  {matchSeed?.team_id2 === matchSeed?.winning_team_id
                    ? matchSeed?.winning_points
                    : matchSeed?.losing_points}
                </span>
              ) : (
                <span className="player-score">--</span>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 d-flex align-items-center">
              <label>{t('Date')}</label>
            </div>
            <div className="col-md-9 text-label-2">
              {matchSeed?.date ? matchSeed?.date : t('Unknown')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 d-flex align-items-center">
              <label>{t('Time')}</label>
            </div>
            <div className="col-md-9 text-label-2">
              {matchSeed?.time ? moment.utc(`${matchSeed?.time}`, 'HH:mm').format('HH:mm') : t('Unknown')}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MatchViewModal;
