import React, { useEffect } from "react";
import './index.scss';
import TitleBar from "../components/titlte-bar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { useTranslation } from "react-i18next";
import { getPrivacyPolicy } from "../../../actions/Cms";

const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { privacy_policy, isLoading } = useSelector(state => state.Cms);

    useEffect(() => {
        dispatch(getPrivacyPolicy());
    }, [dispatch]);

    return (
        <div className='mainBase pt-0'>
            <TitleBar title="Privacy Policy" linkUrl="/" />
            <div className='cmn-content-body cmn-cms-wrapper'>
                <div className='container'>
                    {isLoading ? <Loader/> :
                    privacy_policy ? <div dangerouslySetInnerHTML={{__html: i18n.language === "en" || i18n.language === "en-US" ? privacy_policy?.en_content : privacy_policy?.pt_content}}></div> : <p className='text-center mb-0'>{t("Privacy Policy") +" "+ t("Not Found")}</p>}
                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy;