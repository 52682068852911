import React from "react";
import './index.scss';
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const InfoModal = ({ openInfoModal, setOpenInfoModal, content }) => {
    const { t } = useTranslation();
    return (
        <Modal
            show={openInfoModal}
            onHide={() => setOpenInfoModal(false)}
            keyboard={false}
            size="md"
            className="logout-modal"
            centered
        >

            <Modal.Body className="p-0">
                <div className="p-4 text-center">
                    <p className="mb-0">{content}</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default InfoModal;