import React from 'react'
import bnrimg from '../../../assets/images/bnrimg.svg';
import smlogo from '../../../assets/images/smlogo.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BannerHome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
        <section className='homeBanner'>
          <div className='container'>
            <div className='row homeBanner-body'>
              <div className='col-md-6 bnrLft'>
                <img src={bnrimg} alt='' />
              </div>
              <div className='col-md-6'>
                <div className='bnrRgt'>
                    <h3><img src={smlogo} alt='' /> {t("In Every Challenge")},</h3>
                    <h1 className='mb-3'>{t("An Overcoming")}:<br/> Sport Ranking<span className='copyrighticon'>&copy;</span></h1>
                    <h5 className='mt-3 mb-0'>{t("The Right Point In Your Game.")}</h5>
                    <button className='commonButton commonBtnbordr mt-4' onClick={()=>navigate("/championships")}>{t("View Championships")}&nbsp;&nbsp;<i className="fa-solid fa-arrow-right"></i></button>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default BannerHome;