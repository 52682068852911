import React from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TimeSelectField } from '../../../components/datetime-select-field';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { openingHoursUpdate } from "../../../actions/Auth";

const OpeningHoursModal = ({ openHourModal, setOpenHourModal, day, openingTime, closingTime, dayStatus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.Auth);

    const formik = useFormik({
        initialValues: {
            days: day || null,
            opening_time: openingTime || null,
            closing_time: closingTime || null,
            status: dayStatus || "false",
        },
        enableReinitialize: true,
        validationSchema: yup.lazy(values => {
            if (values.status === "true") {
                return yup.object({
                    opening_time: yup.string().required(t("Please enter open time.")),
                    closing_time: yup.string().required(t("Please enter close time."))
                });
            }
            return yup.object({});
        }),
        onSubmit: async (values) => {
            if (values.status === "true") {
                dispatch(openingHoursUpdate(values, formik, setOpenHourModal));
            } else {
                const defaultDays = {
                    days: day,
                    opening_time: null,
                    closing_time: null,
                    status: "false",
                }
                dispatch(openingHoursUpdate(defaultDays, formik, setOpenHourModal));
            }
        }
    });
    const handleCancelModal = () => {
        setOpenHourModal(false);
        formik.resetForm();
    }

    return (
        <Modal
            show={openHourModal}
            onHide={() => handleCancelModal()}
            keyboard={false}
            size="md"
            className="openhours-modal"
            centered
        >
            <Modal.Header>
                <Modal.Title as="h5">{t("Select Time")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div className="custom-switchbx">
                        <Form.Check
                            type="radio"
                            id="switch-open"
                            label={t("Open")}
                            name="status"
                            value="true"
                            onChange={() => formik.setFieldValue('status', "true")}
                            checked={formik.values.status === "true"}
                        />
                        <Form.Check
                            type="radio"
                            label={t("Closed")}
                            id="switch-close"
                            name="status"
                            value="false"
                            onChange={() => {
                                formik.setFieldValue('status', "false");
                                // formik.resetForm();
                            }}
                            checked={formik.values.status === "false"}
                        />
                    </div>
                    {formik.values.status === "true" && (
                        <div className="row mt-3">
                            <div className="col-sm-6">
                                <TimeSelectField formik={formik} fieldName={"opening_time"} labelName={t('Open Time')} />
                            </div>
                            <div className="col-sm-6 mt-2 mt-sm-0">
                                <TimeSelectField formik={formik} fieldName={"closing_time"} labelName={t('Close Time')} />
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-cancel"
                    type="button"
                    onClick={() => handleCancelModal()}>
                    <span>{t('Cancel')}</span>
                </button>
                <button
                    className="btn btn-submit"
                    type='button'
                    disabled={isLoading}
                    onClick={() => formik.submitForm()}>
                    {!isLoading && (
                        <span>{t("Submit")}</span>
                    )}
                    {isLoading && (
                        <Spinner animation="border" size="sm" />
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default OpeningHoursModal;