import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../pages/admin/common/sidebar';
import Header from '../pages/admin/common/header';
import store from '../stores/store';
import { getArenaList } from '../actions/Arenas';
import { getAthleteList } from '../actions/Athletes';
import { getTournamentList } from '../actions/Tournaments';
import { getCategoriesList, getDuosList, getNotificationList, getTeamList, getTypesList } from '../actions/CommonData';
import { useSelector } from 'react-redux';
import { changeLanguageApi, fetchDeviceToken } from '../services/auth.service';
import { useTranslation } from 'react-i18next';
// import Footer from '../pages/admin/common/footer/Footer';


const AdminLayout = () => {
  const { i18n } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 991);
  const { isLoggedIn, deviceToken } = useSelector(state => state.Auth);
  const lng = i18n.language === 'en-US' || i18n.language === 'en' ? 'en' : 'pt';

  useEffect(() => {
    const handleBodyResize = () => {
      setIsWideScreen(window.innerWidth > 991);
      setIsSidebarOpen(true);
    };

    window.addEventListener('resize', handleBodyResize);

    return () => {
      window.removeEventListener('resize', handleBodyResize);
    };
  }, []);

  useEffect(() => {
    store.dispatch(getArenaList());
    store.dispatch(getAthleteList());
    store.dispatch(getTournamentList());
    store.dispatch(getDuosList());
    store.dispatch(getCategoriesList());
    store.dispatch(getTypesList());
    store.dispatch(getTeamList());
    store.dispatch(getNotificationList());
    if (isLoggedIn && deviceToken) fetchDeviceToken(deviceToken);
    if(isLoggedIn) changeLanguageApi(lng);
  }, []);

  // console.log("deviceToken====>", deviceToken)
  return (
    <>
      <div className="admin-container">
        <Sidebar isWideScreen={isWideScreen} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        <div className="admin-body">
          <Header isWideScreen={isWideScreen} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
          <main className={`dbMain pt100 ${isWideScreen ? "translate-x-0" : ""}`}>
            <Outlet />
          </main>
          {/* <Footer/> */}
        </div>
      </div>
    </>
  )
}
export default AdminLayout;
