import React from 'react';
import noImg from '../../../assets/images/no-image.jpg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const ChampionshipItem = ({ item }) => {
    const navigate = useNavigate();

    // console.log(item)
    return (
        <div className='championCard' onClick={() => navigate(`/championships/view/${item.arena_id}`)}>
            <div className='championCardImg height-320'>
                {item?.pic_url ? <img src={process.env.REACT_APP_IMGBASE_URL + item?.pic_url} alt={item?.championship_name} /> : <img src={noImg} alt='No image' />}
            </div>
            <div className='cardTxt'>
                <div className='cardHdn'>
                    {/* <span>#{item.id}</span> */}
                    <h6>{item.championship_name}</h6>
                    <p>{item.user.display_name}</p>
                </div>
                <div className='cardDate'>
                    <h5>{moment(item.from_date).format('DD')}</h5>
                    <h6>{moment(item.from_date).format('MMM')}</h6>
                </div>
            </div>
        </div>
    )
}

export default ChampionshipItem;