export const SelectStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: '#484848',
        borderRadius: '25px',
        borderWidth: 0,
        boxShadow: null,
    }),
    option: (styles, { isFocused, isSelected }) => {
        return {
            ...styles,
            fontSize: "14px",
            fontWeight: 500,
            backgroundColor: isSelected ? '#f4c727' : null,
            '&:hover': { backgroundColor: isFocused ? '#f4c727' : '#484848' },
            color: '#333333',
        };
    },
    input: (styles) => ({
        ...styles,
        color: '#fff',
        fontSize: '14px',
        fontWeight: 500,
        borderRadius: '25px',
        borderWidth: 0,
        minHeight: '44px',
        boxShadow: null
    }),
    singleValue: (styles) => ({
        ...styles,
        color: '#fff',
        fontSize: '14px',
        fontWeight: 500,
        '.option-id': {
           color: "#000",
           backgroundColor: '#f4c727',
        },
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#a9a9a9',
        fontWeight: 500,
        fontSize: '14px',
    }),
    valueContainer: (styles) => ({
        ...styles,
        paddingLeft: '14px',
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        paddingRight: '5px',
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: '#535353',
    }),
    dropdownIndicator: (styles, { isFocused }) => ({
        ...styles,
        color: isFocused ? '#fff' : '#ccc',
        '&:hover': {
            color: '#fff',
        },
    }),
    clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: '#777',
        ':hover': {
            color: '#fff'
        }
    })
};

export const Select1Styles = {
    option: (styles, { isFocused, isSelected }) => {
        return {
            ...styles,
            display: isSelected ? 'none' : null,
            fontSize: "14px",
            fontWeight: 500,
            backgroundColor: isSelected ? '#f4c727' : null,
            '&:hover': { backgroundColor: isFocused ? '#f4c727' : '#484848' },
            color: '#333333',
        };
    },
};

export const Select2Styles = {
    input: (styles) => ({
        ...styles,
        color: '#fff',
        fontSize: '14px',
        fontWeight: 500,
        borderRadius: '25px',
        borderWidth: 0,
        minHeight: '38px',
        boxShadow: 'none'
    }),
};