import './index.scss';
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/Auth";
import { useTranslation } from 'react-i18next';

const Logout = ({ logoutModal, setLogoutModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch((logout()));
        setLogoutModal(false);
    }

    return (
        <>
            <Modal
                show={logoutModal}
                onHide={() => setLogoutModal(false)}
                keyboard={false}
                size="sm"
                className="logout-modal"
                centered
            >
                <Modal.Body>
                    <p className="text-center">{t("Are you sure you want to logout")}?</p>
                    <div className="mt-3 justify-content-center d-flex">
                        <button type="button" className="btn btn-outline-primary commonButton" onClick={() => setLogoutModal(false)}>
                            {t("No")}
                        </button>
                        <button onClick={handleLogout} type="submit" className="btn btn-primary commonButton ms-3">{t("Yes")}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Logout;