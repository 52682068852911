import './index.scss';
import React, { useEffect } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { emailVerification } from '../../../actions/Auth';
import { Alert, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { clearMessage } from '../../../actions/Actions';
import { useTranslation } from 'react-i18next';


const EmailVerification = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { message, isError, isLoading } = useSelector(state => state.Auth);
    const getLanguage = () => i18n.language === 'en-US' || i18n.language === 'en' ? 'en' : 'pt';

    const formik = useFormik({
        initialValues: {
            language: getLanguage(),
            email: ''
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            email: yup.string().email(t("Invalid email address.")).required(t("Please enter email address.")),
        }),
        onSubmit: async (values) => {
           dispatch(emailVerification(values, formik));
        }
    })

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    return (
        <section className='loginbase'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 loginimg'>
                        {/* <img src={loginimg} alt='' /> */}
                    </div>
                    <div className='col-lg-6'>
                        <div className='loginCard'>
                            <div className='loginLogo'>
                                {/* <img src={logo} alt='' /> */}
                                <h4>{t("Email Verification")}</h4>
                            </div>
                            {message && isError && typeof message === 'string' && (
                                <Alert className='mb-3' variant="danger" onClose={() => dispatch(clearMessage())} dismissible>
                                    {message}
                                </Alert>
                            )}
                            {message && !isError && (
                                <Alert className='mb-3' variant="success" onClose={() => dispatch(clearMessage())} dismissible>
                                    {message}
                                </Alert>
                            )}
                            <form onSubmit={formik.handleSubmit}>
                                <div className='fieldPosition mb-4'>
                                    <FloatingLabel controlId="floatingEmail" label={t("Email Address")} className="cmn-style-floating floating-btn-group">
                                        <span className='fieldicon'><i className="fa-regular fa-envelope"></i></span>
                                        <Form.Control type="email" name="email" placeholder="Enter email address" onChange={formik.handleChange} value={formik.values.email} />
                                    </FloatingLabel>
                                    {formik.touched.email && formik.errors.email ? <p className="errorTxt">{formik.errors.email}</p> : null}
                                </div>
                                <button className='loginButton' type='submit' disabled={isLoading}>
                                    {isLoading ? <Spinner animation="border" size="sm" /> : <span>{t("Submit")}</span>}
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EmailVerification;