import React from "react";
import './index.scss';
import { FloatingLabel } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";

export const AddressInputField = ({ formik, fieldName, labelName }) => {

  return (
    <>
      <FloatingLabel controlId={fieldName} label={labelName} className='cmn-style-floating'>
        <Autocomplete
          className="form-control"
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          onPlaceSelected={(place) => formik.setFieldValue(fieldName, place.formatted_address)}
          onChange={(e) => formik.setFieldValue(fieldName, e.target.value)}
          placeholder={"Enter address"}
          value={formik.values[fieldName]}
        />
      </FloatingLabel>
      {formik.touched[fieldName] && formik.errors[fieldName] ? <p className="errorTxt">{formik.errors[fieldName]}</p> : null}
    </>
  );
}
