import Championship from '../components/Championship';
import './index.scss';
import React from "react";
import Transform from '../components/Transform';
import Upgrade from '../components/Upgrade';
import DiscoverSlider from '../components/DiscoverSlider';
import BannerHome from '../components/BannerHome';

const Home = () => {
    return (
        <div className='mainBase pt-0'>
            <BannerHome />
            <div className='homeMiddle'>
                <Championship/>
                <Transform/>
                <Upgrade/>
            </div>
            <div className='upgrade'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <DiscoverSlider/>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    )
}

export default Home;