import { Outlet } from 'react-router-dom';
import Header from '../pages/auth/common/header';
import Footer from '../pages/auth/common/footer';

const AuthLayout = ()=>{
    return(
        <div className='authMain'>
            <header className='header-wrap'><Header/></header>
            <main className='body-wrap'><Outlet/></main>
            <footer className='footer-wrap'><Footer/></footer>
        </div>
    )
}
export default AuthLayout;
