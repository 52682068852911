import axios from "axios";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchNewsList = () => {
  return axios.get(API_URL + "news")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`News list failed: ${error.message}`);
    });
};

export const fetchNewsView = (itemId) => {
  return axios.get(API_URL + `news-details/${itemId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`News view failed: ${error.message}`);
    });
};

export const fetchRankingList = () => {
  return axios.get(API_URL + "arena/ranking-list")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Ranking list failed: ${error.message}`);
    });
};

export const fetchTermsConditions = () => {
  return axios.get(API_URL + "terms")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Terms & conditions failed: ${error.message}`);
    });
};

export const fetchPrivacyPolicy = () => {
  return axios.get(API_URL + "privacy")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Privacy policy failed: ${error.message}`);
    });
};

export const fetchAboutUs = () => {
  return axios.get(API_URL + "about-us")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`About us failed: ${error.message}`);
    });
};

export const fetchHelpList = () => {
  return axios.get(API_URL + "faqs")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Help failed: ${error.message}`);
    });
};

export const fetchPreviousTournament = () => {
  return axios.get(API_URL + "arena/past-tournament")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Previous tournament failed: ${error.message}`);
    });
};

export const fetchUpcomingTournament = () => {
  return axios.get(API_URL + "arena/upcoming-tournament")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Upcoming tournament failed: ${error.message}`);
    });
};

export const fetchTournamentDetails = (itemId) => {
  return axios.get(API_URL + `tournament-details/${itemId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Tournament details failed: ${error.message}`);
    });
};

export const fetchArenaTournamentList = (itemId) => {
  return axios.get(API_URL + `arena-tournament-list/${itemId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Arena tournament list failed: ${error.message}`);
    });
};

export const fetchArenasList = () => {
  return axios.get(API_URL + "athlete/arena/list")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Arena list failed: ${error.message}`);
    });
};

export const fetchArenasView = (itemId) => {
  return axios.get(API_URL + `athlete/arena/view/${itemId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Arena view failed: ${error.message}`);
    });
};

export const fetchContactInfo = () => {
  return axios.get(API_URL + "admin-address")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Contact info failed: ${error.message}`);
    });
};

export const fetchAddContactUs = (contactData) => {
  return axios.post(API_URL + "add-contactus", contactData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(`Add contact us failed: ${error.message}`);
    });
};

export const getGroupPhaseBracketList = (tournamentId) => {
  return axios
    .get(API_URL + `group-phase-bracket-lists/${tournamentId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Group phase brackets failed: ${error.message}`);
    });
}

export const getGroupPhaseStandings = (tournamentId) => {
  return axios
    .get(API_URL + `group-phase-standing/${tournamentId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Group phase standings failed: ${error.message}`);
    });
};

export const getGroupPhaseFinalist = (tournamentId) => {
  return axios
    .get(API_URL + `group-phase-final/${tournamentId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Group phase finalist failed: ${error.message}`);
    });
};

export const getDoubleEliminationBracketList = (tournamentId) => {
  return axios
    .get(API_URL + `double-elimination-bracket-lists/${tournamentId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Double elimination brackets failed: ${error.message}`);
    });
}

export const getDoubleEliminationFinalist = (tournamentId) => {
  return axios
    .get(API_URL + `double-elimination-final/${tournamentId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Double elimination finalist failed: ${error.message}`);
    });
};