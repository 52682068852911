import './index.scss';
import React, { useEffect, useState } from "react";
import success from '../../../assets/images/success.svg';
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetForgotPasssword } from '../../../actions/Auth';
import { Alert, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { clearMessage } from '../../../actions/Actions';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
    const { t, i18n } = useTranslation();
    const { token } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { message, isError, isLoading } = useSelector(state => state.Auth);
    const getLanguage = () => i18n.language === 'en-US' || i18n.language === 'en' ? 'en' : 'pt';

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            language: getLanguage(),
            password: '',
            confirm_password: ''
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            password: yup.string().required(t("Please enter new password.")),
            confirm_password: yup.string()
                .required(t("Please enter confirm password."))
                .oneOf([yup.ref("password")], t("Both passwords need to be the same.")),
        }),
        onSubmit: async (values) => {
            dispatch(resetForgotPasssword(token, values, formik, setShowSuccessModal));
        }
    })

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    return (
        <section className='loginbase'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 loginimg'>
                        {/* <img src={loginimg} alt='' /> */}
                    </div>
                    <div className='col-lg-6'>
                        <div className='loginCard'>
                            <div className='loginLogo'>
                                {/* <img src={logo} alt='' /> */}
                                <h4>{t("Forgot Password")}?</h4>
                            </div>
                            {message && isError && typeof message === 'string' && (
                                <Alert className='mb-3' variant="danger" onClose={() => dispatch(clearMessage())} dismissible>
                                    {message}
                                </Alert>
                            )}
                            <form onSubmit={formik.handleSubmit}>
                                <div className='fieldPosition mb-3'>
                                    <FloatingLabel controlId="floatingPassword" label={t("New Password")} className="cmn-style-floating floating-btn-group">
                                        <span className='fieldicon'><i className="fa-regular fa-lock"></i></span>
                                        <Form.Control name="password" type={showPassword ? "text" : "password"} placeholder="Enter new password" onChange={formik.handleChange} value={formik.values.password} />
                                        <button type='button' className="input-group-addon btn loginEye" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                        </button>
                                    </FloatingLabel>
                                    {formik.touched.password && formik.errors.password ? <p className="errorTxt">{formik.errors.password}</p> : null}
                                </div>

                                <div className='fieldPosition mb-4'>
                                    <FloatingLabel controlId="floatingConfirmPassword" label={t("Confirm Password")} className="cmn-style-floating floating-btn-group">
                                        <span className='fieldicon'><i className="fa-regular fa-lock"></i></span>
                                        <Form.Control name="confirm_password" type={showConfirmPassword ? "text" : "password"} placeholder="Enter confirm password" onChange={formik.handleChange} value={formik.values.confirm_password} />
                                        <button type='button' className="input-group-addon btn loginEye" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {showConfirmPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                        </button>
                                    </FloatingLabel>
                                    {formik.touched.confirm_password && formik.errors.confirm_password ? <p className="errorTxt">{formik.errors.confirm_password}</p> : null}
                                </div>

                                <button className='loginButton' type='submit' disabled={isLoading}>
                                    {isLoading ? <Spinner animation="border" size="sm" /> : <span>{t("Change Password")}</span>}
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


            <Modal className='forgotModal' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} backdrop="static"
                keyboard={false} centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <img src={success} alt='' />
                    <h5 className='px-3 pb-3'>{t("Your Password Has Been Changed Successfully.")}</h5>
                    <button type="button" className="btn cmn-btn-style" onClick={() => navigate("/login")}>{t("Back to Login")}</button>
                </Modal.Body>
            </Modal>

        </section>
    )
}

export default ForgotPassword;