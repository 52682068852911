import {
  PAYMENT_AMOUNT,
  PAYMENT_SUCCESS,
  CLEAR_MESSAGE,
  START_LOADING,
  STOP_LOADING,
  DATA_FAILED,
} from '../actions/Types';

const initialState = {
  paymentAmount: 0,
  month: '',
  message: null,
  isError: false,
  isLoading: false,
  approvalUrl: '',
};

export default function Payment(state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        isError: false,
      };

    case PAYMENT_AMOUNT:
      return {
        ...state,
        paymentAmount: payload.paymentAmount,
        month: payload.month,
        message: payload.message,
      };
    case DATA_FAILED:
      return {
        ...state,
        message: payload.message,
      };

    case PAYMENT_SUCCESS:
      return {
        ...state,
        //paymentAmount: 0,
        //message: payload.message,
        approvalUrl: payload.approvalUrl,
      };
    default:
      return state;
  }
}
