import './index.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import { clearMessage } from '../../../../actions/Actions';
import DoubleElimination from '../brackets/double-elimination';
import GroupPhase from '../brackets/group-phase';
import { getGroupPhaseBrackets } from '../../../../services/tournament.service';

  
const ViewTournament = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { tournaments, isLoading } = useSelector(state => state.Tournaments);
    const teamsToEdit = tournaments.find(tournament => tournament.id === Number(id));
    const tournamentName = teamsToEdit ? teamsToEdit.championship_name + " (" + teamsToEdit.category?.category_name + ")" : '';

    const [groupPhaseData, setGroupPhaseData]= useState()
    // console.log(teamsToEdit)
    // console.log("getGroupPhaseTeams===>", groupPhaseData)

    useEffect(()=>{
        // getGroupPhaseBrackets(id).then((data)=>{
        //     console.log("getGroupPhaseTeams===>", data)
        //     setGroupPhaseData(data.data)
        // })
    },[])


    return (
        <>
            <h2 className='admin-page-title bordrBtm mb-4 pb-2'>{tournamentName}</h2>
            {/* {teamsToEdit && teamsToEdit.type.type_name === "Double Elimination" && <DoubleElimination />}
            {teamsToEdit && teamsToEdit.type.type_name === "Group Face" && <GroupPhase data={groupPhaseData} />} */}
        </>
    )
}

export default ViewTournament;