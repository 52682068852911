import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../pages/frontend/common/header';
import Footer from '../pages/frontend/common/footer';
import { useSelector } from 'react-redux';
import { changeLanguageApi, fetchDeviceToken } from '../services/auth.service';
import store from '../stores/store';
import { getContactInfo } from '../actions/Cms';
import { useTranslation } from 'react-i18next';

const FrontendLayout = () => {
    const { i18n } = useTranslation();
    const { isLoggedIn, deviceToken } = useSelector(state => state.Auth);
    const lng = i18n.language === 'en-US' || i18n.language === 'en' ? 'en' : 'pt';

    useEffect(() => {
        store.dispatch(getContactInfo());
        if (isLoggedIn && deviceToken) fetchDeviceToken(deviceToken);
        if(isLoggedIn) changeLanguageApi(lng);
    }, []);

    return (
        <>
            <header className='auth-header-wrap'><Header /></header>
            <main className='auth-body-wrap'><Outlet /></main>
            <footer className='auth-footer-wrap'><Footer /></footer>
        </>
    )
}

export default FrontendLayout;