import React from 'react'
import whiteicn from '../../../assets/images/footerwhitelogo.svg'
import plyr from '../../../assets/images/plyr.png'
import imgShape from '../../../assets/images/ShieldBadge2.svg';
import upImg1 from '../../../assets/images/up-img-1.png';
// import upImg2 from '../../../assets/images/up-img-2.png';
// import upImg3 from '../../../assets/images/up-img-3.png';
import psImg1 from '../../../assets/images/ps-img-1.png';
import psImg2 from '../../../assets/images/ps-img-2.png';
import psImg3 from '../../../assets/images/ps-img-3.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const Upgrade = () => {
    const { t } = useTranslation();
    return (
        <section className='upgrade-wrap'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='upgbg'>
                            <h3><img className="logo" src={whiteicn} alt='' />{t("Upgrade Your Future")}</h3>
                            <div>
                                <div className='up-images'>
                                    <div className='up-img-block transform1'>
                                        <img className='img-user' src={upImg1} alt="No user" />
                                        <img className='img-shape' src={imgShape} alt="No user" />
                                    </div>
                                    <div className='up-img-block'>
                                        <img className='img-user' src={upImg1} alt="No user" />
                                        <img className='img-shape' src={imgShape} alt="No user" />
                                    </div>
                                    <div className='up-img-block transform1'>
                                        <img className='img-user' src={upImg1} alt="No user" />
                                        <img className='img-shape' src={imgShape} alt="No user" />
                                    </div>
                                </div>
                                <div className='up-content'>
                                    <p className='up-name'><span>#17</span> Rinaldo Rodrigues</p>
                                    <p className='up-address'>Ribeirao Preto- SP</p>
                                    <div className='up-rating'>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-duotone fa-solid fa-star"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 mt-lg-0 mt-4'>
                        <div className='upgbg ps-right-wrap'>
                            {/* <h6><span>#17</span> Rinaldo Rodrigues</h6> */}
                            <div className='playrdetl'>
                                <div className='playrLft'>
                                    <img src={plyr} alt='' />
                                </div>
                                <div className='playrRgt'>
                                    <h6><span>#10</span> Victor Leite</h6>
                                    <p className='text-sa'>
                                        <span>{t("Side")}: <strong>{t("Right")}</strong></span>
                                        <span>{t("Age")}: <strong>25 {t("Years")}</strong></span>
                                    </p>
                                    <div className='btns-sa'>
                                        <Link className='commonButton'><i className="fa-regular fa-share"></i> {t("Share")}</Link>
                                        <Link className='commonButton'><i className="fa-regular fa-pen"></i> {t("Edit Profile")}</Link>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='ps-item-wrap'>
                                <div className="ps-item-col">
                                    <div className="ps-item-card">
                                        <div className="ps-item-img">
                                            <img src={psImg1} alt="Image" />
                                        </div>
                                        <div className="ps-item-content">
                                            <h6>1350 {t("FTV Points")}</h6>
                                            <p>{t("Collect")} <i className="fa-solid fa-arrow-right"></i></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ps-item-col">
                                    <div className="ps-item-card">
                                        <div className="ps-item-img">
                                            <img src={psImg2} alt="Image" />
                                        </div>
                                        <div className="ps-item-content">
                                            <h6>1350 {t("FTV Points")}</h6>
                                            <p>{t("Collect")} <i className="fa-solid fa-arrow-right"></i></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ps-item-col">
                                    <div className="ps-item-card">
                                        <div className="ps-item-img">
                                            <img src={psImg3} alt="Image" />
                                        </div>
                                        <div className="ps-item-content">
                                            <h6>1350 {t("FTV Points")}</h6>
                                            <p>{t("Collect")} <i className="fa-solid fa-arrow-right"></i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='row'>
                <div className='col-md-12'>carousel</div>
            </div> */}
            </div>
        </section>
    )
}

export default Upgrade;