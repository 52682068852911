function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const getSearchedByName = (data, inputSearch) => {
    if (!inputSearch) return data;

    const escapedInputSearch = escapeRegExp(inputSearch);
    const nameRegex = new RegExp(`^${escapedInputSearch}`, "i");

    return data.filter(({ name, display_name }) =>
        name?.match(nameRegex) || display_name?.match(nameRegex)
    );
};

export const getSearchedByIdNameNickName = (data, inputSearch) => {
    if (!inputSearch) return data;

    let filteredData;

    if (inputSearch.startsWith("#")) {
        const idSearch = inputSearch.slice(1);
        const escapedIdSearch = escapeRegExp(idSearch);
        const idRegex = new RegExp(`^${escapedIdSearch}`, "i");
        filteredData = data.filter(({ user_id }) => user_id?.toString().match(idRegex));
    } else {
        const escapedInputSearch = escapeRegExp(inputSearch);
        const nameRegex = new RegExp(`^${escapedInputSearch}`, "i");
        filteredData = data.filter(({ name, display_name, gender }) =>
            name?.match(nameRegex) || display_name?.match(nameRegex) || gender?.match(nameRegex)
        );
    }

    return filteredData.sort((a, b) => a.user_id - b.user_id);
};

export const getSearchedByCityState = (data, inputSearch) => {
    if (!inputSearch) return data;

    const escapedInputSearch = escapeRegExp(inputSearch);
    const nameRegex = new RegExp(`^${escapedInputSearch}`, "i");

    return data.filter(({ city, state }) =>
        city?.match(nameRegex) || state?.match(nameRegex)
    );
};

export const getSearchedByChampionshipName = (data, inputSearch) => {
    if (!inputSearch) return data;

    const escapedInputSearch = escapeRegExp(inputSearch);
    const nameRegex = new RegExp(`^${escapedInputSearch}`, "i");

    return data.filter(({ championship_name }) =>
        championship_name?.match(nameRegex)
    );
};

export const getSearchedByTitle = (data, inputSearch) => {
    if (!inputSearch) return data;

    const escapedInputSearch = escapeRegExp(inputSearch);
    const nameRegex = new RegExp(`^${escapedInputSearch}`, "i");

    return data.filter(({ title }) =>
        title?.match(nameRegex)
    );
};
