import './index.scss';
import React, { useEffect, useState } from "react";
import logo from '../../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../../actions/Auth';
import { Alert, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { CLEAR_MESSAGE } from '../../../actions/Types';
import { clearMessage } from '../../../actions/Actions';
import { useTranslation } from 'react-i18next';
import PhoneInputField from '../../../components/phone-input-field';
import { CitySelectField, StateSelectField } from '../../../components/country-state-cily-select-field';
import { AddressInputField } from '../../../components/address-input-field';

const Register = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { message, isError, isLoading } = useSelector(state => state.Auth);
    const getLanguage = () => i18n.language === 'en-US' || i18n.language === 'en' ? 'en' : 'pt';

    const formik = useFormik({
        initialValues: {
            language: getLanguage(),
            name: "",
            display_name: "",
            email: "",
            phone: "",
            password: "",
            confirm_password: "",
            state: "",
            city: "",
            address: "",
            term_condition: false,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            name: yup.string().min(2, t("Name must have atleast 2 characters.")).required(t("Please enter your name.")),
            display_name: yup.string().required(t("Please enter nick name.")),
            email: yup.string().email(t("Invalid email address.")).required(t("Please enter email address.")),
            phone: yup.number().required(t("Please enter phone number.")),
            password: yup.string().required(t("Please enter password.")),
            confirm_password: yup.string()
                .required(t("Please enter confirm password."))
                .oneOf([yup.ref("password")], t("Both passwords need to be the same.")),
            state: yup.string().required(t("Please enter state.")),
            city: yup.string().required(t("Please enter city.")),
            address: yup.string().required(t("Please enter address.")),
            term_condition: yup.bool().oneOf([true], t("You need to accept the terms & conditions.")),
        }),
        onSubmit: async (values) => {
            dispatch(register(values, formik, navigate));
        }
    })

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    return (
        <section className='loginbase regd'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-2 loginimg'>
                        {/* <img src={loginimg} alt='' /> */}
                    </div>
                    <div className='col-lg-8'>
                        <div className='loginCard'>
                            <div className='loginLogo'>
                                <img src={logo} alt='' />
                                <h4>{t("Create Your Account")}</h4>
                            </div>
                            {message && isError && typeof message === 'string' && (
                                <Alert className='mb-3' variant="danger" onClose={() => dispatch({ type: CLEAR_MESSAGE })} dismissible>
                                    {message}
                                </Alert>
                            )}
                            {message && !isError && (
                                <Alert className='mb-3' variant="success" onClose={() => dispatch({ type: CLEAR_MESSAGE })} dismissible>
                                    {message}
                                </Alert>
                            )}
                            <form onSubmit={formik.handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-6 mb-3'>
                                        <FloatingLabel controlId="Name" label={t("Name")} className='cmn-style-floating'>
                                            <Form.Control type="text" name="name" placeholder="Enter name" onChange={formik.handleChange} value={formik.values.name} />
                                            {formik.touched.name && formik.errors.name ? <p className="errorTxt">{formik.errors.name}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <FloatingLabel controlId="NickName" label={t("Nick Name")} className='cmn-style-floating'>
                                            <Form.Control type="text" name="display_name" placeholder="Enter nick name" onChange={formik.handleChange} value={formik.values.display_name} />
                                            {formik.touched.display_name && formik.errors.display_name ? <p className="errorTxt">{formik.errors.display_name}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <FloatingLabel controlId="Email" label={t("Email Address")} className='cmn-style-floating'>
                                            <Form.Control type="email" name="email" placeholder="Enter email address" onChange={formik.handleChange} value={formik.values.email} />
                                            {formik.touched.email && formik.errors.email ? <p className="errorTxt">{formik.errors.email}</p> : null}
                                        </FloatingLabel>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <PhoneInputField formik={formik} fieldName={"phone"} labelName={t('Phone Number')} />
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <div className='fieldPosition'>
                                            <FloatingLabel controlId="Password" label={t("Password")} className="cmn-style-floating">
                                                <Form.Control name="password" type={showPassword ? "text" : "password"} placeholder="Enter password" onChange={formik.handleChange} value={formik.values.password} />
                                                <button type='button' className="input-group-addon btn loginEye" onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                                </button>
                                            </FloatingLabel>
                                            {formik.touched.password && formik.errors.password ? <p className="errorTxt">{formik.errors.password}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <div className='fieldPosition'>
                                            <FloatingLabel controlId="ConfirmPassword" label={t("Confirm Password")} className="cmn-style-floating">
                                                <Form.Control name="confirm_password" type={showConfirmPassword ? "text" : "password"} placeholder="Enter confirm password" onChange={formik.handleChange} value={formik.values.confirm_password} />
                                                <button type='button' className="input-group-addon btn loginEye" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                    {showConfirmPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                                </button>
                                            </FloatingLabel>
                                            {formik.touched.confirm_password && formik.errors.confirm_password ? <p className="errorTxt">{formik.errors.confirm_password}</p> : null}
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <StateSelectField formik={formik} cityFieldName={"city"} fieldName={"state"} labelName={t('State')} />
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <CitySelectField formik={formik} stateFieldName={"state"} fieldName={"city"} labelName={t('City')} />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <AddressInputField formik={formik} fieldName={"address"} labelName={t('Address')} />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <div className="acceptTerms">
                                            <input type="checkbox" id="Accept" name="term_condition" onChange={formik.handleChange} value={formik.values.term_condition} checked={formik.values.term_condition} />
                                            <label htmlFor="Accept">{t("Accept")}</label>&nbsp;
                                            <Link to="/">{t("Terms & Condition")}</Link>
                                        </div>
                                        {formik.touched.term_condition && formik.errors.term_condition ? <p className="errorTxt">{formik.errors.term_condition}</p> : null}
                                    </div>
                                </div>

                                <button className='loginButton' type='submit' disabled={isLoading}>
                                    {isLoading ? <Spinner animation="border" size="sm" /> : <span>{t("Register")}</span>}  
                                </button>
                            </form>
                            <h6 className='text-center pt-3'>{t("Already have an account")}? <Link to="/login">{t("Login")}</Link> {t("here")}</h6>
                        </div>
                    </div>
                    <div className='col-lg-2 loginimg'></div>
                </div>
            </div>
        </section>
    )
}

export default Register;