import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {thunk} from 'redux-thunk';
import Auth from '../reducers/Auth';
import Arenas from '../reducers/Arenas';
import Athletes from '../reducers/Athletes';
import Tournaments from '../reducers/Tournaments';
import Cms from '../reducers/Cms';
import CommonData from '../reducers/CommonData';
import Payment from '../reducers/Payment';

const rootReducer = combineReducers({
  Auth,
  Arenas,
  Athletes,
  Tournaments,
  Cms,
  Payment,
  CommonData,
});

const middleware = [thunk];

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(...middleware),
});

export default store;
