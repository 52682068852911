import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import discicon1 from '../../../assets/images/discicon1.svg';
import discicon2 from '../../../assets/images/discicon2.svg';
import discicon3 from '../../../assets/images/discicon3.svg';
import discicon4 from '../../../assets/images/discicon4.svg';
import { useTranslation } from 'react-i18next';

const DiscoverSlider = () => {
    const { t } = useTranslation();
    const options = {
        items: 4,
        rewind: true,
        autoplay: true,
        nav: true,
        dots: false,
        margin: 15,
        navText: ["<i className='fa-solid fa-arrow-left'></i>", "<i className='fa-solid fa-arrow-right'></i>"],
        responsive: {
            0: {
                items: 1
            },
            991: {
                items: 2
            },
            1000: {
                items: 4
            }
        }
    };

    return (
        <>
        <h3 className='text-center pb-4 mt-5 pt-3'>{t("Discover Their Experiences")}</h3>
        <OwlCarousel className='owl-theme' {...options}>
            <div className='item'>
                <div className='discoverSlide'>
                    <img src={discicon1} alt='' />
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                        Nullam dictum felis</p>
                    <h6>Aenean massa</h6>
                    <small>Vivamus elementum. </small>
                </div>
            </div>
            <div className='item'>
                <div className='discoverSlide'>
                    <img src={discicon2} alt='' />
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                        Nullam dictum felis</p>
                    <h6>Aenean massa</h6>
                    <small>Vivamus elementum. </small>
                </div>
            </div>
            <div className='item'>
                <div className='discoverSlide'>
                    <img src={discicon3} alt='' />
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                        Nullam dictum felis</p>
                    <h6>Aenean massa</h6>
                    <small>Vivamus elementum. </small>
                </div>
            </div>
            <div className='item'>
                <div className='discoverSlide'>
                    <img src={discicon4} alt='' />
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                        Nullam dictum felis</p>
                    <h6>Aenean massa</h6>
                    <small>Vivamus elementum. </small>
                </div>
            </div>
            <div className='item'>
                <div className='discoverSlide'>
                    <img src={discicon2} alt='' />
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                        Nullam dictum felis</p>
                    <h6>Aenean massa</h6>
                    <small>Vivamus elementum. </small>
                </div>
            </div>
        </OwlCarousel>
        </>
    )
}


export default DiscoverSlider