import './index.scss';
import React, { useEffect } from "react";
import logo from '../../../../assets/images/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import noUser from '../../../../assets/images/no-user.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { clearAllNotification } from '../../../../actions/CommonData';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '../../../../actions/Auth';

const Header = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn, user } = useSelector(state => state.Auth);
    const { notificationlist } = useSelector(state => state.CommonData);

    useEffect(() => { }, [user]);
    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    // console.log("notificationlist====>", notificationlist)

    return (
        <header className='dbHead'>
            <div className='container-fluid'>
                <div className='dbHeadContent'>
                    <div className="dbHeadLeft">
                        <Link className='dbLogo' to='/'><img src={logo} alt='' /></Link>
                        <button type='button' onClick={() => setIsSidebarOpen(!isSidebarOpen)} className={`btn btn-outline-light border-0 d-inline-block d-lg-none ms-2 ${isSidebarOpen ? "" : ""}`}>{isSidebarOpen ? <i className="fa-regular fa-bars"></i> : <i className="fa-regular fa-xmark"></i>}</button>
                    </div>
                    <div className="dbHeadRight">
                        {/* <Dropdown className="chatBase">
                        <Dropdown.Toggle variant="" id="">
                            <i className="fa-regular fa-comment-dots"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>Msw</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}


                        <Dropdown className="notifyBase d-flex">
                            <Dropdown.Toggle variant="" id="">
                                <i className="fa-regular fa-bell"></i>
                                {notificationlist?.length > 0 && <Badge pill bg="danger">{notificationlist?.length}</Badge>}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className='noti-dropdown-header'>
                                    <span className='title'>{t("Notifications")}</span>
                                    <span className='noti-clear-all' onClick={() => dispatch(clearAllNotification())}>{t("Clear All")}</span>
                                </div>
                                <div className='noti-dropdown-body'>

                                    {notificationlist && notificationlist?.length > 0 ? notificationlist?.map((item) => {
                                        return (
                                            <div className='noti-item' key={item.id} onClick={() => {
                                                if (item.type === "News") navigate(`/news/view/${item.foreign_id}`)
                                            }}>
                                                <h6>{item.title}</h6>
                                                <p>{item.body}</p>
                                            </div>
                                        )
                                    }) : <div className='no-data-found text-center'>{t("No notifications.")}</div>}
                                </div>

                            </Dropdown.Menu>
                        </Dropdown>

                        {user && isLoggedIn ?
                            <Dropdown className="userBase">
                                <Dropdown.Toggle variant="" onClick={() => navigate("/my-profile")}>
                                    {user.profile_url === null || user.profile_url === "" || user.profile_url === undefined ?
                                        (<span className="user-img">
                                            <img src={noUser} alt='No user' />
                                        </span>) :
                                        (<span className="user-img">
                                            <img src={process.env.REACT_APP_IMGBASE_URL + user.profile_url} alt='No user' />
                                        </span>)}
                                    <span className="user-name">{user.name}</span>
                                </Dropdown.Toggle>
                                {/* <Dropdown.Menu>
                                <Dropdown.Item><i className="fa-solid fa-right-from-bracket"></i> Logout</Dropdown.Item>
                            </Dropdown.Menu> */}
                            </Dropdown> : null}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;