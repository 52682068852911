import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPreviousTournament, getUpcomingTournament } from '../../../actions/Cms';
import ChampionshipItem from './ChampionshipItem';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';
import Loader from '../../../components/loader';

const options = {
    items: 3,
    rewind: true,
    autoplay: true,
    nav: true,
    dots: false,
    margin: 15,
    navText: ["<i className='fa-solid fa-arrow-left'></i>", "<i className='fa-solid fa-arrow-right'></i>"],
    responsive: {
        0: {
            items: 1
        },
        767: {
            items: 2
        },
        1000: {
            items: 3
        }
    }
};

const Championship = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { upcoming_tournament, previous_tournament, isLoading } = useSelector(state => state.Cms);

    useEffect(() => {
        dispatch(getUpcomingTournament());
        dispatch(getPreviousTournament());
    }, [dispatch]);

    return (
        <section className='championship'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <h2 className='pb-2'>{t("Championships / Tournament")}</h2>
                        {isLoading ? <Loader /> : <Tabs
                            defaultActiveKey="upcomming"
                            transition={false}
                            className="cmn-tabs-style mb-3"
                        >
                            <Tab eventKey="upcomming" title={t("Up Coming")}>
                                {upcoming_tournament && upcoming_tournament.length > 0 ?
                                    <OwlCarousel className='owl-theme' {...options}>
                                        {upcoming_tournament.map((item) => {
                                            return (
                                                <div key={item.id} className="item text-start">
                                                    <ChampionshipItem item={item} />
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>
                                    :
                                    <p className="mb-0">{t("Up Coming")} {t("Championships / Tournament")} {t("Not Found")}</p>
                                }
                            </Tab>
                            <Tab eventKey="previous" title={t("Previous")}>
                                {previous_tournament && previous_tournament.length > 0 ?
                                    <OwlCarousel className='owl-theme' {...options}>
                                        {previous_tournament.map((item) => {
                                            return (
                                                <div key={item.id} className="item text-start">
                                                    <ChampionshipItem item={item} />
                                                </div>
                                            )
                                        })}
                                    </OwlCarousel>
                                    :
                                    <p className="mb-0">{t("Previous")} {t("Championships / Tournament")} {t("Not Found")}</p>
                                }
                            </Tab>
                        </Tabs>}
                    </div>
                </div>
              
            </div>
        </section>
    )
}

export default Championship;