import { useTranslation } from 'react-i18next';
import './index.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentData, paymentProcess } from '../../../actions/Payment';
import { Alert, Spinner } from 'react-bootstrap';
import { clearMessage } from '../../../actions/Actions';
// import { paymentSuccessData } from '../../../actions/Actions';
import Loader from '../../../components/loader';
// import QRCode from 'react-qr-code';
// import { useNavigate } from 'react-router-dom';
const Payment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const navigation = useNavigate();
  const { paymentAmount, isLoading, month, message, isError, approvalUrl } = useSelector(state => state.Payment);
  const { user } = useSelector(state => state.Auth);

  useEffect(() => {
    dispatch(fetchPaymentData());
  }, []);

  const paymentProcessFunc = () => {
    const sendData = {
      amount: paymentAmount,
      arena_id: user.id,
      month: month,
      payment_type: "Paypal"
    };
    dispatch(paymentProcess(sendData));
  };

  useEffect(() => {
    paymentFunc();
  }, [approvalUrl]);

  const paymentFunc = () => {
    // console.log('=====approvalUrl=========>', approvalUrl);
    if (approvalUrl != '') {
      //window.open(approvalUrl, '_blank', 'noopener,noreferrer');
      window.open(approvalUrl, '_self');
      //dispatch(paymentSuccessData(''));
    }
  };

  return (
    <>
      {isLoading ? <Loader /> :
        <>
          {paymentAmount > 0 && message && !isError && (
            <Alert
              className="mb-3"
              variant="success"
              onClose={() => dispatch(clearMessage())}
              dismissible>
              {message}
            </Alert>
          )}
          <div className="payment-box">
            <h2 className="admin-page-title mb-3">{t('Payment')}</h2>

            <div className="payment-mode">
              <label>{t('Payment Mode')}</label>
              <select className="form-control">
                <option>Paypal</option>
                {/* <option>100</option>
          <option>500</option>
          <option>1000</option> */}
              </select>
            </div>
            <div className="payment-amount mt-4">
              <div className="payment-label">
                {t('Payment Amount')}
                <span>{paymentAmount == 0 ? '0' : `R$ ${paymentAmount}`}</span>
              </div>
              <div className="payment-des">
                Consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                Neque porro quisquam est, qui dolorem ipsum ..
              </div>
            </div>
            {/* {approvalUrl != '' && (
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            maxWidth: 200,
            width: '100%',
          }}>
          <QRCode
            size={256}
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            value={approvalUrl}
            viewBox={`0 0 256 256`}
          />
        </div>
      )} */}
            {paymentAmount != 0 && (
              <button
                disabled={isLoading}
                type="button"
                className="btn btn-make-payment mt-4"
                onClick={() => paymentProcessFunc()}>
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>{t('Make Payment')}</>
                )}
              </button>
            )}
          </div>
        </>
      }
    </>
  );
};

export default Payment;
