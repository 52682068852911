import {
    ARENA_SUCCESS,
    ARENA_VIEW,
    CLEAR_MESSAGE,
    DATA_FAILED,
    START_LOADING,
    STOP_LOADING
} from "../actions/Types";

const initialState = {
    arenas: [],
    details: {},
    message: null,
    isError: false,
    isLoading: false
}
export default function Arenas(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ARENA_SUCCESS:
            return {
                ...state,
                arenas: payload.arenas.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
            }
        case DATA_FAILED:
            return {
                ...state,
                message: payload.message,
            }
        case ARENA_VIEW:
            return {
                ...state,
                details: payload.details,
            };
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: null,
                isError: false
            };
        default:
            return state;
    }
}