import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./index.scss";

const LoadMore = ({ visibleItems, setVisibleItems, totalItems, perPage }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const loadMoreItems = () => {
        setLoading(true);
        setTimeout(() => {
            setVisibleItems((prev) => prev + perPage);
            setLoading(false);
        }, 1000);
    };


    return (
        <>
            {visibleItems < totalItems && (
                <div className='loadItems'>
                    <button onClick={loadMoreItems} disabled={loading} className="commonButton addBtn">
                        {loading ? t("Loading") + "..." : t("Load More")}
                    </button>
                </div>
            )}
        </>
    );
}

export default LoadMore;
