import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };
  const queryParams = getQueryParams(location.search);
  const paymentType = queryParams.get('payment_type');
  const amount = queryParams.get('amount');
  const transactionId = queryParams.get('transaction_id');
  
  return (
    <section className="payment-sf">
      <div className="container">
        <div className="cmn-card-info-content payment-success">
          <i className="fa-duotone fa-circle-check"></i>
          <h3>{t("Payment Successful")}</h3>
          <p className='t-no'>{t("Transaction Number")}: <span>{transactionId ? transactionId : "N/A"}</span></p>
          <hr />
          <p>{t("Amount Paid")}: <span>{amount ? "R$ "+amount : "N/A"}</span></p>
          <p>{t("Payed By")} <span>{paymentType ? paymentType : "N/A"}</span></p>
          <Link to="/dashboard" className="btn btn-primary mt-3">{t("Back to Dashboard")}</Link>
        </div>
      </div>
    </section>
  );
};

export default PaymentSuccess;
