import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MatchUpdateConfirmModal = ({
    updateConfirmModal,
    setUpdateConfirmModal,
    confirmUpdateAction,
    matchWiningScore
}) => {
    const { t } = useTranslation();

    const handleConfirmUpdate = () => {
        setUpdateConfirmModal(false);
        confirmUpdateAction();
    }

    return (
        <Modal
            show={updateConfirmModal}
            onHide={() => setUpdateConfirmModal(false)}
            keyboard={false}
            size="sm"
            className="logout-modal"
            centered
        >
            <Modal.Body>
                {!matchWiningScore ?
                    <>
                        <p className="text-center">{t("Are you sure you want to update this match")}?</p>
                        <div className="mt-3 justify-content-center d-flex">
                            <button type="button" className="btn btn-outline-primary commonButton" onClick={() => setUpdateConfirmModal(false)}>
                                {t("No")}
                            </button>
                            <button className='btn btn-primary commonButton ms-3' type="button" onClick={handleConfirmUpdate}>
                                <span>{t("Yes")}</span>
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <p className="text-center">{t("Winning team's score must be greater than losing team's score")}</p>
                        <div className="mt-3 justify-content-center d-flex">
                            <button type="button" className="btn btn-outline-primary commonButton" onClick={() => setUpdateConfirmModal(false)}>
                                {t("Ok")}
                            </button>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}

export default MatchUpdateConfirmModal;