import './index.scss';
// import PagePagination from '../../../components/pagination/PagePagination';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getSearchedByTitle } from '../../../helpers';
// import { FloatingLabel, Form } from 'react-bootstrap';
import moment from 'moment';
import Loader from '../../../components/loader';
import LoadMore from '../../../components/load-more';
import noImg from '../../../assets/images/no-image.jpg';
import { getNewsList } from '../../../actions/Cms';
import BannerBar from '../components/banner-bar';
import bannerImg from '../../../assets/images/news-banner.png';

const News = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { news_list, isLoading } = useSelector(state => state.Cms);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredItems = getSearchedByTitle(news_list, searchTerm);
    // FOR-LOAD-MORE 
    const [visibleItems, setVisibleItems] = useState(20);
    // const [currentPage, setCurrentPage] = useState(1);
    // const pageSize = 4;
    // const handlePageChange = (page) => {
    //     setCurrentPage(page);
    // };
    // const fetchData = () => {
    //     const startIndex = (currentPage - 1) * pageSize;
    //     const endIndex = startIndex + pageSize;
    //     const currentPageData = newsData.slice(startIndex, endIndex);
    //     return currentPageData;
    // };

    useEffect(() => {
        dispatch(getNewsList());
    }, [dispatch]);

    return (
        <>
            <div className='mainBase pt-0'>
                <BannerBar
                    title={"News"}
                    subTitle={"Stay up to date with the news! Follow our networks @sport.ranking.ftv!"}
                    description={"The right point in your game."}
                    bannerUrl={bannerImg}
                    linkUrl={"/championships"} />
                <div className='cmn-content-body news-wrapper'>
                    <div className='container'>
                        {/* <div className='topFlex mb-3'>
                            <div></div>
                            <div className='admin-page-header-right'>
                                <FloatingLabel controlId="NewsTitle" label={t("News Title")}>
                                    <Form.Control type="text" name="name" placeholder="" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                </FloatingLabel>
                            </div>
                        </div> */}
                        <div className="row">
                            {isLoading ? <Loader /> :
                                filteredItems && filteredItems.length > 0 ? filteredItems.slice(0, visibleItems).map((item) => (
                                    <div key={item.id} className='col-md-6 col-lg-3 py-3'>
                                        <div className='championCard' onClick={() => navigate(`/news/view/${item?.id}`)}>
                                            <div className='championCardImg height-240'>
                                                {item?.news_url ?
                                                    <img src={process.env.REACT_APP_IMGBASE_URL + item?.news_url} alt="" /> :
                                                    <img src={noImg} alt="" />}
                                            </div>
                                            <div className='cardTxt'>
                                                <div className='cardHdn'>
                                                    <h6>{item?.title}</h6>
                                                    <p>{item?.description?.slice(0, 150)} </p>
                                                    <Link className="btn btn-read-more" to={`/news/view/${item?.id}`}>{t("Read More")}</Link>
                                                </div>
                                                <div className='cardDate'>
                                                    <h5>{moment(item.created_at).format("DD")}</h5>
                                                    <h6>{moment(item.created_at).format("MMMM")}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div className='text-center'><p className='mb-0'>{t("News")} {t("Not Found")}</p></div>}

                        </div>
                        {!isLoading && filteredItems.length > 20 && (
                            <LoadMore visibleItems={visibleItems} setVisibleItems={setVisibleItems} perPage={20} totalItems={filteredItems.length}/>
                        )}
                        {/* <PagePagination totalItems={newsData.length} currentPage={currentPage} pageSize={pageSize} onPageChange={handlePageChange} /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default News;