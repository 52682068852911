import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PaymentCancelled = () => {
  const { t } = useTranslation();

  return (
    <section className="payment-sf">
      <div className="container">
        <div className="cmn-card-info-content payment-failed">
          <i className="fa-duotone fa-circle-exclamation"></i>
          <h3>{t("Payment Cancelled")}</h3>
          <hr/>
          <p>{t("Your bill payment was cancelled successfully")}.</p>
          <Link to="/dashboard" className="btn btn-primary mt-3">{t("Back to Dashboard")}</Link>
        </div>
      </div>
    </section>
  );
};

export default PaymentCancelled;
