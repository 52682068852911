export const Data = {
  success: true,
  data: {
    tournament: {
      id: 112,
      championship_name: 'double elemination',
      arena_id: '126',
      categories_id: '1',
      gender: 'Female',
      types_id: '1',
      duos_id: '1',
      from_date: '2024-05-07',
      to_date: '2024-05-23',
      from_time: '11:23',
      to_time: '11:23',
      status: '0',
      created_at: '2024-05-07T05:53:35.000000Z',
      updated_at: '2024-05-07T05:53:35.000000Z',
      category: {
        id: 1,
        category_name: 'Novice',
      },
      user: {
        id: 126,
        display_name: 'AmitArena001',
      },
      type: {
        id: 1,
        type_name: 'Double Elimination',
      },
    },
    tournament_match: [
      {
        id: 174,
        tournament_id: 112,
        team_id1: 221,
        team_id2: 224,
        winning_team_id: 221,
        losing_team_id: 224,
        level: '1',
        winning_points: 18,
        losing_points: 12,
        winners_side: 0,
        order: 1,
        date: null,
        time: null,
        location: null,
        played: 'true',
        created_at: null,
        updated_at: null,
        team1: {
          id: 221,
          tournament_id: 112,
          athlete_player1_id: 149,
          athlete_player2_id: 150,
          team_division_id: '1',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 149,
            display_name: 'Melon',
          },
          athlete_player2: {
            id: 150,
            display_name: 'Ewerton',
          },
        },
        team2: {
          id: 224,
          tournament_id: 112,
          athlete_player1_id: 139,
          athlete_player2_id: 131,
          team_division_id: '4',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 139,
            display_name: 'Ginger-ath',
          },
          athlete_player2: {
            id: 131,
            display_name: 'Banana',
          },
        },
      },
      {
        id: 175,
        tournament_id: 112,
        team_id1: 227,
        team_id2: 228,
        winning_team_id: null,
        losing_team_id: null,
        level: '1',
        winning_points: null,
        losing_points: null,
        winners_side: 0,
        order: 2,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,
        team1: {
          id: 227,
          tournament_id: 112,
          athlete_player1_id: 119,
          athlete_player2_id: 128,
          team_division_id: '7',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 119,
            display_name: 'qdqrrghnnswqds',
          },
          athlete_player2: {
            id: 128,
            display_name: 'Captoo',
          },
        },
        team2: {
          id: 228,
          tournament_id: 112,
          athlete_player1_id: 110,
          athlete_player2_id: 111,
          team_division_id: '8',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 110,
            display_name: 'Celeine001',
          },
          athlete_player2: {
            id: 111,
            display_name: 'angelena01',
          },
        },
      },
      {
        id: 176,
        tournament_id: 112,
        team_id1: 222,
        team_id2: 225,
        winning_team_id: null,
        losing_team_id: null,
        level: '1',
        winning_points: null,
        losing_points: null,
        winners_side: 0,
        order: 3,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,
        team1: {
          id: 222,
          tournament_id: 112,
          athlete_player1_id: 148,
          athlete_player2_id: 140,
          team_division_id: '2',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 148,
            display_name: 'Dynamo',
          },
          athlete_player2: {
            id: 140,
            display_name: 'Brinjal-ath',
          },
        },
        team2: {
          id: 225,
          tournament_id: 112,
          athlete_player1_id: 133,
          athlete_player2_id: 130,
          team_division_id: '5',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 133,
            display_name: 'Guava',
          },
          athlete_player2: {
            id: 130,
            display_name: 'ARIJIT 31',
          },
        },
      },
      {
        id: 177,
        tournament_id: 112,
        team_id1: 226,
        team_id2: 223,
        winning_team_id: null,
        losing_team_id: null,
        level: '1',
        winning_points: null,
        losing_points: null,
        winners_side: 0,
        order: 4,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,
        team1: {
          id: 226,
          tournament_id: 112,
          athlete_player1_id: 132,
          athlete_player2_id: 129,
          team_division_id: '6',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 132,
            display_name: 'Apple',
          },
          athlete_player2: {
            id: 129,
            display_name: 'Gamer Jit',
          },
        },
        team2: {
          id: 223,
          tournament_id: 112,
          athlete_player1_id: 141,
          athlete_player2_id: 134,
          team_division_id: '3',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 141,
            display_name: 'Ballon-ath',
          },
          athlete_player2: {
            id: 134,
            display_name: 'Imli',
          },
        },
      },
      {
        id: 178,
        tournament_id: 112,
        team_id1: 221,
        team_id2: null,
        winning_team_id: null,
        losing_team_id: null,
        level: 'q',
        winning_points: null,
        losing_points: null,
        winners_side: 0,
        order: 1,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,

        team2: null,
        team1: {
          id: 221,
          tournament_id: 112,
          athlete_player1_id: 149,
          athlete_player2_id: 150,
          team_division_id: '1',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 149,
            display_name: 'Melon',
          },
          athlete_player2: {
            id: 150,
            display_name: 'Ewerton',
          },
        },
      },
      {
        id: 179,
        tournament_id: 112,
        team_id1: null,
        team_id2: null,
        winning_team_id: null,
        losing_team_id: null,
        level: 'q',
        winning_points: null,
        losing_points: null,
        winners_side: 0,
        order: 2,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,
        team1: null,
        team2: null,
      },
      {
        id: 180,
        tournament_id: 112,
        team_id1: 224,
        team_id2: null,
        winning_team_id: null,
        losing_team_id: null,
        level: '1',
        winning_points: null,
        losing_points: null,
        winners_side: 1,
        order: 1,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,

        team2: null,
        team1: {
          id: 224,
          tournament_id: 112,
          athlete_player1_id: 139,
          athlete_player2_id: 131,
          team_division_id: '4',
          status: 1,
          group: '0',
          created_at: '2024-05-07T05:56:08.000000Z',
          updated_at: '2024-05-07T05:56:08.000000Z',
          athlete_player1: {
            id: 139,
            display_name: 'Ginger-ath',
          },
          athlete_player2: {
            id: 131,
            display_name: 'Banana',
          },
        },
      },
      {
        id: 181,
        tournament_id: 112,
        team_id1: null,
        team_id2: null,
        winning_team_id: null,
        losing_team_id: null,
        level: '1',
        winning_points: null,
        losing_points: null,
        winners_side: 1,
        order: 2,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,
        team1: null,
        team2: null,
      },
      {
        id: 182,
        tournament_id: 112,
        team_id1: null,
        team_id2: null,
        winning_team_id: null,
        losing_team_id: null,
        level: 'q',
        winning_points: null,
        losing_points: null,
        winners_side: 1,
        order: 1,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,
        team1: null,
        team2: null,
      },
      {
        id: 183,
        tournament_id: 112,
        team_id1: null,
        team_id2: null,
        winning_team_id: null,
        losing_team_id: null,
        level: 'q',
        winning_points: null,
        losing_points: null,
        winners_side: 1,
        order: 2,
        date: null,
        time: null,
        location: null,
        played: 'false',
        created_at: null,
        updated_at: null,
        team1: null,
        team2: null,
      },
    ],
    winner_stage_count: 2,
    looser_stage_count: 2,
  },
  message: 'Bracket list shown successfully',
};

export const semiFinal = [
  {
    id: 44,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 'q',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 45,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 'q',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
];

export const Group_matches = [
  {
    id: 398,
    tournament_id: 116,
    team_id1: 278,
    team_id2: 280,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'A',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 278,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '6',
      status: 1,
      group: 'A',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 280,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '8',
      status: 1,
      group: 'A',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 399,
    tournament_id: 116,
    team_id1: 278,
    team_id2: 273,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'A',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 278,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '6',
      status: 1,
      group: 'A',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 273,
      tournament_id: 116,
      athlete_player1_id: 99,
      athlete_player2_id: 107,
      team_division_id: '1',
      status: 1,
      group: 'A',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: {
        id: 99,
        display_name: 'qqrr',
      },
      athlete_player2: {
        id: 107,
        display_name: 'chandan002',
      },
    },
  },
  {
    id: 400,
    tournament_id: 116,
    team_id1: 280,
    team_id2: 273,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'A',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 280,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '8',
      status: 1,
      group: 'A',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 273,
      tournament_id: 116,
      athlete_player1_id: 99,
      athlete_player2_id: 107,
      team_division_id: '1',
      status: 1,
      group: 'A',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: {
        id: 99,
        display_name: 'qqrr',
      },
      athlete_player2: {
        id: 107,
        display_name: 'chandan002',
      },
    },
  },
  {
    id: 401,
    tournament_id: 116,
    team_id1: 284,
    team_id2: 275,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'B',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 284,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '12',
      status: 1,
      group: 'B',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 275,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '3',
      status: 1,
      group: 'B',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 402,
    tournament_id: 116,
    team_id1: 284,
    team_id2: 274,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'B',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 284,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '12',
      status: 1,
      group: 'B',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 274,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '2',
      status: 1,
      group: 'B',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 403,
    tournament_id: 116,
    team_id1: 275,
    team_id2: 274,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'B',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 275,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '3',
      status: 1,
      group: 'B',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 274,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '2',
      status: 1,
      group: 'B',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 404,
    tournament_id: 116,
    team_id1: 281,
    team_id2: 277,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'C',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 281,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '9',
      status: 1,
      group: 'C',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 277,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '5',
      status: 1,
      group: 'C',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 405,
    tournament_id: 116,
    team_id1: 281,
    team_id2: 282,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'C',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 281,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '9',
      status: 1,
      group: 'C',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 282,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '10',
      status: 1,
      group: 'C',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 406,
    tournament_id: 116,
    team_id1: 277,
    team_id2: 282,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'C',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 277,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '5',
      status: 1,
      group: 'C',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 282,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '10',
      status: 1,
      group: 'C',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 407,
    tournament_id: 116,
    team_id1: 283,
    team_id2: 279,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'D',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 283,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '11',
      status: 1,
      group: 'D',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 279,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '7',
      status: 1,
      group: 'D',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 408,
    tournament_id: 116,
    team_id1: 283,
    team_id2: 276,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'D',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 283,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '11',
      status: 1,
      group: 'D',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 276,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '4',
      status: 1,
      group: 'D',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
  {
    id: 409,
    tournament_id: 116,
    team_id1: 279,
    team_id2: 276,
    winning_team_id: 0,
    losing_team_id: 0,
    level: 'g',
    group_name: 'D',
    winning_points: 0,
    losing_points: 0,
    winners_side: 0,
    order: 0,
    date: null,
    time: null,
    location: null,
    played: 'false',
    created_at: null,
    updated_at: null,
    team1: {
      id: 279,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '7',
      status: 1,
      group: 'D',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
    team2: {
      id: 276,
      tournament_id: 116,
      athlete_player1_id: null,
      athlete_player2_id: null,
      team_division_id: '4',
      status: 1,
      group: 'D',
      created_at: '2024-05-09T10:31:58.000000Z',
      updated_at: '2024-05-09T10:31:58.000000Z',
      athlete_player1: null,
      athlete_player2: null,
    },
  },
];

export const Group_Teams = {
  success: true,
  data: [
    {
      id: 53,
      tournament_id: 120,
      team_name: '15',
      group_name: 'A',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 15,
        tournament_id: 90,
        athlete_player1_id: null,
        athlete_player2_id: null,
        team_division_id: '3',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:01:20.000000Z',
        updated_at: '2024-04-15T11:00:58.000000Z',
        athlete_player1: null,
        athlete_player2: null,
      },
    },
    {
      id: 54,
      tournament_id: 120,
      team_name: '6',
      group_name: 'A',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 6,
        tournament_id: 80,
        athlete_player1_id: 100,
        athlete_player2_id: 101,
        team_division_id: '2',
        status: 1,
        group: null,
        created_at: '2024-04-15T05:49:45.000000Z',
        updated_at: '2024-04-15T05:50:30.000000Z',
        athlete_player1: {
          id: 100,
          name: 'hhhg',
          display_name: 'qqrrghn',
          cpf_no: '92087896007',
          email: 'oovul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:40:13.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:40:13.000000Z',
          updated_at: '2024-04-09T07:40:13.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 101,
          name: 'hhhg',
          display_name: 'qqrrghnn',
          cpf_no: '92087896001',
          email: 'movul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:40:28.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:40:29.000000Z',
          updated_at: '2024-04-09T07:40:29.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 55,
      tournament_id: 120,
      team_name: '12',
      group_name: 'A',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 12,
        tournament_id: 89,
        athlete_player1_id: 102,
        athlete_player2_id: 107,
        team_division_id: '4',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:00:13.000000Z',
        updated_at: '2024-04-15T09:16:45.000000Z',
        athlete_player1: {
          id: 102,
          name: 'hhhg',
          display_name: 'qdqrrghnn',
          cpf_no: '92187896001',
          email: 'dmovul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:40:49.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:40:50.000000Z',
          updated_at: '2024-04-09T07:40:50.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 107,
          name: 'Chandan Kamila',
          display_name: 'chandan002',
          cpf_no: '45845676363',
          email: 'chandan1@mailinator.com',
          email_verified_at: '2024-04-09T12:02:10.000000Z',
          phone: 8017352586,
          zipcode: null,
          playing_side: 'right',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T12:02:10.000000Z',
          updated_at: '2024-04-09T12:02:10.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 56,
      tournament_id: 120,
      team_name: '16',
      group_name: 'A',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 16,
        tournament_id: 90,
        athlete_player1_id: null,
        athlete_player2_id: null,
        team_division_id: '4',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:01:20.000000Z',
        updated_at: '2024-04-15T11:00:58.000000Z',
        athlete_player1: null,
        athlete_player2: null,
      },
    },
    {
      id: 57,
      tournament_id: 120,
      team_name: '2',
      group_name: 'B',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 2,
        tournament_id: 77,
        athlete_player1_id: 111,
        athlete_player2_id: 107,
        team_division_id: '2',
        status: 1,
        group: null,
        created_at: '2024-04-12T14:18:20.000000Z',
        updated_at: '2024-04-14T08:21:11.000000Z',
        athlete_player1: {
          id: 111,
          name: 'angelena',
          display_name: 'angelena01',
          cpf_no: '14785236900',
          email: 'angelena@mailinator.com',
          email_verified_at: '2024-04-10T10:44:35.000000Z',
          phone: 9883121259,
          zipcode: null,
          playing_side: 'right',
          gender: 'Female',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-10T10:44:36.000000Z',
          updated_at: '2024-04-10T10:44:36.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 107,
          name: 'Chandan Kamila',
          display_name: 'chandan002',
          cpf_no: '45845676363',
          email: 'chandan1@mailinator.com',
          email_verified_at: '2024-04-09T12:02:10.000000Z',
          phone: 8017352586,
          zipcode: null,
          playing_side: 'right',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T12:02:10.000000Z',
          updated_at: '2024-04-09T12:02:10.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 58,
      tournament_id: 120,
      team_name: '4',
      group_name: 'B',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 4,
        tournament_id: 77,
        athlete_player1_id: 109,
        athlete_player2_id: 77,
        team_division_id: '4',
        status: 1,
        group: null,
        created_at: '2024-04-12T14:18:20.000000Z',
        updated_at: '2024-04-14T08:21:11.000000Z',
        athlete_player1: {
          id: 109,
          name: 'Arijit10',
          display_name: 'Captain',
          cpf_no: '11111111113',
          email: 'ari10@mailinator.com',
          email_verified_at: '2024-04-10T10:23:47.000000Z',
          phone: 3124978063,
          zipcode: 'null',
          playing_side: 'right',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-10T09:55:28.000000Z',
          updated_at: '2024-04-10T10:52:02.000000Z',
          deleted_at: null,
        },
        athlete_player2: null,
      },
    },
    {
      id: 59,
      tournament_id: 120,
      team_name: '3',
      group_name: 'B',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 3,
        tournament_id: 77,
        athlete_player1_id: 62,
        athlete_player2_id: 110,
        team_division_id: '3',
        status: 1,
        group: null,
        created_at: '2024-04-12T14:18:20.000000Z',
        updated_at: '2024-04-14T08:21:11.000000Z',
        athlete_player1: {
          id: 62,
          name: 'harry',
          display_name: 'harry',
          cpf_no: '22365789652',
          email: 'harry@gmail.com',
          email_verified_at: null,
          phone: 7896541230,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-03-29T05:41:56.000000Z',
          updated_at: '2024-03-29T05:41:56.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 110,
          name: 'Celeine',
          display_name: 'Celeine001',
          cpf_no: '14785236980',
          email: 'celeine@mailinator.com',
          email_verified_at: '2024-04-10T10:43:02.000000Z',
          phone: 9883121257,
          zipcode: null,
          playing_side: 'left',
          gender: 'Female',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-10T10:43:02.000000Z',
          updated_at: '2024-04-10T10:43:02.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 60,
      tournament_id: 120,
      team_name: '1',
      group_name: 'B',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 1,
        tournament_id: 77,
        athlete_player1_id: 110,
        athlete_player2_id: 62,
        team_division_id: '1',
        status: 1,
        group: null,
        created_at: '2024-04-12T14:18:20.000000Z',
        updated_at: '2024-04-14T08:21:37.000000Z',
        athlete_player1: {
          id: 110,
          name: 'Celeine',
          display_name: 'Celeine001',
          cpf_no: '14785236980',
          email: 'celeine@mailinator.com',
          email_verified_at: '2024-04-10T10:43:02.000000Z',
          phone: 9883121257,
          zipcode: null,
          playing_side: 'left',
          gender: 'Female',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-10T10:43:02.000000Z',
          updated_at: '2024-04-10T10:43:02.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 62,
          name: 'harry',
          display_name: 'harry',
          cpf_no: '22365789652',
          email: 'harry@gmail.com',
          email_verified_at: null,
          phone: 7896541230,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-03-29T05:41:56.000000Z',
          updated_at: '2024-03-29T05:41:56.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 61,
      tournament_id: 120,
      team_name: '9',
      group_name: 'C',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 9,
        tournament_id: 89,
        athlete_player1_id: 105,
        athlete_player2_id: 109,
        team_division_id: '1',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:00:13.000000Z',
        updated_at: '2024-04-15T09:16:45.000000Z',
        athlete_player1: {
          id: 105,
          name: 'hhhg',
          display_name: 'qdqrrghnnswq',
          cpf_no: '92187896005',
          email: 'dsqovuls.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:41:36.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:41:36.000000Z',
          updated_at: '2024-04-09T07:41:36.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 109,
          name: 'Arijit10',
          display_name: 'Captain',
          cpf_no: '11111111113',
          email: 'ari10@mailinator.com',
          email_verified_at: '2024-04-10T10:23:47.000000Z',
          phone: 3124978063,
          zipcode: 'null',
          playing_side: 'right',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-10T09:55:28.000000Z',
          updated_at: '2024-04-10T10:52:02.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 62,
      tournament_id: 120,
      team_name: '8',
      group_name: 'C',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 8,
        tournament_id: 80,
        athlete_player1_id: 104,
        athlete_player2_id: 105,
        team_division_id: '4',
        status: 1,
        group: null,
        created_at: '2024-04-15T05:49:45.000000Z',
        updated_at: '2024-04-15T05:49:45.000000Z',
        athlete_player1: null,
        athlete_player2: {
          id: 105,
          name: 'hhhg',
          display_name: 'qdqrrghnnswq',
          cpf_no: '92187896005',
          email: 'dsqovuls.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:41:36.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:41:36.000000Z',
          updated_at: '2024-04-09T07:41:36.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 63,
      tournament_id: 120,
      team_name: '13',
      group_name: 'C',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 13,
        tournament_id: 90,
        athlete_player1_id: null,
        athlete_player2_id: null,
        team_division_id: '1',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:01:20.000000Z',
        updated_at: '2024-04-15T11:00:58.000000Z',
        athlete_player1: null,
        athlete_player2: null,
      },
    },
    {
      id: 64,
      tournament_id: 120,
      team_name: '11',
      group_name: 'C',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 11,
        tournament_id: 89,
        athlete_player1_id: 102,
        athlete_player2_id: 107,
        team_division_id: '3',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:00:13.000000Z',
        updated_at: '2024-04-15T09:16:45.000000Z',
        athlete_player1: {
          id: 102,
          name: 'hhhg',
          display_name: 'qdqrrghnn',
          cpf_no: '92187896001',
          email: 'dmovul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:40:49.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:40:50.000000Z',
          updated_at: '2024-04-09T07:40:50.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 107,
          name: 'Chandan Kamila',
          display_name: 'chandan002',
          cpf_no: '45845676363',
          email: 'chandan1@mailinator.com',
          email_verified_at: '2024-04-09T12:02:10.000000Z',
          phone: 8017352586,
          zipcode: null,
          playing_side: 'right',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T12:02:10.000000Z',
          updated_at: '2024-04-09T12:02:10.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 65,
      tournament_id: 120,
      team_name: '14',
      group_name: 'D',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 14,
        tournament_id: 90,
        athlete_player1_id: null,
        athlete_player2_id: null,
        team_division_id: '2',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:01:20.000000Z',
        updated_at: '2024-04-15T11:00:58.000000Z',
        athlete_player1: null,
        athlete_player2: null,
      },
    },
    {
      id: 66,
      tournament_id: 120,
      team_name: '10',
      group_name: 'D',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 10,
        tournament_id: 89,
        athlete_player1_id: 119,
        athlete_player2_id: 109,
        team_division_id: '2',
        status: 1,
        group: null,
        created_at: '2024-04-15T09:00:13.000000Z',
        updated_at: '2024-04-15T09:16:45.000000Z',
        athlete_player1: {
          id: 119,
          name: 'hhhgds',
          display_name: 'qdqrrghnnswqds',
          cpf_no: '92187896008',
          email: 'dsqssovuls.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-12T05:44:09.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-12T05:44:09.000000Z',
          updated_at: '2024-04-12T05:44:09.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 109,
          name: 'Arijit10',
          display_name: 'Captain',
          cpf_no: '11111111113',
          email: 'ari10@mailinator.com',
          email_verified_at: '2024-04-10T10:23:47.000000Z',
          phone: 3124978063,
          zipcode: 'null',
          playing_side: 'right',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-10T09:55:28.000000Z',
          updated_at: '2024-04-10T10:52:02.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 67,
      tournament_id: 120,
      team_name: '5',
      group_name: 'D',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 5,
        tournament_id: 80,
        athlete_player1_id: 98,
        athlete_player2_id: 99,
        team_division_id: '1',
        status: 1,
        group: null,
        created_at: '2024-04-15T05:49:45.000000Z',
        updated_at: '2024-04-15T05:49:45.000000Z',
        athlete_player1: {
          id: 98,
          name: 'hhhg',
          display_name: 'qqqq',
          cpf_no: '92087896700',
          email: 'osttccvul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:38:34.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:38:34.000000Z',
          updated_at: '2024-04-09T07:38:34.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 99,
          name: 'hhhg',
          display_name: 'qqrr',
          cpf_no: '92087896707',
          email: 'cvul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:39:56.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:39:56.000000Z',
          updated_at: '2024-04-09T07:39:56.000000Z',
          deleted_at: null,
        },
      },
    },
    {
      id: 68,
      tournament_id: 120,
      team_name: '7',
      group_name: 'D',
      match: 0,
      win: 0,
      loss: 0,
      points_earn: 0,
      points_loss: 0,
      remaining_points: 0,
      created_at: '2024-05-13T05:25:56.000000Z',
      updated_at: '2024-05-13T05:25:56.000000Z',
      team: {
        id: 7,
        tournament_id: 80,
        athlete_player1_id: 102,
        athlete_player2_id: 103,
        team_division_id: '3',
        status: 1,
        group: null,
        created_at: '2024-04-15T05:49:45.000000Z',
        updated_at: '2024-04-15T05:49:45.000000Z',
        athlete_player1: {
          id: 102,
          name: 'hhhg',
          display_name: 'qdqrrghnn',
          cpf_no: '92187896001',
          email: 'dmovul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:40:49.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:40:50.000000Z',
          updated_at: '2024-04-09T07:40:50.000000Z',
          deleted_at: null,
        },
        athlete_player2: {
          id: 103,
          name: 'hhhg',
          display_name: 'qdqrrghnns',
          cpf_no: '92187896003',
          email: 'dsovul.roy@brainiuminfotech.com',
          email_verified_at: '2024-04-09T07:41:05.000000Z',
          phone: null,
          zipcode: null,
          playing_side: 'left',
          gender: 'Male',
          address: null,
          user_type: 'athlete',
          status: 1,
          language: 'en',
          is_deleted: 0,
          created_at: '2024-04-09T07:41:05.000000Z',
          updated_at: '2024-04-09T07:41:05.000000Z',
          deleted_at: null,
        },
      },
    },
  ],
  message: 'Standing list shown successfully',
};

export const finalListData = [
  {
    id: 44,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 'q',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 45,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 'q',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 46,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 'q',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 47,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 'q',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 48,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 's',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 49,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 's',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 50,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 'f',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
  {
    id: 51,
    tournament_id: 30,
    team_id1: null,
    team_id2: null,
    winning_team_id: null,
    losing_team_id: null,
    level: 't',
    winning_points: null,
    losing_points: null,
    winners_side: 1,
    date: null,
    time: null,
    location: null,
    played: 'false',
    order: 2,
    created_at: null,
    updated_at: null,
    team1: null,
    team2: null,
  },
];
