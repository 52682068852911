import {
    fetchAboutUs,
    fetchAddContactUs,
    fetchArenaTournamentList,
    fetchArenasList,
    fetchArenasView,
    fetchContactInfo,
    fetchHelpList,
    fetchNewsList,
    fetchNewsView,
    fetchPreviousTournament,
    fetchPrivacyPolicy,
    fetchRankingList,
    fetchTermsConditions,
    fetchTournamentDetails,
    fetchUpcomingTournament,
    getDoubleEliminationBracketList,
    getDoubleEliminationFinalist,
    getGroupPhaseBracketList,
    getGroupPhaseFinalist,
    getGroupPhaseStandings
} from "../services/cms.service";
import {
    clearMessage,
    handleAboutUsView,
    handleArenaTournamentDetails,
    handleArenaTournamentList,
    handleArenasListView,
    handleArenasView,
    handleContactInfo,
    handleContactUsSuccess,
    handleDoubleEliminationBrackets,
    handleFinalList,
    handleGroupPhaseBrackets,
    handleGroupPhaseStanding,
    handleHelpView,
    handleNewsList,
    handleNewsView,
    handlePreviousTournamentView,
    handlePrivacyPolicyView,
    handleRankingSuccess,
    handleTermsConditionView,
    handleFetchDataFailed,
    handleUpcomingTournamentView,
    startLoading,
    stopLoading
} from "./Actions";

export const getNewsList = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchNewsList().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleNewsList(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getNewsView = (itemId) => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchNewsView(itemId).then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleNewsView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
};

export const getRankingList = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchRankingList().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleRankingSuccess(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getTermsConditions = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchTermsConditions().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleTermsConditionView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getPrivacyPolicy = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchPrivacyPolicy().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handlePrivacyPolicyView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getAboutUs = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchAboutUs().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleAboutUsView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getHelpList = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchHelpList().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleHelpView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getUpcomingTournament = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchUpcomingTournament().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleUpcomingTournamentView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getPreviousTournament = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchPreviousTournament().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handlePreviousTournamentView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getArenasList = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchArenasList().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleArenasListView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getArenasView = (itemId) => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchArenasView(itemId).then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleArenasView(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
};

export const getContactInfo = () => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchContactInfo().then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleContactInfo(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const AddContactUs = (contactData, formik) => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchAddContactUs(contactData).then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleContactUsSuccess(data));
                formik.resetForm();
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
}

export const getArenaTournamentList = (itemId) => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchArenaTournamentList(itemId).then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleArenaTournamentList(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
};

export const getArenaTournamentDetails = (itemId) => (dispatch) => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return fetchTournamentDetails(itemId).then(
        (data) => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleArenaTournamentDetails(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        }
    );
};

export const fetchGetGroupPhaseBracketList = id => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return getGroupPhaseBracketList(id).then(
        data => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleGroupPhaseBrackets(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        },
    );
};

export const fetchGetGroupPhaseStandings = id => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return getGroupPhaseStandings(id).then(
        data => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleGroupPhaseStanding(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        },
    );
};

export const fetchGetGroupPhaseFinalist = id => dispatch => {
    dispatch(clearMessage());
    return getGroupPhaseFinalist(id).then(
        data => {
            if (data?.success) {
                dispatch(handleFinalList(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        },
    );
};

export const fetchGetDoubleEliminationBracketList = id => dispatch => {
    dispatch(startLoading());
    dispatch(clearMessage());
    return getDoubleEliminationBracketList(id).then(
        data => {
            if (!data) return dispatch(stopLoading());
            if (data?.success) {
                dispatch(handleDoubleEliminationBrackets(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        },
    );
};

export const fetchGetDoubleEliminationFinalist = id => dispatch => {
    dispatch(clearMessage());
    return getDoubleEliminationFinalist(id).then(
        data => {
            if (data?.success) {
                dispatch(handleFinalList(data));
                dispatch(stopLoading());
            } else {
                dispatch(handleFetchDataFailed(data.message));
                dispatch(stopLoading());
            }
            return Promise.resolve();
        },
        error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(stopLoading());
            dispatch(dispatch(handleFetchDataFailed(message)));
            return Promise.reject(error);
        },
    );
};