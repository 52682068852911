import React, { useEffect } from 'react';
import './index.scss';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MatchesGroup from './matches-group';
import FinalStage from '../components/final-matches-bracket';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../../components/loader';

import {
  fetchGetGroupPhaseBracketList,
  fetchGetGroupPhaseFinalist,
  fetchGetGroupPhaseStandings,
} from '../../../../../actions/Cms';
import BannerHeader from '../components/banner-header';

const ChampionGroupPhase = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    tournament,
    tournamentMatch,
    tournamentStanding,
    isLoading,
    finalMatches,
  } = useSelector(state => state.Tournaments);

  const groups = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  const standingsHelper = (standings, groupName) => {
    return (
      standings && standings?.filter(item => item.group_name === groupName)
    );
  };

  const bracketHelper = (matches, groupName) => {
    return (
      matches &&
      matches
        ?.filter(item => item.group_name === groupName)
        ?.map(item => {
          return item;
        })
    );
  };

  useEffect(() => {
    dispatch(fetchGetGroupPhaseBracketList(id));
    dispatch(fetchGetGroupPhaseStandings(id));
    dispatch(fetchGetGroupPhaseFinalist(id));
  }, []);

  // console.log("tournament===>", tournament)

  return (
    <>
      <div className="mainBase pt-0">
        <BannerHeader
          arenaId={tournament?.arena_id}
          picUrl={tournament?.pic_url}
          tournamentName={tournament?.championship_name}
          duoNumber={tournament?.duo?.duo_number}
          status={tournament?.status}
        />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="bracketContent">
              <div className="container">
                <div className="group-phase-tabs">
                  <Tabs defaultActiveKey="groupmatches" transition={false}>
                    <Tab eventKey="groupmatches" title={t('Group Matches')}>
                      {tournamentMatch?.length > 0 &&
                        tournamentStanding?.length > 0 ? (
                        <>
                          {groups && groups.length > 0 ? (
                            groups
                              .slice(0, Number(tournament?.no_group))
                              .map(index => {
                                return (
                                  <MatchesGroup
                                    key={index}
                                    groupName={index}
                                    groupStandings={standingsHelper(
                                      tournamentStanding,
                                      index,
                                    )}
                                    matchBrackets={bracketHelper(
                                      tournamentMatch,
                                      index,
                                    )}
                                    tournamentId={tournament?.id}
                                    tournamentType={tournament?.type?.type_name}
                                    tournamentStatus={tournament?.status}
                                  />
                                );
                              })
                          ) : (
                            <p>{t('No Team Found.')}</p>
                          )}
                        </>
                      ) : (
                        <p className="mt-3">
                          {t('Group Matches')} {t('Not Found')}
                        </p>
                      )}
                    </Tab>
                    <Tab className='final-tab' eventKey="final" title="Final">
                      {finalMatches && finalMatches?.length > 0 ? (
                        <FinalStage
                          finalMatches={finalMatches}
                          tournamentId={tournament?.id}
                          tournamentType={tournament?.type?.type_name}
                          tournamentStatus={tournament?.status}
                          noTeamsFinal={tournament?.no_teams_final}
                          preMatchLength={
                            tournamentMatch?.filter(
                              item => item.group_name != null
                            ).length
                          }
                        />
                      ) : (
                        <p className="mt-3">
                          {'Final'} {'Matches'} {'Not Found'}.
                        </p>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ChampionGroupPhase;
