import './App.scss';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './stores/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './components/scroll-to-top';
import BackToTop from './components/back-to-top';
import { I18nextProvider } from 'react-i18next';
import i18n from './language/i18n';
import Routers from './routers';
import { fetchToken } from './firebase';

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    fetchToken(setTokenFound);
  }, []);

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
            <ScrollToTop>
              <Routers />
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
        <ToastContainer position="top-right" hideProgressBar={true} theme="light" />
        <BackToTop />
      </I18nextProvider>
    </>
  );
}

export default App;
