import Beginner from '../../../../assets/images/badges/Beginner.png';

import Bronze1 from '../../../../assets/images/badges/Bronze1.png';
import Bronze2 from '../../../../assets/images/badges/Bronze2.png';
import Bronze3 from '../../../../assets/images/badges/Bronze3.png';
import Bronze4 from '../../../../assets/images/badges/Bronze4.png';

import Silver1 from '../../../../assets/images/badges/Silver1.png';
import Silver2 from '../../../../assets/images/badges/Silver2.png';
import Silver3 from '../../../../assets/images/badges/Silver3.png';
import Silver4 from '../../../../assets/images/badges/Silver4.png';

import Gold1 from '../../../../assets/images/badges/Gold1.png';
import Gold2 from '../../../../assets/images/badges/Gold2.png';
import Gold3 from '../../../../assets/images/badges/Gold3.png';
import Gold4 from '../../../../assets/images/badges/Gold4.png';

import Platinum1 from '../../../../assets/images/badges/Platinum1.png';
import Platinum2 from '../../../../assets/images/badges/Platinum2.png';
import Platinum3 from '../../../../assets/images/badges/Platinum3.png';
import Platinum4 from '../../../../assets/images/badges/Platinum4.png';

//Trophy Room
import Trophyroom from '../../../../assets/images/badges/box25.png';
import Goldcup from '../../../../assets/images/badges/Goldcup.png';
import Silvercup from '../../../../assets/images/badges/Silvercup.png';
import Bronzecup from '../../../../assets/images/badges/Bronzecup.png';

// Category
import CategoryBlock from '../../../../assets/images/badges/CategoryBlock.png';
import Categorybar from '../../../../assets/images/badges/Categorybar.png';
import Category from '../../../../assets/images/badges/Category.png';
import Ribbon1 from '../../../../assets/images/badges/Ribbon1.png';


export const BadgeImages = {
    Beginner: Beginner,
    Bronze1: Bronze1,
    Bronze2: Bronze2,
    Bronze3: Bronze3,
    Bronze4: Bronze4,
    Silver1: Silver1,
    Silver2: Silver2,
    Silver3: Silver3,
    Silver4: Silver4,
    Gold1: Gold1,
    Gold2: Gold2,
    Gold3: Gold3,
    Gold4: Gold4,
    Platinum1: Platinum1,
    Platinum2: Platinum2,
    Platinum3: Platinum3,
    Platinum4: Platinum4,

    //Trophy Room
    Trophyroom: Trophyroom,
    Goldcup: Goldcup,
    Silvercup: Silvercup,
    Bronzecup: Bronzecup,

    // Category
    CategoryBlock: CategoryBlock,
    Categorybar: Categorybar,
    Category: Category,

    Ribbon1: Ribbon1,
};
