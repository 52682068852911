import React from "react";
import './index.scss';
import DatePicker from 'react-datepicker';
import { FloatingLabel, Form } from "react-bootstrap";
import moment from "moment";

export const DobSelectField = ({ formik, fieldName, labelName }) => {

  return (
    <>
      <DatePicker
        value={formik.values[fieldName] ? formik.values[fieldName] : null}
        onChange={date => formik.setFieldValue(fieldName, moment(date).format("DD-MM-YYYY"))}
        name={fieldName}
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        autoComplete="off"
        customInput={
          <FloatingLabel controlId={fieldName} label={labelName} className='cmn-style-floating'>
            <Form.Control className='dateIcon' placeholder="Select date" name={fieldName} onChange={formik.handleChange} value={formik.values[fieldName] ? formik.values[fieldName] : ""} readOnly />
          </FloatingLabel>}
        maxDate={new Date()}
      />
      {formik.touched[fieldName] && formik.errors[fieldName] ? <p className="errorTxt">{formik.errors[fieldName]}</p> : null}
    </>
  );
}

export const DateSelectField = ({ formik, fieldName, labelName }) => {

  return (
    <>
      <DatePicker
        value={formik.values[fieldName] ? formik.values[fieldName] : null}
        onChange={date => formik.setFieldValue(fieldName, moment(date).format("DD-MM-YYYY"))}
        name={fieldName}
        dateFormat="dd-MM-yyyy"
        autoComplete="off"
        customInput={
          <FloatingLabel controlId={fieldName} label={labelName} className='cmn-style-floating'>
            <Form.Control className='dateIcon' placeholder="Select date" name={fieldName} onChange={formik.handleChange} value={formik.values[fieldName] ? formik.values[fieldName] : ""} readOnly />
          </FloatingLabel>}
        minDate={new Date()}
      />
      {formik.touched[fieldName] && formik.errors[fieldName] ? <p className="errorTxt">{formik.errors[fieldName]}</p> : null}
    </>
  );
}

export const TimeSelectField = ({ formik, fieldName, labelName }) => {

  return (
    <>
      <DatePicker
        value={formik.values[fieldName] ? moment.utc(`${formik.values[fieldName]}`, 'HH:mm').format('HH:mm') : null}
        onChange={time => formik.setFieldValue(fieldName, moment(time).format("HH:mm"))}
        name={fieldName}
        autoComplete="off"
        timeIntervals={15}
        timeCaption="Time"
        timeFormat="HH:mm"
        showTimeSelectOnly
        showTimeSelect
        customInput={
          <FloatingLabel controlId={fieldName} label={labelName} className='cmn-style-floating'>
            <Form.Control className='timeIcon' placeholder="Select time" name={fieldName} onChange={formik.handleChange} value={formik.values[fieldName] ? moment.utc(`${formik.values[fieldName]}`, 'HH:mm').format('HH:mm') : ""} readOnly />
          </FloatingLabel>}
      />
      {formik.touched[fieldName] && formik.errors[fieldName] ? <p className="errorTxt">{formik.errors[fieldName]}</p> : null}
    </>
  );
}