import React from "react";
import './index.scss';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import MatchesBracket from "./matches-bracket";

const MatchesGroup = ({ groupStandings, matchBrackets, groupName, tournamentId, tournamentType, tournamentStatus }) => {
    const { t } = useTranslation();

    return (
        <div className="matches-group-tabs arenaBase mt-3">
            <Tabs
                defaultActiveKey="group"
                transition={false}
            >
                <Tab eventKey="group" title={`${t("Group")} ${groupName}`}>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">{t("Team Name")}</th>
                                <th scope="col">{"M"}</th>
                                <th scope="col">{"W"}</th>
                                <th scope="col">{"L"}</th>
                                <th scope="col">{"P.E"}</th>
                                <th scope="col">{"P.L"}</th>
                                <th scope="col">{"R.P"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupStandings && groupStandings?.length > 0 ? groupStandings?.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td data-label={t("Team Name")}>Team {item.team_division_id}</td>
                                        <td data-label={"M"}>{item.match}</td>
                                        <td data-label={"W"}>{item.win}</td>
                                        <td data-label={"L"}>{item.loss}</td>
                                        <td data-label={"P.E"}>{item.points_earn}</td>
                                        <td data-label={"P.L"}>{item.points_loss}</td>
                                        <td data-label={"R.P"}>{item.remaining_points}</td>
                                    </tr>
                                )
                            }) : <tr><td colSpan={7} className="text-center">{t("No Team Found.")}</td></tr>}
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey="standings" title={t("Standings")}>
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">{t("Team Name")}</th>
                                <th scope="col">{"M"}</th>
                                <th scope="col">{"W"}</th>
                                <th scope="col">{"L"}</th>
                                <th scope="col">{"P.E"}</th>
                                <th scope="col">{"P.L"}</th>
                                <th scope="col">{"R.P"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupStandings && groupStandings?.length > 0 ? groupStandings?.slice()?.sort((a, b) => b.remaining_points - a.remaining_points)?.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td data-label={t("Team Name")}>Team {item.team_division_id}</td>
                                        <td data-label={"M"}>{item.match}</td>
                                        <td data-label={"W"}>{item.win}</td>
                                        <td data-label={"L"}>{item.loss}</td>
                                        <td data-label={"P.E"}>{item.points_earn}</td>
                                        <td data-label={"P.L"}>{item.points_loss}</td>
                                        <td data-label={"R.P"}>{item.remaining_points}</td>
                                    </tr>
                                )
                            }) : <tr><td colSpan={7} className="text-center">{t("No Team Found.")}</td></tr>}
                        </tbody>
                    </table>
                </Tab>
                <Tab eventKey="matches" title={t("Matches")}>
                    {matchBrackets?.length > 0 &&
                        <MatchesBracket
                            rounds={matchBrackets}
                            groupName={groupName}
                            tournamentId={tournamentId}
                            tournamentType={tournamentType}
                            tournamentStatus={tournamentStatus}
                        />}
                </Tab>
            </Tabs>
        </div>
    );
};

export default MatchesGroup;