import React from "react";
import './index.scss';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TitleBar = ({ title, linkUrl }) => {
    const { t } = useTranslation();
    return (
        <div className='cmn-header-style homeBanner'>
            <div className='container'>
                    <div className='cmn-header-title'>
                        <Link className="back-btn" to={linkUrl}><i className="fa-solid fa-chevron-left"></i> </Link>
                        <h1>{t(`${title}`)}</h1>
                    </div>
            </div>
        </div>
    )
}
export default TitleBar;