import {
  fetchPaymentDataApi,
  paymentProcessApi,
} from '../services/payment.service';
import {
  paymentAmountData,
  handleFetchDataFailed,
  paymentSuccessData,
  clearMessage,
  startLoading,
  stopLoading,
} from './Actions';

export const fetchPaymentData = () => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return fetchPaymentDataApi().then(
    data => {
      dispatch(stopLoading());
      if (data?.success) {
        dispatch(paymentAmountData(data));
      } else {
        dispatch(handleFetchDataFailed(data?.message));
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};

export const paymentProcess = data => dispatch => {
  dispatch(startLoading());
  dispatch(clearMessage());
  return paymentProcessApi(data).then(
    data => {
      dispatch(stopLoading());
      if (data?.success) {
        dispatch(paymentSuccessData(data?.approval_url));
      } else {
        dispatch(handleFetchDataFailed(data?.message));
      }
      return Promise.resolve();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch(stopLoading());
      dispatch(dispatch(handleFetchDataFailed(message)));
      return Promise.reject(error);
    },
  );
};
