import './index.scss';
import React, { useEffect, useState } from "react";
import logo from '../../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../actions/Auth';
import { Alert, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { clearMessage } from '../../../actions/Actions';
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const { message, isError, isLoading } = useSelector(state => state.Auth);
    const getLanguage = () => i18n.language === 'en-US' || i18n.language === 'en' ? 'en' : 'pt';
 
    const formik = useFormik({
        initialValues: {
            language: getLanguage(),
            email: '',
            password: ''
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            email: yup.string().email(t("Invalid email address.")).required(t("Please enter email address.")),
            password: yup.string().min(6, t("Password must have at least 6 characters.")).required(t("Please enter password.")),
        }),
        onSubmit: async (values) => {
            dispatch(login(values, formik, navigate, t));
        }
    })

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);


    return (
        <section className='loginbase'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 loginimg'>
                        {/* <img src={loginimg} alt='' /> */}
                    </div>
                    <div className='col-lg-6'>
                        <div className='loginCard'>
                            <div className='loginLogo'>
                                <img src={logo} alt='' />
                                <h4>{t('Ready to Login')}</h4>
                            </div>
                            {message && isError && typeof message === 'string' && (
                                <Alert className='mb-3' variant="danger" onClose={() => dispatch(clearMessage())} dismissible>
                                    {message}
                                </Alert>
                            )}
                            {message && !isError && (
                                <Alert className='mb-3' variant="success" onClose={() => dispatch(clearMessage())} dismissible>
                                    {t("Login successful! Welcome back!")}
                                </Alert>
                            )}
                            <form onSubmit={formik.handleSubmit}>
                                <div className='fieldPosition mb-3'>
                                    <FloatingLabel controlId="floatingEmail" label={t('Email Address')} className="cmn-style-floating floating-btn-group">
                                        <span className='fieldicon'><i className="fa-regular fa-envelope"></i></span>
                                        <Form.Control type="email" name="email" placeholder="Enter email address" onChange={formik.handleChange} value={formik.values.email} />
                                    </FloatingLabel>
                                    {formik.touched.email && formik.errors.email ? <p className="errorTxt">{formik.errors.email}</p> : null}
                                </div>
                                <div className='fieldPosition'>
                                    <FloatingLabel controlId="floatingPassword" label={t('Password')} className="cmn-style-floating floating-btn-group">
                                        <span className='fieldicon'><i className="fa-regular fa-lock"></i></span>
                                        <Form.Control name="password" type={showPassword ? "text" : "password"} placeholder="Enter password" onChange={formik.handleChange} value={formik.values.password} />
                                        <button type='button' className="input-group-addon btn loginEye" onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                        </button>
                                    </FloatingLabel>
                                    {formik.touched.password && formik.errors.password ? <p className="errorTxt">{formik.errors.password}</p> : null}
                                </div>
                                <p className='forgtLink'><Link to="/email-verification">{t("Forgot Password")}?</Link></p>
                                <button className='loginButton' type='submit' disabled={isLoading}>
                                    {isLoading ? <Spinner animation="border" size="sm" /> : <span>{t("Login")}</span>}
                                </button>
                            </form>
                            <h6 className='text-center pt-3'><Link to="/">{t("Back to Home")}</Link></h6>
                            {/* <h6 className='text-center pt-3'>{t("Don't have an account")}? <Link to="/register">{t("Register")}</Link> {t("here")}</h6> */}
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Login;