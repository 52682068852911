import './index.scss'
import React from 'react';
import { Link } from 'react-router-dom'
import noImg from '../../../../../../assets/images/no-image.jpg';
import { useTranslation } from 'react-i18next';

const BannerHeader = ({ arenaId, picUrl, tournamentName, duoNumber, status }) => {
    const {t} = useTranslation();
    return (
        <div className='bannerChapions'>
            <div className='maleBeginner'>
                <div className='container'>
                    <div className='maleFlex'>
                        <div className='maleLft'>
                            <Link className='back-btn' to={`/championships/view/${arenaId}`}><i className="fa-solid fa-chevron-left"></i> </Link>
                            <div className='mlCard ps-3'>
                                <div className='mlCardImg'>
                                    {picUrl ?
                                        <img className='img-user' src={process.env.REACT_APP_IMGBASE_URL + picUrl} alt={tournamentName} />
                                        : <img className='img-user' src={noImg} alt="No user" />}
                                </div>
                                <div className='maleBeginnr'>
                                    <h4>{tournamentName ? tournamentName : "N/A"}</h4>
                                    <h5>{duoNumber ? <><i className="fa-light fa-user-group"></i> {duoNumber} {t("Teams")} </> : "N/A"}</h5>
                                </div>
                            </div>
                        </div>
                        {status === 2 && (
                            <div className='live-txt'>
                                <span><i className="fa-solid fa-circle-small"></i>&nbsp;{t("Live")}</span>
                            </div>
                        )}
                        {status === 3 && (
                            <Link className='commonButton shareBtn'><i className="fa-solid fa-share"></i> Share</Link>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerHeader;