import React, { useEffect } from "react";
import './index.scss';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { useTranslation } from "react-i18next";
import { getAboutUs } from "../../../actions/Cms";
import TitleBar from "../components/titlte-bar";

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { about_us, isLoading } = useSelector(state => state.Cms);

    useEffect(() => {
        dispatch(getAboutUs());
    }, [dispatch]);

    return (
        <>
            <div className='mainBase pt-0'>
                <TitleBar title="About Us" linkUrl="/" />
                <div className='cmn-content-body cmn-cms-wrapper aboutus-wrapper'>
                    <div className='container clearfix'>
                        {isLoading ? <Loader /> :
                            about_us ? <div dangerouslySetInnerHTML={{ __html: i18n.language === "en" || i18n.language === "en-US" ? about_us?.en_content : about_us?.pt_content }}></div> : <p className='text-center mb-0'>{t("About Us") + " " + t("Not Found")}</p>}
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUs;