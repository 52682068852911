import React, { useEffect, useState } from "react";
import "./index.scss";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

const OpeningHoursComponent = ({ openingHours }) => {
    const { t } = useTranslation();
    const [currentDay, setCurrentDay] = useState('');
    const dayFiltered = openingHours?.find(item => item.days === currentDay);

    useEffect(() => {
        setCurrentDay(moment().format('dddd'));
    }, []);

    return (
        <Dropdown size="lg" className='opening-hours-dropdown'>
            <Dropdown.Toggle>
                {dayFiltered?.status === "true" ?
                    <>
                        <span className='day-open'>{t("Open")}</span>
                        &nbsp;
                        <span className='day-time'>
                        {dayFiltered?.opening_time && moment.utc(`${dayFiltered?.opening_time}`, 'HH:mm').format('HH:mm')}
                        &nbsp;-&nbsp;
                        {dayFiltered?.closing_time && moment.utc(`${dayFiltered?.closing_time}`, 'HH:mm').format('HH:mm')}
                        </span>
                    </>
                    :
                    <span className='day-close'>{t("Closed")}</span>
                }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {openingHours?.length > 0 && openingHours?.map((item) => {
                    return (
                        <div key={item?.id} className='row hours-list'>
                            <div className='col-sm-5 py-1 text-start'>{t(item.days)}</div>
                            {item.status === "true" ?
                                <div className='col-sm-7 py-1 text-end'>{moment.utc(`${item.opening_time}`, 'HH:mm').format('HH:mm')} - {moment.utc(`${item.closing_time}`, 'HH:mm').format('HH:mm')}</div>
                                :
                                <div className='col-sm-7 py-1 text-end'><span className='day-close'>{t("Closed")}</span></div>
                            }
                        </div>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default OpeningHoursComponent;