import axios from 'axios';
import authHeader from './auth-header';
import {toast} from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL + 'arena/';

export const fetchPaymentDataApi = () => {
  return axios
    .get(API_URL + 'check-outstanding-payments', {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Payment Data failed: ${error.message}`);
    });
};

export const paymentProcessApi = data => {
  return axios
    .post(API_URL + 'process-transaction', data, {headers: authHeader()})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      toast.error(`Tranaction failed: ${error.message}`);
    });
};
