import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EnFlag } from '../../../src/assets/images/flags/united-kingdom-flag-icon.svg';
import { ReactComponent as PrFlag } from '../../../src/assets/images/flags/brazil-flag-icon.svg';
import { useSelector } from 'react-redux';
import { changeLanguageApi } from '../../services/auth.service';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const { isLoggedIn } = useSelector(state => state.Auth);

  const renderFlagIcon = () => {
    switch (i18n.language) {
      case 'en-US':
        return <EnFlag />;
      case 'en':
        return <EnFlag />;
      case 'pt':
        return <PrFlag />;
      default:
        return null;
    }
  };

  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    // if (isLoggedIn) changeLanguageApi(e.target.value);
  }

  return (
    <>
      <div className="language-dropdown">
        <span className='globe'>
          {renderFlagIcon()}
          {/* <i className="fa-regular fa-globe"></i> */}
        </span>
        <select onChange={(e) => handleChangeLanguage(e)} value={i18n.language}>
          <option value="en">English</option>
          <option value="pt">Portuguese</option>
        </select>
      </div>

    </>
  );
}

export default LanguageDropdown;
