import './index.scss';
import React from "react";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <section className='loginfooter'>
            &copy; {new Date().getFullYear()} sportranking. {t("All rights reserved")}
        </section>
    )
}

export default Footer;