import React, {useState, useEffect} from 'react';
import MatchUpdateModal from '../match-update-modal';
import MatchViewModal from '../match-view-modal';
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  SingleLineSeed,
} from 'react-brackets';
import {useTranslation} from 'react-i18next';
import {finalListData} from '../../../../../../json/Data';

const FinalStage = ({
  finalMatches,
  tournamentId,
  tournamentType,
  tournamentStatus,
  noTeamsFinal,
  preMatchLength,
}) => {
  const {t} = useTranslation();
  const [matchData, setMatchData] = useState([]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [matchSeed, setMatchSeed] = useState('');
  const [matchNo, setMatchNo] = useState('');
  const [matchList, setMatchList] = useState([]);

  useEffect(() => {
    finalPhaseFunc();
  }, [finalMatches]);

  const finalPhaseFunc = () => {
    let matchesData = [];
    if (finalMatches == undefined || finalMatches.length == 0) {
      matchesData = finalListData;
    } else {
      matchesData = finalMatches;
    }
    let quaterFinalMatchList = [];
    if (noTeamsFinal == 8 || noTeamsFinal == '8') {
      quaterFinalMatchList = matchesData?.filter(item => item.level == 'q');
    }

    let semiMatchList = matchesData?.filter(item => item.level == 's');
    let finalMatchList = matchesData?.filter(item => item.level == 'f');
    let thirdPlacelist = matchesData?.filter(item => item.level == 't');
    let temp1 = {
      title: 'Semi Final',
      seeds: semiMatchList,
    };

    let temp2 = {
      title: 'Final',
      seeds: finalMatchList,
    };
    let temp4 = {
      title: t('3rd Place Match'),
      seeds: thirdPlacelist,
    };
    let arr = [];
    if (quaterFinalMatchList.length == 0) {
      arr = [temp1, temp2, temp4];
      setMatchList([...semiMatchList, ...thirdPlacelist, ...finalMatchList]);
    } else {
      let temp3 = {
        title: t('Quater Final'),
        seeds: quaterFinalMatchList,
      };
      arr = [temp3, temp1, temp2, temp4];
      setMatchList([
        ...quaterFinalMatchList,
        ...semiMatchList,
        ...thirdPlacelist,
        ...finalMatchList,
      ]);
    }

    setMatchData(arr);
  };

  const handleUpdateModal = data => {
    const pos = matchList.map(e => e.id).indexOf(data.id);
    // console.log('======pos======>', preMatchLength + pos + 1);
    setMatchNo(preMatchLength + pos + 1);
    setMatchSeed(data);
    setOpenUpdateModal(true);
  };

  const handleViewModal = data => {
    const pos = matchList.map(e => e.id).indexOf(data.id);
    // console.log('======pos======>', preMatchLength + pos + 1);
    setMatchNo(preMatchLength + pos + 1);
    setMatchSeed(data);
    setOpenViewModal(true);
  };

  const CustomSeed = ({seed, breakpoint, roundIndex, rounds}) => {
    const isLineConnector =
      rounds[roundIndex]?.seeds?.length ===
      (rounds[roundIndex + 1]?.seeds?.length || 0);
    const Wrapper = isLineConnector ? SingleLineSeed : Seed;
    //const Wrapper = SingleLineSeed;

    return (
      <>
        <Wrapper
          mobileBreakpoint={breakpoint}
          style={{fontSize: 12}}
          className={`${
            rounds[roundIndex]?.title == 'Final' || seed?.id == undefined
              ? 'remove_line'
              : ''
          }`}>
          {seed && seed?.id ? (
            <SeedItem
              onClick={() => {
                if (tournamentStatus === 1 && seed?.team_id1 != null && seed?.team_id2 != null) {
                  handleViewModal(seed);
                } else {
                  if (tournamentStatus === 2 && seed?.played === "0" && seed?.team_id1 != null && seed?.team_id2 != null) {
                    handleUpdateModal(seed);
                  } else if (tournamentStatus === 2 && seed?.played === "true" && seed?.team_id1 != null && seed?.team_id2 != null) {
                    handleViewModal(seed);
                  } else {
                    handleViewModal(seed);
                  }
                }
              }}>
              <div>
                <SeedTeam
                  className={`game-style game-1 ${
                    seed?.played !== 'false' && seed?.played != false
                      ? seed?.winning_team_id == seed?.team_id1
                        ? 'win'
                        : 'lose'
                      : ''
                  }`}>
                  {/* <span className='player-id'>#{seed?.team_id1}</span> */}
                  {seed?.team_id1 != null ? (
                    <span className="player-name">
                      {t('Team') + " "} {seed?.team1?.team_division_id}
                    </span>
                  ) : (
                    <span className="player-name">{t("No Team")}</span>
                  )}

                  {seed?.played === true || seed?.played === 'true' ? (
                    <span className="player-score">
                      {seed?.team_id1 === seed?.winning_team_id
                        ? seed?.winning_points
                        : seed?.losing_points}
                    </span>
                  ) : (
                    <span className="player-score">--</span>
                  )}
                </SeedTeam>
                <SeedTeam
                  className={`game-style game-2 ${
                    seed?.played !== 'false' && seed?.played != false
                      ? seed?.winning_team_id == seed?.team_id2
                        ? 'win'
                        : 'lose'
                      : ''
                  }`}>
                  {/* <span className='player-id'>#{seed?.team_id2}</span> */}
                  <span className="player-name">
                    {seed?.team_id2 != null
                      ? t('Team') + " " + seed?.team2?.team_division_id
                      : t('No Team')}
                  </span>
                  {/* <span className='player-score'>{seed?.winning_team_id==seed?.team_id2? seed?.winning_points:seed?.losing_points}</span> */}
                  {seed?.played === true || seed?.played === 'true' ? (
                    <span className="player-score">
                      {seed?.team_id2 === seed?.winning_team_id
                        ? seed?.winning_points
                        : seed?.losing_points}
                    </span>
                  ) : (
                    <span className="player-score">--</span>
                  )}
                </SeedTeam>
              </div>
            </SeedItem>
          ) : (
            <div style={{height: '70px'}}></div>
          )}
        </Wrapper>
      </>
    );
  };

  return (
    <div>
      {matchData.length > 0 && (
        <Bracket
          rounds={matchData}
          renderSeedComponent={CustomSeed}
          bracketClassName={'bracket-mlr-1 mt-3'}
          roundTitleComponent={(title, roundIndex) => {
            return (
              <div className="bracket-step-title">
                <span>{title}</span>
              </div>
            );
          }}
        />
      )}
      {openUpdateModal && (
        <MatchUpdateModal
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          matchSeed={matchSeed}
          tournamentId={tournamentId}
          tournamentType={tournamentType}
          matchNumber={t('Match') + ' ' + matchNo}
        />
      )}
      {openViewModal && (
        <MatchViewModal
          openViewModal={openViewModal}
          setOpenViewModal={setOpenViewModal}
          matchSeed={matchSeed}
          tournamentId={tournamentId}
          tournamentType={tournamentType}
          matchNumber={t('Match') + ' ' + matchNo}
        />
      )}
    </div>
  );
};

export default FinalStage;
