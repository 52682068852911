import {
  ATHLETE_SUCCESS,
  ATHLETE_VIEW,
  CLEAR_MESSAGE,
  START_LOADING,
  STOP_LOADING,
  ATHLETE_COUNT,
  ATHLETE_RANKING,
  DATA_FAILED,
} from '../actions/Types';

const initialState = {
  athletes: [],
  details: {},
  message: null,
  isError: false,
  isLoading: false,
  countingData: {},
  rankingData: null
};
export default function Athletes(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ATHLETE_SUCCESS:
      return {
        ...state,
        athletes: payload.athletes.sort(
          (a, b) => a.user_id - b.user_id,
        ),
      };
    case DATA_FAILED:
      return {
        ...state,
        message: payload.message
      };
    case ATHLETE_VIEW:
      return {
        ...state,
        details: payload.details,
      };

    case ATHLETE_COUNT:
      return {
        ...state,
        countingData: payload.countingData,
      };
    case ATHLETE_RANKING:
      return {
        ...state,
        rankingData: payload.rankingData,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        isError: false,
      };
    default:
      return state;
  }
}
