import React, { useEffect } from "react";
import './index.scss';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearMessage } from "../../actions/Actions";
import { verifiedEmail } from "../../actions/Auth";
import { useTranslation } from "react-i18next";

const VerificationSuccess = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(()=>{
        dispatch(verifiedEmail(token));
        dispatch(clearMessage());
    }, [dispatch]);

    return (
        <div className="verified-success-box d-flex text-center justify-content-center flex-column align-items-center">
            <div className="verified-success-content">
                <i className="fa-light fa-badge-check"></i>
                <h3>{t("Email is Verified!")}</h3>
                <p>{t("Thanks for your support, You have succesfully verified account.")} {t("You can now proceed to you login")}</p> 
                <p></p>
                <button type="button" className="btn cmn-btn-style" onClick={() => navigate("/login")}>{t("Back to Login")}</button>
            </div>
        </div>
    )
}

export default VerificationSuccess;