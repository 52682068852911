import { useTranslation } from 'react-i18next';
import './index.scss';
import React from "react";
import { useSelector } from 'react-redux';
import Loader from '../../../components/loader';

const Dashboard = () => {
    const { t } = useTranslation();
    const { arenas } = useSelector(state => state.Arenas);
    const { athletes, isLoading } = useSelector(state => state.Athletes);

    return (
        <>
            {isLoading ? <Loader /> :
                <div className='dbCardbg'>
                    <div className='dbCard'>
                        <span className='dbIcon'><i className="fa-solid fa-user-tie" /></span>
                        <div>
                            <h6>{t("Arenas")}</h6>
                            <h5>{arenas?.length > 0 ? arenas?.length : 0}</h5>
                        </div>
                    </div>
                    <div className='dbCard'>
                        <span className='dbIcon'><i className="fa-solid fa-person-running" /></span>
                        <div>
                            <h6>{t("Athletes")}</h6>
                            <h5>{athletes?.length > 0 ? athletes?.length : 0}</h5>
                        </div>
                    </div>
                    <div className='dbCard'>
                        <span className='dbIcon'><i className="fa-regular fa-square-poll-vertical"></i></span>
                        <div>
                            <h6>{t("Total Revenue")}</h6>
                            <h5>{`R$ ${0}`}</h5>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Dashboard;