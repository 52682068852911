import './index.scss';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { getSearchedByCityState, getSearchedByIdNameNickName } from '../../../helpers';
import { useSelector } from 'react-redux';
import Loader from '../../../components/loader';
import LoadMore from '../../../components/load-more';
import { CitySelectField, SearchInputField, StateSelectField } from '../../../components/search-filter';


const Arena = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { arenas, isLoading } = useSelector(state => state.Arenas);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchStateTerm, setSearchStateTerm] = useState('');
    const [searchCityTerm, setSearchCityTerm] = useState('');
    const fiteredItemStateState = getSearchedByCityState(arenas, searchStateTerm);
    const fiteredItemCityState = getSearchedByCityState(fiteredItemStateState, searchCityTerm);
    const filteredItems = getSearchedByIdNameNickName(fiteredItemCityState, searchTerm);

    // FOR-LOAD-MORE 
    const [visibleItems, setVisibleItems] = useState(20);

    const arenaItems = filteredItems && filteredItems.length > 0 ? filteredItems.slice(0, visibleItems).map((item) => (
        <tr key={item.id}>
            <td data-label={t("Arena Name")}>{item.name ? item.name : "N/A"}</td>
            <td data-label={t("Nick Name")}>{item.display_name ? item.display_name : "N/A"}</td>
            <td data-label={t("City/State")}>{item.city && item.state ? item.city + '/' + item.state : "N/A"}</td>
            <td data-label={t("Championship Created")}>{item.total_championship && <span className='circleabd'>{item.total_championship}</span>}</td>
            <td data-label={t("Action")} className='arenaBtns'>
                <button type='button' onClick={() => navigate(`/arena/view/${item.id}`)}><i className="fa-regular fa-eye"></i></button>
                {/* <button type='button'><i className="fa-regular fa-trash"></i></button> */}
            </td>
        </tr>
    )) : <tr className='p-0'><td colSpan="5" className='text-center'>{t("Arenas")} {t("Not Found")}.</td></tr>;

    const handleResetFilter = () => {
        setSearchTerm('');
        setSearchStateTerm('');
        setSearchCityTerm('');
    }
    // console.log("GET ARENA LIST====>", arenas)

    return (
        <div className='arenaBase'>
            <div className='topFlex mb-3'>
                {/* <button className='btn btn-reset d-block d-lg-none ms-3'><i className="fa-regular fa-filter"></i></button> */}
                <h2 className='admin-page-title mb-3'>{t("Arenas")}</h2>
                <div className='admin-page-header-right'>
                    <div className='search-filter-bx'>
                        <div className='search-filter-col state-filter'>
                            <StateSelectField fieldName={"state"} labelName={t("State")} cityFieldName={"city"} setSearchCityTerm={setSearchCityTerm} searchStateTerm={searchStateTerm} setSearchStateTerm={setSearchStateTerm} />
                        </div>
                        <div className='search-filter-col city-filter'>
                            <CitySelectField fieldName={"city"} labelName={t("City")} stateFieldName={searchStateTerm} searchCityTerm={searchCityTerm} setSearchCityTerm={setSearchCityTerm} />
                        </div>
                        <div className='search-filter-col search-filter'>
                            <SearchInputField fieldName={"search"} labelName={t("Search")} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                            <button className='btn btn-reset ms-3' onClick={handleResetFilter}><i className="fa-regular fa-light fa-rotate-left"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <table className='mb-3'>
                <thead>
                    <tr>
                        <th scope="col">{t("Arena Name")}</th>
                        <th scope="col">{t("Nick Name")}</th>
                        <th scope="col">{t("City/State")}</th>
                        <th scope="col">{t("Championship Created")}</th>
                        <th scope="col">{t("Action")}</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? <tr><td colSpan="5"><Loader /></td></tr> : arenaItems}
                </tbody>
            </table>

            {!isLoading && filteredItems.length > 20 && (
                <LoadMore visibleItems={visibleItems} setVisibleItems={setVisibleItems} perPage={20} totalItems={filteredItems.length} />
            )}

            <Link to="/dashboard" className='backtxt'><i className="fa-light fa-arrow-left-long"></i> {t("Back")}</Link>
        </div>
    )
}

export default Arena;