import React from "react";
import './index.scss';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import noImg from '../../../../assets/images/no-image.jpg';

const BannerBar = ({ title, subTitle, description, bannerUrl, linkUrl }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <section className='homeBanner champBnr inner-banner-wrap'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 d-flex align-items-center'>
                        <div className='bnrRgt'>
                            <h3>{t(`${title}`)}</h3>
                            <h1>{t(`${subTitle}`)}</h1>
                            <h5>{t(`${description}`)}</h5>
                            <button className='commonButton commonBtnbordr mt-3' onClick={()=> navigate(`${linkUrl}`)}>{t("View Championships")} <i className="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                    <div className='col-md-6 champImg'>
                        {bannerUrl ? <img src={bannerUrl} alt='' /> : <img src={noImg} alt='' />}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BannerBar;