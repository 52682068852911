import './index.scss'
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import noImg from '../../../../../../assets/images/no-image.jpg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStartTournament } from '../../../../../../actions/Tournaments';
import InfoModal from '../../../../../../components/info-modal';

const BannerHeader = ({ arenaId, tournamentId, picUrl, tournamentName, duoNumber, categoryType, status }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { tournament, tournaments } = useSelector(state => state.Tournaments);
    const tournamentTeams = tournaments.find(
        tournament => tournament.id === Number(tournamentId),
    );
    const [openInfoModal, setOpenInfoModal] = useState(false);

    const hasBlankPlayerField = teamArr => {
        for (const obj of teamArr) {
            if (!obj.athlete_player1_id || !obj.athlete_player2_id) {
                return true;
            }
        }
        return false;
    };

    // console.log("===tournamentTeams==>", tournamentTeams)

    return (
        <>
            <div className='tournament-header'>
                <Link className='back-link' to={`/tournaments`}><i className="fa-solid fa-chevron-left"></i> </Link>
                <div className='tournament-body-bx ps-3'>
                    <div className='mlCardImg'>
                        {picUrl ?
                            <img className='img-user' src={process.env.REACT_APP_IMGBASE_URL + picUrl} alt={tournamentName} />
                            : <img className='img-user' src={noImg} alt="No user" />}
                    </div>
                    <div className='tournament-content'>
                        <h4>{tournamentName ? tournamentName : "N/A"} {categoryType ? "(" + t(categoryType) + ")" : ""}</h4>
                        <span>{duoNumber ? <><i className="fa-light fa-user-group"></i> {duoNumber} {t("Teams")} </> : "N/A"}</span>
                    </div>
                </div>
                {tournament?.status === 1 && (
                    <div className="tournament-header-right">
                        {tournamentTeams?.fetch_teams &&
                            hasBlankPlayerField(tournamentTeams?.fetch_teams) ? (
                            <button
                                onClick={() => setOpenInfoModal(true)}
                                className="addBtns ms-3"
                                type="button">
                                <i className="fa-regular fa-circle-play"></i>{' '}
                                <span>{t('Start Tournament')}</span>
                            </button>
                        ) : (
                            <button
                                onClick={() => dispatch(fetchStartTournament(tournamentId))}
                                className="addBtns ms-3"
                                type="button">
                                <i className="fa-regular fa-circle-play"></i>{' '}
                                <span>{t('Start Tournament')}</span>
                            </button>
                        )}
                    </div>
                )}
                {status === 2 && (
                    <div className='live-txt'>
                        <span><i className="fa-solid fa-circle-small"></i>&nbsp;{t("Live")}</span>
                    </div>
                )}
            </div>
            <InfoModal
                openInfoModal={openInfoModal}
                setOpenInfoModal={setOpenInfoModal}
                content={t('Player is blank in the team. Please select a player.')}
            />
        </>
    )
}

export default BannerHeader;